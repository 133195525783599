import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNum: 1,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState: initialState,
  reducers: {
    setPageNum: (state, action) => {
      state.pageNum = action.payload;
    },
    resetPageNum: (state) => {
      state.pageNum = 1;
    },
  },
});
export default paginationSlice.reducer;
export const { setPageNum, resetPageNum } = paginationSlice.actions;
