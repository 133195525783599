import React from "react";
import { Link, useLocation } from "react-router-dom";

const AccountTable = ({ data }) => {
  console.log(data);
  const location = useLocation();
  const isSuper = location.pathname.includes("super");
  const isData = location.pathname.includes("data");
  const link = isData
    ? "/data-sending-log/detail/"
    : isSuper
    ? "/super/detail/"
    : "/admin/detail/";
  return (
    <table className="w-full">
      <thead>
        <tr>
          {Object.keys(data[0]).map((key) => (
            <th key={key} className="border h-[50px] bg-gray-200">
              {key.toUpperCase()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {Object.values(item).map((val, index) => (
              <td key={index} className="border h-[50px] text-center">
                {isData ? (
                  <Link to={`${link}${item["일시"]}`}>{val}</Link>
                ) : (
                  <Link to={`${link}${item["No"]}`}>{val}</Link>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AccountTable;
