import React from "react";
import SignalStatus from "./section/signalStatus";
import QueueChange from "./section/QueueChange";
import Legend from "./section/Legend";
import SearchForm from "./section/SearchForm";

const Sidebar = () => {
  return (
    <div className="sideBarWrapper">
      <div className="sideBarContainer">
        {/*검색 창*/}
        <SearchForm />
        {/*신호 적용 현시*/}
        <SignalStatus />
        {/*대기 행렬 변화*/}
        <QueueChange />
        {/*범례*/}
        <Legend />
      </div>
    </div>
  );
};

export default Sidebar;
