import React from "react";
import Sidebar from "../contents/signalResult/Sidebar";
import SignalResultMap from "../contents/signalResult/section/map/SignalResultlMap";
const SignalResult = () => {
  return (
    <>
      <Sidebar />
      <SignalResultMap />
    </>
  );
};

export default SignalResult;
