import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  setFinalCheck,
  showConfirmModal,
  showDoubleCheckModal,
} from "../../store/features/common/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import ModalBtn from "../button/ModalBtn";

const DoubleCheckModal = ({ data }) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.confirmModal.doubleCheck); // 이중 확인 모달이 표시될지 여부를 나타내는 상태값
  // console.log(show);
  const cancelButtonRef = useRef(null); // 모달 취소 버튼

  useEffect(() => {
    dispatch(setFinalCheck({ finalCheck: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("최종확인~~~~~~", finalCheck);
  // }, [finalCheck]);

  /**
   * 확인 버튼 선택 시, 완료 모달을 표시하는 함수
   */
  const handleConfirmBtn = () => {
    dispatch(setFinalCheck({ finalCheck: true }));
    dispatch(showConfirmModal({ show: true })); // 완료 모달 상태 변경
    dispatch(showDoubleCheckModal({ doubleCheck: false })); // 이중 확인 모달 상태 변경
  };
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(showDoubleCheckModal({ doubleCheck: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex justify-center items-center">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-2">
                        <div className="my-7">
                          <p className="title2">{`정말 ${data}하시겠습니까?`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-pale-grey px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <ModalBtn type="submit" onClick={handleConfirmBtn}>
                    확인
                  </ModalBtn>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DoubleCheckModal;
