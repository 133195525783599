import React from "react";
import AutonomousRoute from "./section/AutonomousRoute";
import Legend from "./section/Legend";
import SearchForm from "./section/SearchForm";

const Sidebar = () => {
  return (
    <div className="sideBarWrapper">
      <div className="space-y-[20px] sideBarContainer">
        {/*검색창*/}
        <SearchForm />
        <div className="sideBarContent">
          {/*자율 주행 경로 */}
          <AutonomousRoute />
        </div>
        {/*범례*/}
        <Legend />
      </div>
    </div>
  );
};

export default Sidebar;
