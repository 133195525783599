import React, { useState } from "react";
import { useQuery } from "react-query";

import { Role } from "../../constants/Constants";

import { superApi } from "../../api/AccountApi";

/**
 * 셀렉트 박스 옵션 값
 * @param type  셀렉트 박스 종류
 * @returns {*[]}
 * @constructor
 */
const Options = ({ type }) => {
  // console.log("Options param", type);
  const [coListData, setCoListData] = useState([]);
  const roleData = ["전체", Role.USER, Role.ADMIN];
  const stateData = ["전체", "정상", "탈퇴", "강제 탈퇴"];
  /**
   * 회사 목록을 가져오는 쿼리
   */
  useQuery(["coList"], () => superApi.coList(), {
    enabled: type === "company" || type === "detailCompany",
    onSuccess: (data) => {
      // console.log("coList", data.data.items);
      setCoListData([...data.data.items]);
    },
  });

  let options = [];
  if (type === "company") {
    options = coListData.map((item, index) => (
      <option value={item} key={index}>
        {item}
      </option>
    ));
  }
  if (type === "role") {
    options = roleData.map((item, index) => (
      <option value={item} key={index}>
        {item}
      </option>
    ));
  }
  if (type === "state") {
    options = stateData.map((item, index) => (
      <option key={index} value={item}>
        {item}
      </option>
    ));
  }
  if (type === "detailCompany") {
    options = coListData
      .filter((i) => i !== "전체")
      .map((item, index) => (
        <option value={item} key={index}>
          {item}
        </option>
      ));
  }
  if (type === "detailRole") {
    options = roleData
      .filter((i) => i !== "전체")
      .map((item, index) => (
        <option value={item} key={index}>
          {item}
        </option>
      ));
  }
  if (type === "detailState") {
    options = stateData
      .filter((i) => i !== "전체")
      .map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ));
  }
  return options;
};
export default React.memo(Options);
