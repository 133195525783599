import axios from "axios";
export const passApi = {
  passArrow: async (param) => {
    // console.log("searchApi params", param);
    const { startDate, endDate } = param;
    const response = await axios.get("/refer/crossway/info", {
      params: { start_date: startDate, end_date: endDate },
    });
    // console.log("응답", response.data);
    return response.data;
  },

  passDailyChart: async (param) => {
    // console.log("passDailyChart param", param);
    const {
      startDate,
      endDate,
      crossway: crossName,
      direction: directionData,
    } = param;
    const response = await axios.get("refer/pass/traffic", {
      params: {
        start_date: startDate,
        end_date: endDate,
        crossway: crossName,
        direction: directionData,
      },
    });

    console.log("응답", response.data);
    return response.data;
  },

  passHourlyChart: async (param) => {
    // console.log("passHourlyChart param", param);
    const {
      startDate,
      endDate,
      crossway: crossName,
      direction: directionData,
    } = param;
    const response = await axios.get("/refer/pass/time/traffic", {
      params: {
        start_date: startDate,
        end_date: endDate,
        crossway: crossName,
        direction: directionData,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};

export const rotationApi = {
  rotationArrow: async () => {
    const response = await axios.get("/refer/crossway/direction/info");
    // console.log("응답", response.data);
    return response.data;
  },
  rotationOptions: async (crossway, direction) => {
    // console.log("rotationOptions param", crossway, direction);
    const response = await axios.get("/refer/direction/rotation/movement", {
      params: {
        crossway: crossway,
        direction: direction,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  rotationDailyChart: async (param) => {
    // console.log("rotationDailyChart param", param);
    const {
      startDate,
      endDate,
      crossway: crossName,
      direction: directionData,
      rotation: rotationData,
    } = param;
    const response = await axios.get("/refer/direction/pass/traffic", {
      params: {
        start_date: startDate,
        end_date: endDate,
        crossway: crossName,
        direction: directionData,
        rotation: rotationData,
      },
    });

    // console.log("응답", response.data);
    return response.data;
  },
  rotationHourlyChart: async (param) => {
    // console.log("rotationHourlyChart param", param);
    const {
      startDate,
      endDate,
      crossway: crossName,
      direction: directionData,
      rotation: rotationData,
    } = param;
    const response = await axios.get("/refer/direction/pass/time/traffic", {
      params: {
        start_date: startDate,
        end_date: endDate,
        crossway: crossName,
        direction: directionData,
        rotation: rotationData,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  rotationRatioChart: async (param) => {
    // console.log("rotationRatioChart param", param);
    const {
      startDate,
      endDate,
      crossway: crossName,
      direction: directionData,
      rotation: rotationData,
    } = param;
    const response = await axios.get("/refer/direction/rotation/ratio", {
      params: {
        start_date: startDate,
        end_date: endDate,
        crossway: crossName,
        direction: directionData,
        rotation: `${rotationData} 진행`,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};

export const signalApi = {
  signalMap: async () => {
    const response = await axios.get("/refer/crossway/signal/info");
    // console.log("응답", response.data);
    return response.data;
  },
  display: async (param) => {
    // console.log("display param", param);
    const { crossway: crossName } = param;
    const response = await axios.get("/refer/signal/applied", {
      params: {
        crossway: crossName,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  greenTime: async (param) => {
    // console.log("greenTime param", param);
    const { startDate, endDate, crossway: crossName } = param;
    const response = await axios.get("/refer/valid/green", {
      params: {
        start_date: startDate,
        end_date: endDate,
        crossway: crossName,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};

export const signalResultApi = {
  signalResultArrow: async (param) => {
    // console.log("signalResultArrow params", param);
    const { startDate, endDate } = param;
    const response = await axios.get("/signal/result/crossway/info", {
      params: { start_date: startDate, end_date: endDate },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  signalQueue: async (param) => {
    // console.log("signalResultQueue params", param);
    const { crossway: crossName } = param;
    const response = await axios.get("/signal/result/queue/shake", {
      params: { crossway: crossName },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  signalStatus: async (param) => {
    // console.log("signalStatus params", param);
    const { crossway: crossName } = param;
    const response = await axios.get("/signal/result/application", {
      params: { crossway: crossName },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};

export const drivingApi = {
  crosswayList: async (param) => {
    console.log("crosswayList param", param);

    const response = await axios.get("/driving/crossway/names", {
      params: { crossway: param },
    });
    console.log("응답", response.data);
    return response.data;
  },
  drivingArrow: async (param) => {
    console.log("drivingArrow param", param);
    const { startDate, endDate, startCrossway, endCrossway } = param;
    const response = await axios.get("/driving/path", {
      params: {
        start_date: startDate,
        end_date: endDate,
        origin_crossway: startCrossway,
        destination_crossway: endCrossway,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};
export const searchApi = {
  directionOptions: async (crossway) => {
    // console.log("direction param", crossway);
    const response = await axios.get("/commons/direction/rotation/access", {
      params: {
        crossway: crossway,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};
