import React, { useEffect, useState } from "react";
import HorizontalBarChart from "../../../../components/chart/HorizontalBarChart";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { rotationApi } from "../../../../api/SearchApi";

const RotationRatio = () => {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    // console.log(chartData);
  }, [chartData]);
  const search = useSelector((state) => state.searchData.search);
  const { isFetching, isLoading, isSuccess } = useQuery(
    ["rotationRatioChart", search],
    () => rotationApi.rotationRatioChart(search),
    {
      onSuccess: (data) => {
        console.log("rotationRatioChart data", data.data.items);
        setChartData([{ name: "회전 비율", data: data.data.items }]);
      },
    }
  );
  return (
    <div className="sideBarContent mt-[20px]">
      {isSuccess && !isLoading && !isFetching ? (
        !!chartData[0]?.data ? (
          <HorizontalBarChart data={chartData} title={"회전 비율"} />
        ) : (
          <div className="loading h-[200px]">접근 방향을 선택해주세요</div>
        )
      ) : (
        <div className="loading h-[200px]">데이터를 불러오는 중입니다</div>
      )}

      {/*<TblExcelDownload*/}
      {/*  data={dummyData[0].data}*/}
      {/*  filename={"일자별 통과 교통량"}*/}
      {/*/>*/}
    </div>
  );
};

export default RotationRatio;
