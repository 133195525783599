import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clicked: false,
};

const excelSlice = createSlice({
  name: "excel",
  initialState,
  reducers: {
    clickExcelBtn: (state, action) => {
      state.clicked = action.payload.clicked;
    },
    resetExcelBtn: (state, action) => {
      state.clicked = false;
    },
  },
});

export default excelSlice.reducer;
export const { clickExcelBtn, resetExcelBtn } = excelSlice.actions;
