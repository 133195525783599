import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  search: {
    startData: "",
    endData: "",
    satus: "전체",
  },
};
const searchOptionsSlice = createSlice({
  name: "searchOptions",
  initialState,
  reducers: {
    showSearchOptionsModal: (state, action) => {
      state.show = action.payload.show;
    },
    setSearchOptions: (state, action) => {
      state.search = action.payload.search;
    },
  },
});

export default searchOptionsSlice.reducer;
export const { showSearchOptionsModal, setSearchOptions } =
  searchOptionsSlice.actions;
