import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
const today = new Date();
const initialState = {
  search: {
    startCrossway: "",
    endCrossway: "",
    startDate: format(new Date(today), "yyyy-MM-dd"),
    endDate: format(new Date(today), "yyyy-MM-dd"),
  },
  selectedPath: 1,
};

const autoDrivingDataSlice = createSlice({
  name: "autoDrivingDataSlice",
  initialState,
  reducers: {
    setAutoDrivingDataSearch: (state, action) => {
      const { startDate, endDate } = action.payload.search;
      state.search.startDate = startDate;
      state.search.endDate = endDate;
    },
    setAutoDrivingStartCrossway: (state, action) => {
      state.search.startCrossway = action.payload.startCrossway;
    },
    setAutoDrivingEndCrossway: (state, action) => {
      state.search.endCrossway = action.payload.endCrossway;
    },
    resetAutoDrivingStartCrossway: (state, action) => {
      state.search.startCrossway = initialState.search.startCrossway;
    },
    resetAutoDrivingEndCrossway: (state, action) => {
      state.search.endCrossway = initialState.search.endCrossway;
    },
    resetAutoDrivingSearch: (state, action) => {
      state.search = initialState.search;
    },
    setSelectedRoute: (state, action) => {
      state.selectedPath = action.payload;
    },
    resetSelectedRoute: (state, action) => {
      state.selectedPath = initialState.selectedPath;
    },
  },
});

export default autoDrivingDataSlice.reducer;
export const {
  setAutoDrivingDataSearch,
  setAutoDrivingStartCrossway,
  setAutoDrivingEndCrossway,
  resetAutoDrivingStartCrossway,
  resetAutoDrivingEndCrossway,
  resetAutoDrivingSearch,
  setSelectedRoute,
  resetSelectedRoute,
} = autoDrivingDataSlice.actions;
