import React, { useState } from "react";
import DonutChart from "../../../components/chart/DonutChart";
import { useQuery } from "react-query";
import { dashboardApi } from "../../../api/DashboardApi";

const translationMap = {
  congestion: "혼잡",
  normal: "보통",
  smooth: "원활",
};

const changeName = (labels) => {
  return labels.map((label) => translationMap[label] || label);
};
const IntersectionStatusSection = () => {
  const [apiData, setApiData] = useState({});
  const { isFetching, isLoading } = useQuery(
    "intersectionStatus",
    () => dashboardApi.intersectionStatus(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log("intersectionStatus", data.data.items[0]);
        setApiData(data.data.items[0]);
      },
    }
  );

  const chartData = Object.values(apiData);
  const dummyLabel = Object.keys(apiData);
  return (
    <div className="box w-1/2 rounded-box">
      <div className="boxTitle">대기행렬 현황</div>
      <div className="boxContent">
        {isLoading || isFetching ? (
          <div className="h-[150px] loading">데이터를 불러오는 중입니다</div>
        ) : (
          <DonutChart data={chartData} label={changeName(dummyLabel)} />
        )}
      </div>
    </div>
  );
};

export default IntersectionStatusSection;
