export const transitInfoODContent = (createTime) => [
  {
    receiveTime: "00:01",
    createTimeStamp: `${createTime}:01`,
    originCrosswayId: 1,
    destCrosswayId: 2,
    odVolume: 39,
    avgTravelDist: 22,
    avgTravelTime: 29,
    deviceName: 3,
  },
  {
    receiveTime: "00:02",
    createTimeStamp: `${createTime}:02`,
    originCrosswayId: 2,
    destCrosswayId: 3,
    odVolume: 100,
    avgTravelDist: 27,
    avgTravelTime: 11,
    deviceName: 1,
  },
  {
    receiveTime: "00:03",
    createTimeStamp: `${createTime}:03`,
    originCrosswayId: 3,
    destCrosswayId: 4,
    odVolume: 41,
    avgTravelDist: 28,
    avgTravelTime: 22,
    deviceName: 3,
  },
  {
    receiveTime: "00:04",
    createTimeStamp: `${createTime}:04`,
    originCrosswayId: 4,
    destCrosswayId: 5,
    odVolume: 88,
    avgTravelDist: 11,
    avgTravelTime: 21,
    deviceName: 5,
  },
  {
    receiveTime: "00:05",
    createTimeStamp: `${createTime}:05`,
    originCrosswayId: 5,
    destCrosswayId: 6,
    odVolume: 41,
    avgTravelDist: 30,
    avgTravelTime: 18,
    deviceName: 5,
  },
  {
    receiveTime: "00:06",
    createTimeStamp: `${createTime}:06`,
    originCrosswayId: 6,
    destCrosswayId: 7,
    odVolume: 85,
    avgTravelDist: 18,
    avgTravelTime: 20,
    deviceName: 4,
  },
  {
    receiveTime: "00:07",
    createTimeStamp: `${createTime}:07`,
    originCrosswayId: 7,
    destCrosswayId: 8,
    odVolume: 93,
    avgTravelDist: 11,
    avgTravelTime: 17,
    deviceName: 4,
  },
  {
    receiveTime: "00:08",
    createTimeStamp: `${createTime}:08`,
    originCrosswayId: 8,
    destCrosswayId: 9,
    odVolume: 53,
    avgTravelDist: 21,
    avgTravelTime: 28,
    deviceName: 3,
  },
  {
    receiveTime: "00:09",
    createTimeStamp: `${createTime}:09`,
    originCrosswayId: 9,
    destCrosswayId: 10,
    odVolume: 87,
    avgTravelDist: 10,
    avgTravelTime: 21,
    deviceName: 1,
  },
  {
    receiveTime: "00:10",
    createTimeStamp: `${createTime}:10`,
    originCrosswayId: 10,
    destCrosswayId: 11,
    odVolume: 97,
    avgTravelDist: 12,
    avgTravelTime: 21,
    deviceName: 1,
  },
  {
    receiveTime: "00:11",
    createTimeStamp: `${createTime}:11`,
    originCrosswayId: 11,
    destCrosswayId: 12,
    odVolume: 44,
    avgTravelDist: 19,
    avgTravelTime: 12,
    deviceName: 1,
  },
  {
    receiveTime: "00:12",
    createTimeStamp: `${createTime}:12`,
    originCrosswayId: 12,
    destCrosswayId: 13,
    odVolume: 30,
    avgTravelDist: 28,
    avgTravelTime: 27,
    deviceName: 1,
  },
  {
    receiveTime: "00:13",
    createTimeStamp: `${createTime}:13`,
    originCrosswayId: 13,
    destCrosswayId: 14,
    odVolume: 38,
    avgTravelDist: 10,
    avgTravelTime: 30,
    deviceName: 3,
  },
  {
    receiveTime: "00:14",
    createTimeStamp: `${createTime}:14`,
    originCrosswayId: 14,
    destCrosswayId: 15,
    odVolume: 39,
    avgTravelDist: 17,
    avgTravelTime: 28,
    deviceName: 4,
  },
  {
    receiveTime: "00:15",
    createTimeStamp: `${createTime}:15`,
    originCrosswayId: 15,
    destCrosswayId: 16,
    odVolume: 47,
    avgTravelDist: 10,
    avgTravelTime: 24,
    deviceName: 1,
  },
  {
    receiveTime: "00:16",
    createTimeStamp: `${createTime}:16`,
    originCrosswayId: 16,
    destCrosswayId: 17,
    odVolume: 80,
    avgTravelDist: 29,
    avgTravelTime: 18,
    deviceName: 1,
  },
  {
    receiveTime: "00:17",
    createTimeStamp: `${createTime}:17`,
    originCrosswayId: 17,
    destCrosswayId: 18,
    odVolume: 100,
    avgTravelDist: 13,
    avgTravelTime: 13,
    deviceName: 1,
  },
  {
    receiveTime: "00:18",
    createTimeStamp: `${createTime}:18`,
    originCrosswayId: 18,
    destCrosswayId: 19,
    odVolume: 31,
    avgTravelDist: 19,
    avgTravelTime: 12,
    deviceName: 5,
  },
  {
    receiveTime: "00:19",
    createTimeStamp: `${createTime}:19`,
    originCrosswayId: 19,
    destCrosswayId: 20,
    odVolume: 94,
    avgTravelDist: 21,
    avgTravelTime: 24,
    deviceName: 5,
  },
  {
    receiveTime: "00:20",
    createTimeStamp: `${createTime}:20`,
    originCrosswayId: 20,
    destCrosswayId: 21,
    odVolume: 87,
    avgTravelDist: 27,
    avgTravelTime: 28,
    deviceName: 1,
  },
  {
    receiveTime: "00:21",
    createTimeStamp: `${createTime}:21`,
    originCrosswayId: 21,
    destCrosswayId: 22,
    odVolume: 39,
    avgTravelDist: 16,
    avgTravelTime: 18,
    deviceName: 2,
  },
  {
    receiveTime: "00:22",
    createTimeStamp: `${createTime}:22`,
    originCrosswayId: 22,
    destCrosswayId: 23,
    odVolume: 75,
    avgTravelDist: 26,
    avgTravelTime: 18,
    deviceName: 3,
  },
  {
    receiveTime: "00:23",
    createTimeStamp: `${createTime}:23`,
    originCrosswayId: 23,
    destCrosswayId: 24,
    odVolume: 78,
    avgTravelDist: 25,
    avgTravelTime: 28,
    deviceName: 2,
  },
  {
    receiveTime: "00:24",
    createTimeStamp: `${createTime}:24`,
    originCrosswayId: 24,
    destCrosswayId: 25,
    odVolume: 85,
    avgTravelDist: 10,
    avgTravelTime: 29,
    deviceName: 5,
  },
  {
    receiveTime: "00:25",
    createTimeStamp: `${createTime}:25`,
    originCrosswayId: 25,
    destCrosswayId: 26,
    odVolume: 34,
    avgTravelDist: 26,
    avgTravelTime: 12,
    deviceName: 5,
  },
  {
    receiveTime: "00:26",
    createTimeStamp: `${createTime}:26`,
    originCrosswayId: 26,
    destCrosswayId: 27,
    odVolume: 84,
    avgTravelDist: 16,
    avgTravelTime: 28,
    deviceName: 5,
  },
  {
    receiveTime: "00:27",
    createTimeStamp: `${createTime}:27`,
    originCrosswayId: 27,
    destCrosswayId: 28,
    odVolume: 47,
    avgTravelDist: 19,
    avgTravelTime: 19,
    deviceName: 4,
  },
  {
    receiveTime: "00:28",
    createTimeStamp: `${createTime}:28`,
    originCrosswayId: 28,
    destCrosswayId: 29,
    odVolume: 91,
    avgTravelDist: 27,
    avgTravelTime: 26,
    deviceName: 4,
  },
  {
    receiveTime: "00:29",
    createTimeStamp: `${createTime}:29`,
    originCrosswayId: 29,
    destCrosswayId: 30,
    odVolume: 56,
    avgTravelDist: 16,
    avgTravelTime: 14,
    deviceName: 4,
  },
  {
    receiveTime: "00:30",
    createTimeStamp: `${createTime}:30`,
    originCrosswayId: 30,
    destCrosswayId: 31,
    odVolume: 80,
    avgTravelDist: 19,
    avgTravelTime: 24,
    deviceName: 3,
  },
];
