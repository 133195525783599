import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import SendingDataLogDetailDescription from "../../contents/log/section/SendingDataLogDetailDescription";
import SearchBtn from "../../components/button/SearchBtn";
import {
  crosswayTrafficInfo,
  sectionTrafficInfo,
  signalDisplayPlanData,
  signalDisplayPlanOffset,
  transitInfoOD,
} from "../../contents/log/data/logDescriptionData";
import { SignalDisplayPlanContent } from "../../contents/log/data/logContentData/signalDisplayPlanContent";
import SendingDataLogDetailContent from "../../contents/log/section/SendingDataLogDetailContent";
import { crosswayTrafficInfoContent } from "../../contents/log/data/logContentData/crosswayTrafficInfoContent";
import { transitInfoODContent } from "../../contents/log/data/logContentData/transitInfoODContent";
import { signalDisplayPlanOffsetContent } from "../../contents/log/data/logContentData/signalDisplayPlanOffsetContent";
import { sectionTrafficInfoContent } from "../../contents/log/data/logContentData/sectionTrafficInfoContent";

const SendingDataLogDetailRecord = () => {
  const location = useLocation();
  const date = decodeURIComponent(location.pathname).slice(
    decodeURIComponent(location.pathname).lastIndexOf("/") + 1
  );
  const [active, setActive] = useState("신호 운영 계획 현시");
  const navigate = useNavigate();

  const [descriptionData, setDescriptionData] = useState(signalDisplayPlanData);
  const [contentData, setContentData] = useState(SignalDisplayPlanContent);
  const handleClick = (e) => {
    const type = e.target.textContent;
    setActive(type);
    if (type === "신호 운영 계획 현시") {
      setDescriptionData(signalDisplayPlanData);
      setContentData(SignalDisplayPlanContent);
    } else if (type === "신호 운영 계획 옵셋") {
      setDescriptionData(signalDisplayPlanOffset);
      setContentData(signalDisplayPlanOffsetContent);
    } else if (type === "영상 교차로 교통 정보") {
      setDescriptionData(crosswayTrafficInfo);
      setContentData(crosswayTrafficInfoContent);
    } else if (type === "구간 교통 정보") {
      setDescriptionData(sectionTrafficInfo);
      setContentData(sectionTrafficInfoContent(date.slice(0, 16)));
    } else if (type === "기종점 통행 정보") {
      setDescriptionData(transitInfoOD);
      setContentData(transitInfoODContent(date.slice(0, 16)));
    }
  };
  const handleBtn = () => navigate("/data-sending-log");
  return (
    <div className="content">
      <div className=" box">
        <div className="pt-2">
          <div className="boxTitle">데이터 전송 기록 상세 {date}</div>
          <div className="flex space-x-2">
            {[
              "신호 운영 계획 현시",
              "신호 운영 계획 옵셋",
              "영상 교차로 교통 정보",
              "구간 교통 정보",
              "기종점 통행 정보",
            ].map((tab, index) => (
              <div
                className={clsx(
                  "rounded-t-box w-auto flex items-center justify-center font-semibold px-6 py-3  transition-colors",
                  {
                    "bg-pale-grey-two": active === tab,
                    "cursor-pointer bg-pale-grey-three": active !== tab,
                  }
                )}
                onClick={(e) => handleClick(e)}
              >
                {tab}
              </div>
            ))}
          </div>
          <div className="boxContent p-3 mb-3">
            <div className="bg-white rounded-[10px] p-3">
              <p className="text-lg font-pretendard font-semibold -tracking-[.27px]">
                데이터 명세
              </p>
              {SendingDataLogDetailDescription(descriptionData)}
              {SendingDataLogDetailContent(contentData, date)}
              <div className="w-full flex justify-end mt-3">
                <SearchBtn className="!w-[120px]" onClick={handleBtn}>
                  목록
                </SearchBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendingDataLogDetailRecord;
