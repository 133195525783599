import React from "react";
const LogBox = ({ logTitle, logData }) => {
  const formatLogTitle = {
    errorNum: "에러 로그",
    warningNum: "경고 로그",
    infoNum: "일반 로그",
  };
  return (
    <div className="flex flex-col items-center justify-center border w-1/2 bg-white m-5 rounded-box space-y-3">
      <div className="text-5xl">{logData}</div>
      <div className="title1">{formatLogTitle[logTitle]}</div>
    </div>
  );
};

export default LogBox;
