import { createSlice } from "@reduxjs/toolkit";

const initialDate = String(new Date());

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    minDate: initialDate,
    startDate: initialDate,
    endDate: initialDate,
  },
  reducers: {
    setMinDate: (state, action) => {
      state.minDate = action.payload.minDate;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload.startDate;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload.endDate;
    },
    resetMinDate: (state) => {
      state.minDate = initialDate;
    },
  },
});

export default calendarSlice.reducer;
export const { setMinDate, setStartDate, resetMinDate, setEndDate } =
  calendarSlice.actions;
