export const signalDisplayPlanData = [
  {
    key: "INT_LCNO",
    description: "교차로번호(1-1024)",
    key2: "aSPLIT1",
    description2: "A링 1현시",
  },
  {
    key: "aSPLIT2",
    description: "A링 2현시",
    key2: "aSPLIT3",
    description2: "A링 3현시",
  },
  {
    key: "aSPLIT4",
    description: "A링 4현시",
    key2: "aSPLIT5",
    description2: "A링 5현시",
  },
  {
    key: "aSPLIT6",
    description: "A링 6현시",
    key2: "aSPLIT7",
    description2: "A링 7현시",
  },
  {
    key: "aSPLIT8",
    description: "A링 8현시",
    key2: "bSPLIT1",
    description2: "B링 1현시",
  },
  {
    key: "bSPLIT2",
    description: "B링 2현시",
    key2: "bSPLIT3",
    description2: "B링 3현시",
  },
  {
    key: "bSPLIT4",
    description: "B링 4현시",
    key2: "bSPLIT5",
    description2: "B링 5현시",
  },
  {
    key: "bSPLIT6",
    description: "B링 6현시",
    key2: "bSPLIT7",
    description2: "B링 7현시",
  },
  {
    key: "bSPLIT8",
    description: "B링 8현시",
    key2: "",
    description2: "",
  },
];
export const signalDisplayPlanOffset = [
  {
    key: "INT_LCNO",
    description: "교차로번호(1-1024)",
    key2: "OFFSET",
    description2: "옵셋",
  },
];

export const crosswayTrafficInfo = [
  {
    key: "crosswayId",
    description: "교차로 고유 아이디",
    key2: "ringID",
    description2: "링 번호",
  },
  {
    key: "signalCycle",
    description: "현시 번호",
    key2: "smallVolume",
    description2: "교통량 (소형)",
  },
  {
    key: "mediumVolume",
    description: "교통량 (중형)",
    key2: "largeVolume",
    description2: "교통량 (대형)",
  },
  {
    key: "motorcycleVolume",
    description: "교통량 (오토바이)",
    key2: "initialWaitNum",
    description2: "최대초기대기수",
  },
  {
    key: "avgMaxQueue",
    description: "평균최대대기길이",
    key2: "avgSpeed",
    description2: "평균이동류속도",
  },
  {
    key: "avgTrafficRate",
    description: "평균포화교통유율",
    key2: "avgDemandVolume",
    description2: "평균수요교통량",
  },
  {
    key: "avgSaturation",
    description: "평균포화도",
    key2: "dataNum",
    description2: "데이터 수",
  },
  {
    key: "accessCrosswayID",
    description: "접근로 교차로 고유 아이디",
    key2: "movementDirection",
    description2: "이동 방향",
  },
];

export const sectionTrafficInfo = [
  {
    key: "createTimeStamp",
    description: "정보생성일시",
    key2: "linkId",
    description2: "링크 ID",
  },
  {
    key: "volume",
    description: "위치기반차량수",
    key2: "avgTravelTime",
    description2: "평균통행시간 (초)",
  },
  {
    key: "sectionTime",
    description: "구간통행속도",
    key2: "downTime",
    description2: "정지시간 (초)",
  },
  {
    key: "downRatio",
    description: "정지비율",
    key2: "downCount",
    description2: "정지횟수",
  },
  {
    key: "avgQueue",
    description: "평균대기길이 (m)",
    key2: "maxQueue",
    description2: "최대대기길이 (m)",
  },
];

export const transitInfoOD = [
  {
    key: "createTimeStamp",
    description: "정보생성일시",
    key2: "originCrosswayId",
    description2: "출발 교차로번호",
  },
  {
    key: "destCrosswayId",
    description: "도착 교차로번호",
    key2: "odVolume",
    description2: "OD 통행량",
  },
  {
    key: "avgTravelDist",
    description: "평균통행거리 (m)",
    key2: "avgTravelTime",
    description2: "평균통행시간 (초)",
  },
  {
    key: "deviceName",
    description: "수집장치",
    key2: "",
    description2: "",
  },
];
