import ApexChart from "react-apexcharts";

const RadialChart = ({ data }) => {
  return (
    <ApexChart
      type="radialBar"
      series={[data]}
      height={250}
      options={{
        chart: {
          toolbar: {
            show: false,
          },
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
          background: "transparent",
          fontFamily: "pretendard",
        },
        theme: {
          mode: "light",
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "rgba(231, 231, 231, 0.5)",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
            },
            dataLabels: {
              formatter: (val) => val + "℃",
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "22px",
                fontFamily: "pretendard",
                fontWeight: 500,
              },
            },
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.3,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        tooltip: {
          y: { formatter: (value) => value + "℃" },
        },
      }}
    />
  );
};

export default RadialChart;
