import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageNum } from "../../store/features/common/paginationSlice";
import { ReactComponent as IcPageEnd } from "../../asset/icon/pagination/ic_page_end.svg";
import { ReactComponent as IcPageStart } from "../../asset/icon/pagination/ic_page_start.svg";
import { ReactComponent as IcPageF } from "../../asset/icon/pagination/ic_page_f.svg";
import { ReactComponent as IcPageP } from "../../asset/icon/pagination/ic_page_p.svg";

const Pagination = ({ totalNum }) => {
  console.log("totalNum", totalNum);
  const dispatch = useDispatch();
  const pageNum = useSelector((state) => state.pagination.pageNum);
  console.log("pageNum", pageNum);
  const currentPage = pageNum;
  const pageCount = Math.ceil(totalNum / 10);
  const pageNumbers = [];
  const visiblePageCount = 10;
  const pageStart =
    Math.floor((currentPage - 1) / visiblePageCount) * visiblePageCount + 1;
  const pageEnd = Math.min(pageStart + visiblePageCount - 1, pageCount);
  const isEmpty = totalNum <= 10;

  const handlePageClick = (pageIndex) => {
    if (pageIndex < 1) {
      dispatch(setPageNum(1));
    } else if (pageIndex > pageCount) {
      dispatch(setPageNum(pageCount));
    } else {
      dispatch(setPageNum(pageIndex));
    }
  };

  const renderPageNumbers = () => {
    for (let i = pageStart; i <= pageEnd; i++) {
      pageNumbers.push(
        <button
          className={
            i === currentPage
              ? "pageNumBtn !bg-pale-grey !font-bold"
              : "pageNumBtn"
          }
          key={i}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    !isEmpty && (
      <div className="flex justify-center items-center pt-2">
        <button className="pageNumBtn" onClick={() => handlePageClick(1)}>
          <IcPageStart className="icon" />
        </button>
        <button
          className="pageNumBtn"
          onClick={() => handlePageClick(currentPage - 1)}
        >
          <IcPageP className="icon" />
        </button>
        <div className="flex justify-center">{renderPageNumbers()}</div>
        <button
          className="pageNumBtn"
          onClick={() => handlePageClick(currentPage + 1)}
        >
          <IcPageF className="icon" />
        </button>
        <button
          className="pageNumBtn"
          onClick={() => handlePageClick(pageCount)}
        >
          <IcPageEnd className="icon" />
        </button>
      </div>
    )
  );
};

export default Pagination;
