import React, { useState } from "react";
import { useQuery } from "react-query";
import { commonApi } from "../../api/CommonApi";
import { DefaultValue, SelectOptions } from "../../constants/Constants";
import { rotationApi, searchApi } from "../../api/SearchApi";

/**
 * 셀렉트박스 옵션 값
 * @param isDistrict 동 셀렉트박스인지 아닌지 구분
 * @param district  선택된 동 이름
 * @returns {*[]} 교차로 옵션
 * @constructor
 */
const Options = ({ type, dong, crossway, direction }) => {
  // console.log("Options param", type, dong, crossway, direction);
  const [apiData, setApiData] = useState([]);
  const [rotationOptions, setRotationOptions] = useState([]);
  const [directionOptions, setDirectionOptions] = useState([]);
  /**
   * 동과 교차로 옵션값을 가져오는 쿼리
   */
  useQuery(["options"], () => commonApi.options(), {
    enabled: type === SelectOptions.DONG || type === SelectOptions.CROSSWAYS,
    onSuccess: (data) => {
      // console.log("options", data.data.items);
      setApiData([...data.data.items]);
    },
  });
  /**
   * 교차로에 따른 접근 방향을 가져오는 쿼리
   */
  useQuery(
    ["rotationOptions", crossway, direction],
    () => rotationApi.rotationOptions(crossway, direction),
    {
      enabled: type === SelectOptions.ROTATION,
      onSuccess: (data) => {
        // console.log("rotationOptions", data.data.items);
        setRotationOptions(data.data.items);
      },
    }
  );
  /**
   * 교차로와 접근 방향에 따른 진행 방향을 가져오는 쿼리
   */
  useQuery(
    ["directionOptions", crossway],
    () => searchApi.directionOptions(crossway),
    {
      enabled: type === SelectOptions.DIRECTION,
      onSuccess: (data) => {
        // console.log("directionOptions", data.data.items);
        setDirectionOptions(data.data.items);
      },
    }
  );

  let options = [];
  if (type === SelectOptions.DONG) {
    options = apiData.map((item, index) => (
      <option
        value={item.dong}
        selected={item.dong === DefaultValue.DONG}
        key={index}
      >
        {item.dong}
      </option>
    ));
  }
  if (type === SelectOptions.CROSSWAYS) {
    const filteredData = apiData.find((item) => item.dong === dong); // 선택된 동을 기준으로 교차로명을 찾아서 리스트로 출력한다
    if (filteredData) {
      options = filteredData.crossways.map((crossway, index) => (
        <option
          value={crossway}
          selected={crossway === DefaultValue.CROSSWAY}
          key={index}
        >
          {crossway}
        </option>
      ));
    }
  }
  if (type === SelectOptions.DIRECTION) {
    options = directionOptions.map((item, index) => (
      <option value={item} key={index}>
        {item}
      </option>
    ));
  }
  if (type === SelectOptions.ROTATION) {
    options = rotationOptions.map((item, index) => (
      <option value={item} key={index}>
        {item}
      </option>
    ));
  }
  return options;
};
export default React.memo(Options);
