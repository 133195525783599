import React from "react";
import Sidebar from "../contents/autoDrivingData/Sidebar";
import AutoDrivingMap from "../contents/autoDrivingData/section/map/AutoDrivingMap";

const CheckPass = () => {
  return (
    <>
      <Sidebar />
      <AutoDrivingMap />
    </>
  );
};

export default CheckPass;
