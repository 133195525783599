import React from "react";
import clsx from "clsx";

const ModalBtn = ({ onClick, children, className, type }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={clsx(
        "mt-3 inline-flex w-full justify-center rounded-basic bg-white px-3 py-2 textfield shadow-l hover:bg-gray-50 sm:mt-0 sm:w-auto",
        className
      )}
    >
      {children}
    </button>
  );
};

export default ModalBtn;
