import React from "react";
import ApexChart from "react-apexcharts";

const HorizontalBarChart = ({ data, title }) => {
  console.log("HorizontalBarChart", data);
  return (
    <ApexChart
      type="bar"
      series={data}
      height={250}
      width={410}
      options={{
        chart: {
          toolbar: {
            show: true,
          },
          background: "transparent",
          parentHeightOffset: 0,
          offsetX: -45,
        },
        theme: {
          mode: "light",
        },
        title: {
          text: title,
          style: {
            fontSize: "18px",
            fontFamily: "pretendard",
            fontWeight: 600,
          },
          offsetX: 50,
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["black"],
            fontSize: "13px",
            fontFamily: "pretendard",
            fontWeight: 500,
          },
          formatter: function (val, opt) {
            return val + "%";
          },
          offsetX: 500,
        },
        stroke: {
          width: 0,
        },
        grid: { show: false },
        yaxis: {
          show: true,
          max: 100,
          labels: {
            style: {
              fontSize: "13px",
              fontWeight: 500,
              colors: ["white"],
              fontFamily: "pretendard",
            },
            offsetX: 60,
          },
        },
        xaxis: { show: false },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: { position: "bottom" },
          },
        },

        tooltip: {
          x: { formatter: (value) => value },
          y: { formatter: (value) => value + "%" },
        },
      }}
    />
  );
};

export default HorizontalBarChart;
