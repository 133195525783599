import React from "react";

const DetailLogRecord = ({ data, resData }) => {
  console.log("DetailLogRecord resData", resData);

  return (
    <div className="h-650px p-3 overflow-y-scroll border space-y-4">
      {Object.entries(resData).map((item, index) => (
        <div key={index} className=" bg-white rounded-box">
          <div className="py-1 px-4 title2">{item[0]}</div>
          {/*<div className="py-1 px-5 title2">{item.type}</div>*/}
          {/*{console.log(Object.entries(item[1]).map((i) => i.map((ii) => ii)))}*/}
          {Object.entries(item[1]).map((i, iIndex) => (
            <React.Fragment key={iIndex}>
              <div
                key={iIndex}
                className="col-span-3 w-full px-4 border-silver p-[10px] textfield"
              >
                {i.map((k, kIndex) => (
                  <div
                    key={kIndex}
                    className="col-span-3 w-full border-b border-silver textfield"
                  >
                    {Array.isArray(k) ? (
                      k.map((nestedValue, nestedIndex) => (
                        <div
                          key={nestedIndex}
                          className="col-span-3 w-full  border-silver py-[3px] textfield"
                        >
                          {nestedValue}
                        </div>
                      ))
                    ) : (
                      <span className="title1">{k}</span>
                    )}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DetailLogRecord;
