export const SignalDisplayPlanContent = [
  {
    receiveTime: "00:01",
    crosswayId: 1,
    aSPLIT1: 15,
    aSPLIT2: 20,
    aSPLIT3: 15,
    aSPLIT4: 20,
    aSPLIT5: 15,
    aSPLIT6: 25,
    aSPLIT7: 25,
    aSPLIT8: 10,
    bSPLIT1: 25,
    bSPLIT2: 20,
    bSPLIT3: 25,
    bSPLIT4: 15,
    bSPLIT5: 15,
    bSPLIT6: 30,
    bSPLIT7: 25,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:02",
    crosswayId: 2,
    aSPLIT1: 20,
    aSPLIT2: 30,
    aSPLIT3: 20,
    aSPLIT4: 10,
    aSPLIT5: 15,
    aSPLIT6: 10,
    aSPLIT7: 15,
    aSPLIT8: 15,
    bSPLIT1: 10,
    bSPLIT2: 15,
    bSPLIT3: 15,
    bSPLIT4: 10,
    bSPLIT5: 20,
    bSPLIT6: 15,
    bSPLIT7: 15,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:03",
    crosswayId: 3,
    aSPLIT1: 15,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 20,
    aSPLIT5: 30,
    aSPLIT6: 15,
    aSPLIT7: 25,
    aSPLIT8: 20,
    bSPLIT1: 10,
    bSPLIT2: 20,
    bSPLIT3: 30,
    bSPLIT4: 10,
    bSPLIT5: 10,
    bSPLIT6: 10,
    bSPLIT7: 25,
    bSPLIT8: 25,
  },
  {
    receiveTime: "00:04",
    crosswayId: 4,
    aSPLIT1: 25,
    aSPLIT2: 30,
    aSPLIT3: 15,
    aSPLIT4: 10,
    aSPLIT5: 20,
    aSPLIT6: 30,
    aSPLIT7: 15,
    aSPLIT8: 15,
    bSPLIT1: 30,
    bSPLIT2: 20,
    bSPLIT3: 30,
    bSPLIT4: 25,
    bSPLIT5: 25,
    bSPLIT6: 10,
    bSPLIT7: 15,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:05",
    crosswayId: 5,
    aSPLIT1: 15,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 25,
    aSPLIT5: 30,
    aSPLIT6: 25,
    aSPLIT7: 20,
    aSPLIT8: 25,
    bSPLIT1: 20,
    bSPLIT2: 30,
    bSPLIT3: 25,
    bSPLIT4: 15,
    bSPLIT5: 15,
    bSPLIT6: 25,
    bSPLIT7: 15,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:06",
    crosswayId: 6,
    aSPLIT1: 15,
    aSPLIT2: 20,
    aSPLIT3: 15,
    aSPLIT4: 10,
    aSPLIT5: 15,
    aSPLIT6: 10,
    aSPLIT7: 25,
    aSPLIT8: 20,
    bSPLIT1: 15,
    bSPLIT2: 10,
    bSPLIT3: 30,
    bSPLIT4: 25,
    bSPLIT5: 20,
    bSPLIT6: 10,
    bSPLIT7: 20,
    bSPLIT8: 25,
  },
  {
    receiveTime: "00:07",
    crosswayId: 7,
    aSPLIT1: 30,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 25,
    aSPLIT5: 10,
    aSPLIT6: 30,
    aSPLIT7: 20,
    aSPLIT8: 25,
    bSPLIT1: 15,
    bSPLIT2: 15,
    bSPLIT3: 25,
    bSPLIT4: 10,
    bSPLIT5: 10,
    bSPLIT6: 15,
    bSPLIT7: 10,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:08",
    crosswayId: 8,
    aSPLIT1: 20,
    aSPLIT2: 25,
    aSPLIT3: 25,
    aSPLIT4: 25,
    aSPLIT5: 30,
    aSPLIT6: 20,
    aSPLIT7: 25,
    aSPLIT8: 25,
    bSPLIT1: 15,
    bSPLIT2: 30,
    bSPLIT3: 20,
    bSPLIT4: 30,
    bSPLIT5: 15,
    bSPLIT6: 20,
    bSPLIT7: 20,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:09",
    crosswayId: 9,
    aSPLIT1: 15,
    aSPLIT2: 15,
    aSPLIT3: 25,
    aSPLIT4: 15,
    aSPLIT5: 25,
    aSPLIT6: 20,
    aSPLIT7: 20,
    aSPLIT8: 30,
    bSPLIT1: 20,
    bSPLIT2: 30,
    bSPLIT3: 20,
    bSPLIT4: 20,
    bSPLIT5: 15,
    bSPLIT6: 25,
    bSPLIT7: 20,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:10",
    crosswayId: 10,
    aSPLIT1: 30,
    aSPLIT2: 15,
    aSPLIT3: 25,
    aSPLIT4: 20,
    aSPLIT5: 15,
    aSPLIT6: 30,
    aSPLIT7: 30,
    aSPLIT8: 20,
    bSPLIT1: 30,
    bSPLIT2: 10,
    bSPLIT3: 10,
    bSPLIT4: 25,
    bSPLIT5: 20,
    bSPLIT6: 15,
    bSPLIT7: 10,
    bSPLIT8: 10,
  },
  {
    receiveTime: "00:11",
    crosswayId: 11,
    aSPLIT1: 15,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 20,
    aSPLIT5: 30,
    aSPLIT6: 15,
    aSPLIT7: 25,
    aSPLIT8: 20,
    bSPLIT1: 20,
    bSPLIT2: 30,
    bSPLIT3: 25,
    bSPLIT4: 20,
    bSPLIT5: 20,
    bSPLIT6: 15,
    bSPLIT7: 15,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:12",
    crosswayId: 12,
    aSPLIT1: 10,
    aSPLIT2: 25,
    aSPLIT3: 15,
    aSPLIT4: 15,
    aSPLIT5: 15,
    aSPLIT6: 25,
    aSPLIT7: 15,
    aSPLIT8: 15,
    bSPLIT1: 15,
    bSPLIT2: 30,
    bSPLIT3: 15,
    bSPLIT4: 30,
    bSPLIT5: 15,
    bSPLIT6: 10,
    bSPLIT7: 10,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:13",
    crosswayId: 13,
    aSPLIT1: 20,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 15,
    aSPLIT5: 25,
    aSPLIT6: 10,
    aSPLIT7: 30,
    aSPLIT8: 10,
    bSPLIT1: 20,
    bSPLIT2: 15,
    bSPLIT3: 25,
    bSPLIT4: 25,
    bSPLIT5: 30,
    bSPLIT6: 15,
    bSPLIT7: 30,
    bSPLIT8: 10,
  },
  {
    receiveTime: "00:14",
    crosswayId: 14,
    aSPLIT1: 20,
    aSPLIT2: 30,
    aSPLIT3: 20,
    aSPLIT4: 10,
    aSPLIT5: 25,
    aSPLIT6: 25,
    aSPLIT7: 15,
    aSPLIT8: 20,
    bSPLIT1: 10,
    bSPLIT2: 25,
    bSPLIT3: 30,
    bSPLIT4: 25,
    bSPLIT5: 10,
    bSPLIT6: 15,
    bSPLIT7: 25,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:15",
    crosswayId: 15,
    aSPLIT1: 15,
    aSPLIT2: 20,
    aSPLIT3: 10,
    aSPLIT4: 15,
    aSPLIT5: 10,
    aSPLIT6: 30,
    aSPLIT7: 25,
    aSPLIT8: 25,
    bSPLIT1: 15,
    bSPLIT2: 15,
    bSPLIT3: 20,
    bSPLIT4: 25,
    bSPLIT5: 25,
    bSPLIT6: 30,
    bSPLIT7: 25,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:16",
    crosswayId: 16,
    aSPLIT1: 10,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 15,
    aSPLIT5: 15,
    aSPLIT6: 25,
    aSPLIT7: 20,
    aSPLIT8: 30,
    bSPLIT1: 30,
    bSPLIT2: 20,
    bSPLIT3: 15,
    bSPLIT4: 15,
    bSPLIT5: 15,
    bSPLIT6: 25,
    bSPLIT7: 15,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:17",
    crosswayId: 17,
    aSPLIT1: 10,
    aSPLIT2: 25,
    aSPLIT3: 25,
    aSPLIT4: 20,
    aSPLIT5: 20,
    aSPLIT6: 10,
    aSPLIT7: 15,
    aSPLIT8: 25,
    bSPLIT1: 30,
    bSPLIT2: 30,
    bSPLIT3: 30,
    bSPLIT4: 15,
    bSPLIT5: 20,
    bSPLIT6: 20,
    bSPLIT7: 30,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:18",
    crosswayId: 18,
    aSPLIT1: 10,
    aSPLIT2: 25,
    aSPLIT3: 10,
    aSPLIT4: 20,
    aSPLIT5: 25,
    aSPLIT6: 20,
    aSPLIT7: 10,
    aSPLIT8: 15,
    bSPLIT1: 15,
    bSPLIT2: 25,
    bSPLIT3: 25,
    bSPLIT4: 20,
    bSPLIT5: 25,
    bSPLIT6: 25,
    bSPLIT7: 10,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:19",
    crosswayId: 19,
    aSPLIT1: 15,
    aSPLIT2: 20,
    aSPLIT3: 20,
    aSPLIT4: 25,
    aSPLIT5: 30,
    aSPLIT6: 25,
    aSPLIT7: 20,
    aSPLIT8: 10,
    bSPLIT1: 15,
    bSPLIT2: 25,
    bSPLIT3: 30,
    bSPLIT4: 25,
    bSPLIT5: 10,
    bSPLIT6: 10,
    bSPLIT7: 25,
    bSPLIT8: 30,
  },
  {
    receiveTime: "00:20",
    crosswayId: 20,
    aSPLIT1: 20,
    aSPLIT2: 20,
    aSPLIT3: 10,
    aSPLIT4: 30,
    aSPLIT5: 15,
    aSPLIT6: 25,
    aSPLIT7: 30,
    aSPLIT8: 20,
    bSPLIT1: 20,
    bSPLIT2: 15,
    bSPLIT3: 20,
    bSPLIT4: 25,
    bSPLIT5: 10,
    bSPLIT6: 15,
    bSPLIT7: 15,
    bSPLIT8: 25,
  },
  {
    receiveTime: "00:21",
    crosswayId: 21,
    aSPLIT1: 25,
    aSPLIT2: 10,
    aSPLIT3: 30,
    aSPLIT4: 20,
    aSPLIT5: 20,
    aSPLIT6: 25,
    aSPLIT7: 15,
    aSPLIT8: 15,
    bSPLIT1: 10,
    bSPLIT2: 15,
    bSPLIT3: 15,
    bSPLIT4: 20,
    bSPLIT5: 10,
    bSPLIT6: 20,
    bSPLIT7: 10,
    bSPLIT8: 10,
  },
  {
    receiveTime: "00:22",
    crosswayId: 22,
    aSPLIT1: 30,
    aSPLIT2: 20,
    aSPLIT3: 20,
    aSPLIT4: 15,
    aSPLIT5: 25,
    aSPLIT6: 15,
    aSPLIT7: 15,
    aSPLIT8: 25,
    bSPLIT1: 25,
    bSPLIT2: 25,
    bSPLIT3: 15,
    bSPLIT4: 25,
    bSPLIT5: 25,
    bSPLIT6: 10,
    bSPLIT7: 20,
    bSPLIT8: 25,
  },
  {
    receiveTime: "00:23",
    crosswayId: 23,
    aSPLIT1: 15,
    aSPLIT2: 20,
    aSPLIT3: 20,
    aSPLIT4: 15,
    aSPLIT5: 30,
    aSPLIT6: 20,
    aSPLIT7: 25,
    aSPLIT8: 20,
    bSPLIT1: 20,
    bSPLIT2: 20,
    bSPLIT3: 25,
    bSPLIT4: 20,
    bSPLIT5: 20,
    bSPLIT6: 10,
    bSPLIT7: 30,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:24",
    crosswayId: 24,
    aSPLIT1: 15,
    aSPLIT2: 25,
    aSPLIT3: 25,
    aSPLIT4: 30,
    aSPLIT5: 10,
    aSPLIT6: 30,
    aSPLIT7: 30,
    aSPLIT8: 15,
    bSPLIT1: 20,
    bSPLIT2: 25,
    bSPLIT3: 20,
    bSPLIT4: 10,
    bSPLIT5: 30,
    bSPLIT6: 25,
    bSPLIT7: 20,
    bSPLIT8: 30,
  },
  {
    receiveTime: "00:25",
    crosswayId: 25,
    aSPLIT1: 10,
    aSPLIT2: 20,
    aSPLIT3: 25,
    aSPLIT4: 30,
    aSPLIT5: 20,
    aSPLIT6: 20,
    aSPLIT7: 25,
    aSPLIT8: 15,
    bSPLIT1: 20,
    bSPLIT2: 15,
    bSPLIT3: 25,
    bSPLIT4: 10,
    bSPLIT5: 30,
    bSPLIT6: 20,
    bSPLIT7: 10,
    bSPLIT8: 10,
  },
  {
    receiveTime: "00:26",
    crosswayId: 26,
    aSPLIT1: 25,
    aSPLIT2: 10,
    aSPLIT3: 10,
    aSPLIT4: 20,
    aSPLIT5: 30,
    aSPLIT6: 10,
    aSPLIT7: 25,
    aSPLIT8: 20,
    bSPLIT1: 15,
    bSPLIT2: 25,
    bSPLIT3: 15,
    bSPLIT4: 15,
    bSPLIT5: 10,
    bSPLIT6: 20,
    bSPLIT7: 15,
    bSPLIT8: 25,
  },
  {
    receiveTime: "00:27",
    crosswayId: 27,
    aSPLIT1: 15,
    aSPLIT2: 10,
    aSPLIT3: 25,
    aSPLIT4: 30,
    aSPLIT5: 30,
    aSPLIT6: 20,
    aSPLIT7: 30,
    aSPLIT8: 15,
    bSPLIT1: 30,
    bSPLIT2: 25,
    bSPLIT3: 20,
    bSPLIT4: 15,
    bSPLIT5: 25,
    bSPLIT6: 15,
    bSPLIT7: 30,
    bSPLIT8: 25,
  },
  {
    receiveTime: "00:28",
    crosswayId: 28,
    aSPLIT1: 25,
    aSPLIT2: 20,
    aSPLIT3: 15,
    aSPLIT4: 10,
    aSPLIT5: 10,
    aSPLIT6: 30,
    aSPLIT7: 10,
    aSPLIT8: 30,
    bSPLIT1: 25,
    bSPLIT2: 30,
    bSPLIT3: 25,
    bSPLIT4: 10,
    bSPLIT5: 10,
    bSPLIT6: 25,
    bSPLIT7: 25,
    bSPLIT8: 15,
  },
  {
    receiveTime: "00:29",
    crosswayId: 29,
    aSPLIT1: 30,
    aSPLIT2: 15,
    aSPLIT3: 20,
    aSPLIT4: 15,
    aSPLIT5: 30,
    aSPLIT6: 10,
    aSPLIT7: 25,
    aSPLIT8: 15,
    bSPLIT1: 10,
    bSPLIT2: 30,
    bSPLIT3: 15,
    bSPLIT4: 20,
    bSPLIT5: 20,
    bSPLIT6: 25,
    bSPLIT7: 15,
    bSPLIT8: 20,
  },
  {
    receiveTime: "00:30",
    crosswayId: 30,
    aSPLIT1: 25,
    aSPLIT2: 15,
    aSPLIT3: 15,
    aSPLIT4: 25,
    aSPLIT5: 25,
    aSPLIT6: 25,
    aSPLIT7: 30,
    aSPLIT8: 20,
    bSPLIT1: 20,
    bSPLIT2: 15,
    bSPLIT3: 15,
    bSPLIT4: 30,
    bSPLIT5: 15,
    bSPLIT6: 20,
    bSPLIT7: 20,
    bSPLIT8: 25,
  },
];
