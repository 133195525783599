import React, { useEffect, useState } from "react";
import KakaoMap from "../../../../components/map/KakaoMap";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { signalResultApi } from "../../../../api/SearchApi";
import { resetSignalResultSearch } from "../../../../store/features/signalResult/signalResultSlice";

const SignalResultMap = () => {
  const [coordinateData, setCoordinateData] = useState([]);
  const [arrowDataContent, setArrowDataContent] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSignalResultSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * 범례에 따른 화살표 색상 변경
   * @param volume 통과 교통량
   * @returns {string}  색상
   */
  const arrowColor = (volume) => {
    let res = "";
    if (volume >= 0 && volume < 10) {
      res = "#1fc55d";
    } else if (volume >= 10 && volume < 20) {
      res = "#fde047";
    } else if (volume >= 20 && volume < 30) {
      res = "#f97315";
    } else if (volume >= 30) {
      res = "#ee4444";
    }
    return res;
  };
  const findCenter = (search) => {
    const res = coordinateData.find((i) => i.crossway === search.crossway);
    // console.log("res", res);
    return res;
  };
  // 화살표 데이터 받아오는 api
  // 검색 시 교차로 중심좌표 받아오는 api
  const search = useSelector((state) => state.searchData.search);

  const center = findCenter(search);

  const { isFetching, isLoading } = useQuery(
    ["signalResultArrow", search],
    () => signalResultApi.signalResultArrow(search),
    {
      onSuccess: (data) => {
        // console.log(
        //   "signalResultApi onSuccess 넘어온 데이터 값",
        //   data.data.items
        // );
        setCoordinateData(
          data.data.items.map((item) => Object.values(item)[0])
        );

        setArrowDataContent(
          data.data.items.flatMap((items) =>
            Object.values(items)[0].arrows.map((item) => ({
              ...item,
              crossway: Object.values(items)[0].crossway,
              type: "arrow",
              coordinate: "wgs84",
              options: {
                strokeColor: arrowColor(item.queue),
                strokeWeight: 8,
                strokeStyle: "solid",
                strokeOpacity: 1,
                endArrow: true,
              },
            }))
          )
        );
      },
    }
  );

  return (
    <>
      {!isLoading && !isFetching && arrowDataContent.length > 0 ? (
        <KakaoMap
          // arrowData={arrowData}
          arrowData={{ arrow: arrowDataContent }}
          coordinates={coordinateData}
          center={center}
        />
      ) : (
        <div className="flex justify-center items-center grow w-screen">
          <p className="loading">데이터를 불러오는 중입니다</p>
        </div>
      )}
    </>
  );
};

export default SignalResultMap;
