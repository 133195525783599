import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SearchBtn from "../../../components/button/SearchBtn";
import Calendar from "../../../utils/calendar/Calendar";
import Options from "../../../components/select/Options";
import { SelectOptions } from "../../../constants/Constants";
import { format } from "date-fns";

import {
  setCrossway,
  setDirection,
  setDong,
  setRotation,
  setSearch,
} from "../../../store/features/search/searchSlice";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const SearchForm = () => {
  const { control, handleSubmit, register, reset } = useForm();
  const dispatch = useDispatch();
  const search = useSelector((state) => state.searchData.search);
  const location = useLocation().pathname;
  const isRotation = location.includes("rotation");
  const isPass = location.includes("pass");
  const isSignal = location.includes("signal");
  const startDate = useSelector((state) => state.calendar.startDate); // 종료 날짜를 정하지 않았을 경우 api로 전달할 시작 날짜 값
  const today = new Date();
  // const search = useSelector((state) => state.checkRotation.search);
  let defaultValues = {};
  useEffect(() => {
    defaultValues.dong = search.dong;
    defaultValues.crossway = search.crossway;
    defaultValues.direction = search.direction;
    defaultValues.rotation = search.rotation;
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValid = (data) => {
    data.startDate = format(new Date(startDate || today), "yyyy-MM-dd");
    data.endDate = format(
      new Date(data?.endDate || startDate || today),
      "yyyy-MM-dd"
    );
    console.log("rotationTrafficSidebar data", data);

    // 검색어로 그래프와 지도 업데이트를 위한 액션 디스패치
    dispatch(setSearch({ search: data }));
  };

  /**
   * 동 선택 시 상태 등록 함수
   * @param e onChange 이벤트 객체
   */
  const handleChangeDong = (e) => dispatch(setDong(e.target.value));
  /**
   * 사거리 선택 시 상태 등록 함수
   * @param e onChange 이벤트 객체
   */
  const handleChangeCrossway = (e) => dispatch(setCrossway(e.target.value));
  /**
   * 접근 방향 선택 시 상태 등록 함수
   * @param e onChange 이벤트 객체
   */
  const handleChangeDIRECTION = (e) => dispatch(setDirection(e.target.value));
  /**
   * 회전 방향 선택 시 상태 등록 함수
   * @param e onChange 이벤트 객체
   */
  const handleChangeRotation = (e) => dispatch(setRotation(e.target.value));

  return (
    <form
      className="sideBarContainer space-y-[10px]"
      onSubmit={handleSubmit(onValid)}
    >
      <div className="calWrapper relative">
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <Calendar field={field} isStart={true} inputLen={"w-[200px]"} />
          )}
        />
        <hr className="w-[10px] bg-silver" />
        <Controller
          name="endDate"
          control={control}
          render={({ field }) => (
            <Calendar field={field} inputLen={"w-[200px]"} />
          )}
        />
      </div>

      <div
        className={clsx({
          "grid grid-cols-2 gap-[10px] justify-center": isRotation || isSignal,
          "flex justify-center space-x-[10px]": isPass,
        })}
      >
        <select
          className="sideBarSelectBox w-[200px]"
          {...register(SelectOptions.DONG)}
          defaultValue={defaultValues.dong}
          onChange={handleChangeDong}
          value={search.dong}
        >
          <Options type={SelectOptions.DONG} />
        </select>
        <select
          className="sideBarSelectBox w-[200px]"
          {...register(SelectOptions.CROSSWAY)}
          defaultValue={defaultValues.crossway}
          onChange={handleChangeCrossway}
          value={search.crossway}
        >
          <Options type={SelectOptions.CROSSWAYS} dong={search.dong} />
        </select>
        {isSignal ? (
          <></>
        ) : (
          <select
            className="sideBarSelectBox w-[200px]"
            {...register(SelectOptions.DIRECTION)}
            onChange={handleChangeDIRECTION}
            value={search.direction}
          >
            <Options
              type={SelectOptions.DIRECTION}
              crossway={search.crossway}
            />
          </select>
        )}
        {isPass || isSignal ? (
          <></>
        ) : (
          <select
            className="sideBarSelectBox w-[200px]"
            {...register(SelectOptions.ROTATION)}
            onChange={handleChangeRotation}
            value={search.rotation}
          >
            <Options
              type={SelectOptions.ROTATION}
              direction={search.direction}
              crossway={search.crossway}
            />
          </select>
        )}
      </div>
      <SearchBtn>검색</SearchBtn>
      {isRotation ? (
        <div className="text-center p-3 border textfield rounded-basic">
          <span className="title1">{search.dong}</span>

          <span>
            {" "}
            <span className="title1">{search.crossway}</span> 교차로
          </span>

          <span>
            {" "}
            <span className="title1">{search.direction}</span> 접근
          </span>

          <span>
            {" "}
            <span className="title1">{search.rotation}</span> 진행
          </span>
        </div>
      ) : (
        <></>
      )}
    </form>
  );
};

export default SearchForm;
