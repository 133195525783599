import React from "react";
import { useRoutes } from "react-router-dom";
import Login from "../pages/login/Login";
import FindAccount from "../pages/login/FindAccount";
import Dashboard from "../pages/Dashboard";
import Monitoring from "../pages/Monitoring";
import SignalResult from "../pages/SignalResult";
import CheckPass from "../pages/check/CheckPass";
import CheckRotation from "../pages/check/CheckRotation";
import CheckSignal from "../pages/check/CheckSignal";
import AutoDrivingData from "../pages/AutoDrivingData";
import SendingDataLog from "../pages/log/SendingDataLog";
import DetailLog from "../pages/log/DetailLog";
import UserAccount from "../pages/account/user/UserAccount";
import AdminAccount from "../pages/account/admin/AdminAccount";
import SuperAccount from "../pages/account/superAdmin/SuperAccount";
import AdminAccountDetail from "../pages/account/admin/AdminAccountDetail";
import SuperAccountDetail from "../pages/account/superAdmin/SuperAccountDetail";
import SuperAccountCo from "../pages/account/superAdmin/SuperAccountCo";
import ProtectedRoute from "./ProtectedRoute";
import SendingDataLogDetailRecord from "../pages/log/SendingDataLogDetailRecord";

export default function Router() {
  const element = useRoutes([
    { path: "/", element: <Login /> },
    { path: "find-account", element: <FindAccount /> },

    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "monitoring", element: <Monitoring /> },
        { path: "check-pass", element: <CheckPass /> },
        { path: "check-rotation", element: <CheckRotation /> },
        { path: "check-signal", element: <CheckSignal /> },
        { path: "signal-result", element: <SignalResult /> },
        { path: "auto-driving-data", element: <AutoDrivingData /> },
        {
          path: "data-sending-log",
          children: [
            { index: true, element: <SendingDataLog /> },
            // { path: "detail/:No", element: <SendingDataLogDetail /> },
            { path: "detail/:No", element: <SendingDataLogDetailRecord /> },
          ],
        },
        { path: "detail-log", element: <DetailLog /> },
        { path: "user", element: <UserAccount /> },
        {
          path: "admin",
          children: [
            { index: true, element: <AdminAccount /> },
            { path: "detail/:No", element: <AdminAccountDetail /> },
            { path: "add", element: <AdminAccountDetail /> },
          ],
        },
        {
          path: "super",
          children: [
            { index: true, element: <SuperAccount /> },
            { path: "detail/:No", element: <SuperAccountDetail /> },
            { path: "add", element: <SuperAccountDetail /> },
            { path: "co", element: <SuperAccountCo /> },
          ],
        },
      ],
    },
  ]);
  return element;
}
