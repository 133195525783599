export const SelectOptions = {
  SIDO: "sido", // 시도
  SIGUNGU: "sigungu", // 시군구
  DONG: "dong", // 동
  CROSSWAYS: "crossways", // 교차로 목록
  CROSSWAY: "crossway", // 선택된 교차로
  DIRECTION: "direction", // 진행방향
  ROTATION: "rotation", // 회전방향
};

export const DefaultValue = {
  DONG: "정왕1동", // 동
  CROSSWAY: "정왕 사거리", // 선택된 교차로
  DIRECTION: "전체 접근", // 진행방향
  ROTATION: "전체", // 회전방향
};

export const Role = {
  USER: "회원",
  ADMIN: "회사 관리자",
  SUPER: "관리자",
};
