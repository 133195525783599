import React, { useState } from "react";
import { ReactComponent as Img1 } from "../../../asset/image/before/ic_sign_a.svg";
import { ReactComponent as Img2 } from "../../../asset/image/before/ic_sign_b.svg";
import { ReactComponent as Img3 } from "../../../asset/image/after/ic_sign_f.svg";
import { ReactComponent as Img4 } from "../../../asset/image/after/ic_sign_g.svg";
import { useQuery } from "react-query";
import { signalResultApi } from "../../../api/SearchApi";
import { useSelector } from "react-redux";
import Applying from "./Applying";

const SignalStatus = () => {
  const [isApplying, setIsApplying] = useState(true);
  setTimeout(() => {
    setIsApplying(false);
  }, 4000);
  const search = useSelector((state) => state.searchData.search);
  const [before, setBefore] = useState("");
  const [current, setCurrent] = useState("");
  const [signalPattern, setSignalPattern] = useState([]);
  const { isSuccess, isFetching, isLoading } = useQuery(
    ["signalStatus", search],
    () => signalResultApi.signalStatus(search),
    {
      onSuccess: (data) => {
        console.log("signalStatus", data.data);
        setIsApplying(true);
        setBefore(data.data.beforeSignal);
        setCurrent(data.data.currentSignal);
        setSignalPattern(data.data.signalPattern);
      },
    }
  );

  return (
    <>
      {isApplying ? <Applying /> : null}
      <div className="mt-[20px] sideBarContent space-y-3">
        <div className="w-full title1">신호 적용 현시</div>
        {isSuccess && !isFetching && !isLoading ? (
          <>
            <div className="flex space-x-2">
              <p className="flex flex-col justify-center items-center bg-pale-grey w-full py-1 rounded-lg text-sm">
                <span className="textfield">신호 적용 이전</span>
                <span className="textfield font-bold">{before}</span>
              </p>
              <p className="flex flex-col justify-center items-center bg-orange-500 text-white w-full rounded-lg text-sm">
                <span className="textfield">신호 적용 이후</span>
                <span className="textfield font-bold">{current}</span>
              </p>
            </div>
            <div className="flex gap-2">
              <div className="grid grid-cols-2 w-1/2 gap-1 ">
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center text-sm font-semibold">{`1현시 (${signalPattern[0]})`}</div>
                  <div className="bg-white flex justify-center">
                    <Img1 />
                  </div>
                  <div className="text-center text-sm font-semibold">20/3</div>
                </div>
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center  text-sm font-semibold">{`2현시 (${signalPattern[1]})`}</div>
                  <div className="bg-white flex justify-center">
                    <Img2 />
                  </div>
                  <div className="text-center text-sm font-semibold">25/3</div>
                </div>
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center  text-sm font-semibold">{`3현시 (${signalPattern[2]})`}</div>
                  <div className="bg-white flex justify-center">
                    <Img3 />
                  </div>
                  <div className="text-center text-sm font-semibold">43/3</div>
                </div>
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center  text-sm font-semibold">{`4현시 (${signalPattern[3]})`}</div>
                  <div className="bg-white flex justify-center">
                    <div className="bg-white flex justify-center">
                      <Img4 />
                    </div>
                  </div>
                  <div className="text-center text-sm font-semibold">20/3</div>
                </div>
              </div>
              <div className="grid grid-cols-2 w-1/2 gap-1">
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center text-sm font-semibold">1현시</div>
                  <div className="bg-white flex justify-center">
                    <Img1 />
                  </div>
                  <div className="text-center text-sm font-semibold">
                    20
                    <span className="text-red-500 mx-1 font-extrabold">
                      {isApplying ? null : "(-3)"}
                    </span>
                    /3
                  </div>
                </div>
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center  text-sm font-semibold">
                    2현시
                  </div>
                  <div className="bg-white flex justify-center">
                    <Img2 />
                  </div>
                  <div className="text-center text-sm font-semibold">
                    25
                    <span className="text-green-500 mx-1 font-extrabold">
                      {isApplying ? null : "(+5)"}
                    </span>
                    /3
                  </div>
                </div>
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center  text-sm font-semibold">
                    3현시
                  </div>
                  <div className="bg-white flex justify-center">
                    <Img3 />
                  </div>
                  <div className="text-center text-sm font-semibold">
                    43
                    <span className="text-green-500 mx-1 font-extrabold">
                      {isApplying ? null : "(+3)"}
                    </span>
                    /3
                  </div>
                </div>
                <div className="flex flex-col bg-pale-grey-two border rounded-basic">
                  <div className="text-center  text-sm font-semibold">
                    4현시
                  </div>
                  <div className="bg-white flex justify-center">
                    <Img4 />
                  </div>
                  <div className="text-center text-sm font-bold">
                    20
                    <span className="text-red-500 mx-1 font-extrabold">
                      {isApplying ? null : "(-5)"}
                    </span>
                    /3
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="loading h-[330px]">데이터를 불러오는 중입니다</div>
        )}
      </div>
    </>
  );
};

export default SignalStatus;
