import React from "react";
import { ReactComponent as IcNormal } from "../../asset/icon/ic_normal.svg";
import { ReactComponent as IcError } from "../../asset/icon/ic_error.svg";
const Status = () => {
  return (
    <p className="flex caption">
      <IcNormal className="w-[20px] mr-1" />
      <span className="text-normal-green mr-2">Normal</span>
      <IcError className="w-[20px] mr-1" />
      <span className="text-dark-pink">Error</span>
    </p>
  );
};

export default Status;
