import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  showFindModal,
  showPass,
} from "../../store/features/account/findAccountSlice";
import { useNavigate } from "react-router-dom";
import ModalBtn from "../../components/button/ModalBtn";
import { useQuery } from "react-query";
import { findAccountApi } from "../../api/FindAccountApi";

/**
 * 전달받은 findId 값(boolean)으로 비밀번호 찾기와 아이디 찾기를 구분한다
 * 전달받은 userInfo 값으로 아이디 혹은 비밀번호를 찾는다
 * @param findId  아이디 찾기인지 비밀번호 찾기인지 구분하는 Boolean 값
 * @param userInfo  회원 정보 (Id, Passwrod)
 * @returns {JSX.Element}
 * @constructor
 */
export default function FindModal({ findId, userInfo }) {
  console.log("FindModal 넘어온 userInfo 값", userInfo);
  const dispatch = useDispatch();
  const show = useSelector((state) => state.findAccount.show); // 모달이 표시될지 여부를 나타내는 상태값
  const cancelButtonRef = useRef(null); // 모달 취소 버튼
  const navigate = useNavigate();

  const [idRes, setIdRes] = useState("11"); // api에서 받은 id 값
  const [pwRes, setPwRes] = useState("11"); // api에서 받은 password 값

  useQuery(["findId", userInfo], () => findAccountApi.findId(userInfo), {
    retry: false,
    enabled: findId && Object.entries(userInfo).length > 0,
    onSuccess: (idData) => {
      console.log("findId", idData?.data?.items[0]?.id, idData?.message);
      setIdRes(idData?.data?.items[0]?.id);
    },
    onError: (error) => {
      if (error.code === 400) {
        setIdRes("");
      }
    },
  });

  useQuery(["findPass", userInfo], () => findAccountApi.findPass(userInfo), {
    retry: false,
    enabled: !findId && Object.entries(userInfo).length > 0,
    onSuccess: (pwData) => {
      console.log("findPass", pwData);
      console.log("findPass", pwData?.data, pwData?.message);
      setPwRes("존재");
    },
    onError: (error) => {
      setPwRes("");
    },
  });

  useEffect(() => {
    setIdRes("");
    setPwRes("");
  }, []);

  /**
   * 비밀번호 찾기 버튼을 클릭했을 때, 해당 모달을 종료하고 아이디 찾기로 전환하기 위한 핸들러 함수
   * 'showFindModal' 액션을 통해 모달을 숨기고, 'showPass' 액션을 통해 비밀번호 찾기 모달을 표시
   */
  const handleFindPass = () => {
    dispatch(showFindModal({ show: false }));
    dispatch(showPass({ show_pass: true }));
  };

  const handleLoginBtn = () => {
    dispatch(showFindModal({ show: false }));
    navigate("/"); // 로그인 페이지로 이동
    dispatch(showPass({ show_pass: false }));
  };

  /*
    userInfo 를 매개변수로 api를 호출하여 회원 정보를 가져오거나 수정하여 상태 값(setIdRes, setPwRes) 변경
  */

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(showFindModal({ show: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex justify-center items-center">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-center leading-6 title1"
                      >
                        {findId ? "아이디" : "비밀번호"} 찾기 결과
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="my-7 text-center textfield">
                          {findId ? (
                            idRes ? ( // 아이디 찾기 결과가 있을 경우
                              <>
                                <div className="mb-5">
                                  {/*<p>{msg}</p>*/}
                                  <p>
                                    입력하신 정보와 일치하는 아이디는 다음과
                                    같습니다
                                    <br />
                                    개인정보 보호를 위해 일부분은 *으로
                                    표시됩니다
                                  </p>
                                </div>
                                {findId ? (
                                  <div className="p-3 border h-1/3 ">
                                    {idRes}
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              // 아이디 찾기 결과가 없을 경우
                              <>
                                <div className="mb-4">
                                  {/*<p>{msg}</p>*/}
                                  <p>
                                    입력하신 정보와 일치하는 아이디가 없습니다
                                    <br />
                                    정보를 다시 확인하시고 시도해주시기 바랍니다
                                  </p>
                                </div>
                              </>
                            )
                          ) : //     (
                          //   <>
                          //     <div className="mb-4">
                          //       {/*<p>{msg}</p>*/}
                          //       {/*<p>*/}
                          //       {/*  입력하신 정보와 일치하는 아이디가 없습니다*/}
                          //       {/*  <br />*/}
                          //       {/*  정보를 다시 확인하시고 시도해주세요*/}
                          //       {/*</p>*/}
                          //     </div>
                          //   </>
                          // )

                          pwRes ? ( // 비밀번호 찾기 결과가 있을 경우
                            <>
                              <div className="mb-4">
                                {/*<p>{msg}</p>*/}
                                <p>
                                  비밀번호가 연락처로 초기화되었습니다
                                  <br />
                                  로그인 후 비밀번호를 변경해주시기 바랍니다
                                </p>
                              </div>
                            </>
                          ) : (
                            // 비밀번호 찾기 결과가 없을 경우
                            <>
                              <div className="mb-4">
                                <p>
                                  입력하신 정보와 일치하는 비밀번호가 없습니다
                                  <br />
                                  정보를 다시 확인하시고 시도해주시기 바랍니다
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-pale-grey px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {!!findId && !!idRes ? (
                    <>
                      <ModalBtn
                        type="button"
                        onClick={handleFindPass} // 'handleFindPass' 함수 호출
                        ref={cancelButtonRef}
                      >
                        비밀번호 찾기
                      </ModalBtn>
                      <ModalBtn
                        className="mr-3"
                        type="button"
                        onClick={() => handleLoginBtn()}
                      >
                        로그인
                      </ModalBtn>
                    </>
                  ) : (
                    <>
                      <ModalBtn
                        type="button"
                        onClick={() => dispatch(showFindModal({ show: false }))}
                      >
                        확인
                      </ModalBtn>
                      <ModalBtn
                        className="mr-3"
                        type="button"
                        onClick={() => handleLoginBtn()}
                      >
                        로그인
                      </ModalBtn>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
