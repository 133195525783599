import React from "react";

const Applying = () => {
  return (
    <div className="flex justify-center items-center fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-5 justify-center mb-8">
          <div className="animate-[bounce_1s_infinite_100ms] bg-white w-4 h-5 rounded"></div>
          <div className="animate-[bounce_1s_infinite_250ms] bg-white w-4 h-5 rounded"></div>
          <div className="animate-[bounce_1s_infinite_400ms] bg-white w-4 h-5 rounded"></div>
        </div>

        <div className="text-white text-xl">시뮬레이션 결과 값을</div>
        <div className="text-white text-xl text-center">적용 중입니다</div>
      </div>
    </div>
  );
};

export default Applying;
