import React from "react";
import DetailLogContent from "../../contents/log/DetailLogContent";

const DetailLog = () => {
  return (
    <div className="content">
      <DetailLogContent />
    </div>
  );
};

export default DetailLog;
