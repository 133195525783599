import axios from "axios";
import { format } from "date-fns";
const today = format(new Date(), "yyyy-MM-dd");
export const LogApi = {
  detailLog: async (params) => {
    console.log("detailLog params", params);
    const { start_time, end_time, type, keyword, date } = params;
    const response = await axios.get("/log/detail", {
      params: {
        start_time: start_time || "00:00",
        end_time: end_time || "23:59",
        type: type || "전체",
        keyword,
        date: date || today,
      },
    });
    return response.data;
  },
};
