import React from "react";
import SendingDataContent from "../../contents/log/SendingDataContent";

const SendingDataLog = () => {
  return (
    <div className="content">
      <SendingDataContent />
    </div>
  );
};

export default SendingDataLog;
