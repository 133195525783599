import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  setSearchOptions,
  showSearchOptionsModal,
} from "../../../store/features/account/searchOptionsSlice";
import Calendar from "../../../utils/calendar/Calendar";
import ModalBtn from "../../../components/button/ModalBtn";
import { format } from "date-fns";
import { resetPageNum } from "../../../store/features/common/paginationSlice";
import AccountOptions from "../../../components/select/AccountOptions";

export default function AdminAccountSearchOptionsModal({ inputRef }) {
  const { handleSubmit, register, control, reset } = useForm();
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.calendar.startDate); // 종료 날짜를 정하지 않았을 경우 api로 전달할 시작 날짜 값
  const show = useSelector((state) => state.searchOptionsModal.show); // 검색 옵션 설정 모달이 표시될지 여부를 나타내는 상태값
  const cancelButtonRef = useRef(null); // 모달 취소 버튼
  const today = new Date();
  useEffect(() => {
    dispatch(resetPageNum()); // 페이지 값 리셋
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * 폼 제출 시 검색 조건 상태 관리 설정 및 검색 옵션 설정 모달 종료 함수
   * @param data 폼 제출 시 입력된 데이터
   */
  const onValid = (data) => {
    dispatch(resetPageNum()); // 페이지 값 리셋
    console.log("검색 옵션", data);
    data.startDate = format(new Date(data?.startDate || today), "yyyy-MM-dd");
    data.endDate = format(
      new Date(data?.endDate || startDate || today),
      "yyyy-MM-dd"
    );
    dispatch(setSearchOptions({ search: data }));
    dispatch(showSearchOptionsModal({ show: false }));
    reset();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(showSearchOptionsModal({ show: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-end p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative -top-[180px] mr-[80px] transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={handleSubmit(onValid)}>
                  <div className="rounded-t-box bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex justify-center items-center">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-center title1">
                          검색 옵션
                        </Dialog.Title>
                        <div className="mt-4">
                          <div className="my-7">
                            <table>
                              <tbody>
                                <tr className="border-b border-gray-200">
                                  <td className="py-3 textfield">상태</td>
                                  <td>
                                    <select
                                      className="ml-4 border-none text-sm focus:ring-0"
                                      {...register("state")}
                                    >
                                      <AccountOptions type={"state"} />
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="py-4 textfield">등록일시</td>
                                  <td>
                                    <div className="calWrapper ml-4 !mb-0">
                                      <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field }) => (
                                          <Calendar
                                            field={field}
                                            isStart={true}
                                            inputLen={"!w-[150px] !h-[40px]"}
                                          />
                                        )}
                                      />
                                      <hr className="w-[10px] bg-silver" />
                                      <Controller
                                        name="endDate"
                                        control={control}
                                        render={({ field }) => (
                                          <Calendar
                                            field={field}
                                            inputLen={"!w-[150px] !h-[40px]"}
                                          />
                                        )}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-b-box bg-pale-grey px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <ModalBtn type="submit">검색</ModalBtn>
                    <ModalBtn
                      className="mr-3"
                      type="button"
                      onClick={() =>
                        dispatch(showSearchOptionsModal({ show: false }))
                      }
                    >
                      취소
                    </ModalBtn>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
