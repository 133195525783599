import React, { useState } from "react";
import LineChart from "../../../components/chart/LineChart";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { signalResultApi } from "../../../api/SearchApi";

const QueueChange = () => {
  const [chartData, setChartData] = useState([]);
  const search = useSelector((state) => state.searchData.search);
  const { isFetching, isLoading, isSuccess } = useQuery(
    ["signalQueue", search],
    () => signalResultApi.signalQueue(search),
    {
      onSuccess: (data) => {
        // console.log("signalResultQueue data", data.data.items);
        setChartData(data.data.items);
      },
    }
  );
  return (
    <div className="sideBarContent grow">
      {isSuccess && !isLoading && !isFetching && chartData.length > 0 ? (
        <LineChart data={chartData} title={"대기 행렬 변화"} />
      ) : (
        <div className="loading h-[200px]">데이터를 불러오는 중입니다</div>
      )}
    </div>
  );
};

export default QueueChange;
