import { useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu, setParentMenu } from "../../store/features/nav/navSlice";
import { ReactComponent as IcDashboard } from "../../asset/icon/nav/ic-dashboard.svg";
import { ReactComponent as IcData } from "../../asset/icon/nav/ic-data.svg";
import { ReactComponent as IcAccount } from "../../asset/icon/nav/ic-account.svg";
import { ReactComponent as IcLog } from "../../asset/icon/nav/ic-log.svg";
import { ReactComponent as IcMonitor } from "../../asset/icon/nav/ic-monitor.svg";
import { ReactComponent as IcSearch } from "../../asset/icon/nav/ic-search.svg";
import { ReactComponent as IcSign } from "../../asset/icon/nav/ic-sign.svg";

const images = {
  dashboard: IcDashboard,
  data: IcData,
  account: IcAccount,
  log: IcLog,
  monitor: IcMonitor,
  search: IcSearch,
  sign: IcSign,
};

/**
 * 메뉴 출력 함수로 하위 메뉴가 있는지 없는지를 구분하여 출력한다
 * @param item  메뉴 데이터
 * @returns {JSX.Element}
 * @constructor
 */

function NavContents({ item }) {
  const navigate = useNavigate();
  const location = useLocation(); // 현재 페이지 주소
  const url = location.pathname; // 현재 페이지 url
  const dispatch = useDispatch();
  const parentMenus = useSelector((state) => state.nav.parentMenus);
  const isActive = item.url === url;
  /**
   * 메뉴 클릭 함수
   * @param item  해당 메뉴 객체
   * @param event 마우스 이벤트 객체
   */
  const handleMenuClick = (item, event) => {
    if (parentMenus.hasOwnProperty(item.id)) {
      // 클릭된 메뉴가 하위 메뉴가 있는 메뉴인지 아닌지 확인
      dispatch(toggleMenu(item.id)); // 하위 메뉴가 있는 메뉴이면 상태를 반전
    } else {
      resetMenu(item); // 하위 메뉴가 없는 메뉴이면 열려있는 하위 메뉴들을 닫는다
    }
    navigate(item.url); // 해당 url로 이동
  };

  /**
   * 하위 메뉴 출력 상태를 변경하는 함수
   * @param item 해당 메뉴 객체
   */
  const resetMenu = (item) => {
    const tmp = {};
    for (const key in parentMenus) {
      // 하위 메뉴가 있는 메뉴 리스트 중
      tmp[key] = key === String(item.id).slice(0, 1); // 해당 메뉴 아이디와 일치하는 키값은 true 나머지 false
    }
    dispatch(setParentMenu(tmp)); // 새로 정의된 하위 메뉴가 있는 메뉴 리스트 dispatch
  };

  const IconComponent = images[item.icon];

  return (
    <Disclosure>
      <>
        <Disclosure.Button
          className={clsx(
            "flex w-full h-[50px] p-[15px] items-center rounded-basic title2 mb-[10px]",
            {
              "bg-pale-blue": isActive,
              "hover:bg-pale-grey": !isActive,
            }
          )}
          onClick={(e) => handleMenuClick(item, e)}
          disabled={isActive}
        >
          <IconComponent className="icon2 mr-[16px]" />
          {item.upperMenu}
        </Disclosure.Button>
        <Transition
          show={item.id in parentMenus ? parentMenus[item.id] : false}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel className="w-[220px] space-y-[10px] mb-[10px]">
            {item.lowerMenu &&
              item.lowerMenu.map((i) => (
                <Disclosure.Button
                  onClick={(e) => handleMenuClick(i, e)}
                  key={i.id}
                  className={clsx(
                    "flex w-full pl-[50px] h-[50px] p-[15px] items-center rounded-basic title2",
                    {
                      "bg-pale-blue": i.url === url,
                      "hover:bg-pale-grey": i.url !== url,
                    }
                  )}
                >
                  {i.title}
                </Disclosure.Button>
              ))}
          </Disclosure.Panel>
        </Transition>
      </>
    </Disclosure>
  );
}

export default NavContents;
