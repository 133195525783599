import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { showChangePwdModal } from "../../../store/features/account/changePwdSlice";
import { showConfirmModal } from "../../../store/features/common/modalSlice";
import { useForm } from "react-hook-form";
import ModalBtn from "../../../components/button/ModalBtn";
import { useQuery } from "react-query";
import { userApi } from "../../../api/AccountApi";

export default function UpdatePasswordModal({ data }) {
  console.log("--------data--------", data);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset,
    setError,
  } = useForm();

  const dispatch = useDispatch();
  const show = useSelector((state) => state.changePwd.show); // 비밀번호 변경 모달이 표시될지 여부를 나타내는 상태값
  const cancelButtonRef = useRef(null); // 모달 취소 버튼
  const [reqData, setReqData] = useState({});
  const [password, setPassword] = useState("");
  const isCancellation = data === "탈퇴"; // 탈퇴 모달인지 아닌지 구분

  useEffect(() => {
    setReqData({});
    setPassword("");
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log("password", password);
  }, [password]);
  useEffect(() => {
    console.log("reqData.currentPwd", reqData.currentPwd);
  }, [reqData.currentPwd]);

  useQuery(["changePass", reqData], () => userApi.changePass(reqData), {
    retry: false,
    enabled: Object.entries(reqData).length > 0,
    onSuccess: (ChangePassData) => {
      console.log("changePass", ChangePassData);
      if (ChangePassData.code === 200) {
        dispatch(showChangePwdModal({ show: false }));
        dispatch(showConfirmModal({ show: true }));
        setReqData({});
        reset();
      }
    },
    onError: (error) => {
      if (error.response.status === 400) {
        console.log("비밀번호 오류");
        setError(
          "currentPwd",
          { message: "현재 비밀번호가 일치하지 않습니다" },
          { shouldFocus: true }
        );
        setReqData({});
        // reset();
      }
    },
  });

  useQuery(["userDel", password], () => userApi.userDel(password), {
    enabled: !!password,
    retry: false,
    onSuccess: (delData) => {
      if (delData.code === 200) {
        dispatch(showConfirmModal({ show: true }));
        setPassword("");
        reset();
      }
    },
    onError: (error) => {
      console.log("error.response.status", error.response.status);
      if (error.response.status === 400) {
        console.log("비밀번호 오류");
        setError(
          "password",
          { message: "비밀번호가 일치하지 않습니다" },
          { shouldFocus: true }
        );
        setPassword("");
      }
    },
  });

  /**
   * 폼 제출 시 비밀번호 변경 모달 종료 후 확인 모달 상태 변경 함수
   * @param data  폼 제출 시 입력된 데이터
   */
  const onValid = (data) => {
    console.log("비밀번호 변경/ 확인", data);
    isCancellation
      ? setPassword(data.password)
      : setReqData({
          currentPwd: data.currentPwd,
          newPwd: data.newPwd,
        });
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          dispatch(showChangePwdModal({ show: false }));
          reset();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter=" ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave=" ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form key={1} onSubmit={handleSubmit(onValid)}>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex justify-center items-center">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-center leading-6 title1"
                        >
                          {isCancellation ? "비밀번호 확인" : "비밀번호 변경"}
                        </Dialog.Title>
                        <div className="mt-4">
                          <div className="my-7">
                            <table className="relative">
                              <tbody>
                                <tr className="border-b border-gray-200">
                                  <td className="py-3 textfield">
                                    {isCancellation
                                      ? "비밀번호 확인"
                                      : "현재 비밀번호"}
                                  </td>
                                  <td>
                                    {isCancellation ? (
                                      <input
                                        className="h-8 ml-4 text-sm text-right border-none bg-transparent focus:outline-none"
                                        type="password"
                                        placeholder={"비밀번호 확인"}
                                        autoComplete="off"
                                        {...register("password", {
                                          required: "비밀번호를 입력하세요",
                                        })}
                                      />
                                    ) : (
                                      <input
                                        className="h-8 ml-4 text-sm text-right border-none bg-transparent focus:outline-none"
                                        type="password"
                                        placeholder={"현재 비밀번호"}
                                        autoComplete="off"
                                        {...register("currentPwd", {
                                          required:
                                            "현재 비밀번호를 입력하세요",
                                        })}
                                      />
                                    )}
                                  </td>
                                </tr>
                                {isCancellation ? null : (
                                  <>
                                    <tr className="border-b border-gray-200">
                                      <td className="py-3 textfield">
                                        변경 비밀번호
                                      </td>
                                      <td>
                                        <input
                                          className="h-8 ml-4 text-sm text-right border-none bg-transparent focus:outline-none"
                                          type="password"
                                          placeholder="8~12 자리"
                                          autoComplete="off"
                                          {...register("newPwd", {
                                            required:
                                              "변경할 비밀번호를 입력하세요.",
                                            // minLength: {
                                            //   value: 8,
                                            //   message:
                                            //     "8자리 이상 비밀번호를 사용해주세요.",
                                            // },
                                          })}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-b border-gray-200">
                                      <td className="py-3 textfield">
                                        변경 비밀번호 확인
                                      </td>
                                      <td>
                                        <input
                                          className="h-8 ml-4 text-sm text-right border-none bg-transparent focus:outline-none"
                                          type="password"
                                          placeholder="8~12 자리"
                                          autoComplete="off"
                                          {...register("confirmPwd", {
                                            required:
                                              "변경할 비밀번호를 입력하세요",
                                            validate: {
                                              check: (val) => {
                                                if (
                                                  getValues("newPwd") !== val
                                                ) {
                                                  return "변경 비밀번호가 일치하지 않습니다";
                                                }
                                              },
                                            },
                                          })}
                                        />
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <p className="text-right absolute textfield text-dark-pink my-3">
                              {isCancellation
                                ? errors?.password?.message
                                : errors?.currentPwd?.message ||
                                  errors?.newPwd?.message ||
                                  errors?.confirmPwd?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-pale-grey px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <ModalBtn type="submit">
                      {isCancellation ? "확인" : "변경"}
                    </ModalBtn>
                    <ModalBtn
                      className="mr-3"
                      type="button"
                      onClick={() => {
                        dispatch(showChangePwdModal({ show: false }));
                        reset();
                      }}
                    >
                      취소
                    </ModalBtn>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
