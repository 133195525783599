import ApexChart from "react-apexcharts";

const DonutChart = ({ data, label }) => {
  return (
    <ApexChart
      type="donut"
      series={data}
      height={150}
      options={{
        chart: {
          toolbar: {
            show: false,
          },
          background: "transparent",
          fontFamily: "pretendard",
        },
        labels: label,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
                fontSize: 13,
                fontFamily: "pretendard",
                fontWeight: 500,
              },
            },
          },
        ],
      }}
    />
  );
};

export default DonutChart;
