import { createSlice } from "@reduxjs/toolkit";

const initialState = { show: false, show_pass: false };
const findAccountSlice = createSlice({
  name: "findAccount",
  initialState,
  reducers: {
    showFindModal: (state, action) => {
      state.show = action.payload.show;
    },
    showPass: (state, action) => {
      state.show_pass = action.payload.show_pass;
    },
  },
});

export default findAccountSlice.reducer;
export const { showFindModal, showPass } = findAccountSlice.actions;
