import React, { useState } from "react";
import SWTable from "../table/SWTable";
import Status from "../../../components/table/Status";
import { useQuery } from "react-query";
import { dashboardApi } from "../../../api/DashboardApi";
const SoftwareMonitoringSection = () => {
  const [swApiData, setSwApiData] = useState({});
  useQuery("softwareMonitoring", () => dashboardApi.softwareMonitoring(), {
    onSuccess: (data) => {
      // console.log("softwareMonitoring", data.data);
      setSwApiData(data.data);
    },
  });

  return (
    <div className="box w-1/2">
      <div className="boxTitle flex justify-between align-center">
        S/W 모니터링
        <Status />
      </div>
      <SWTable data={swApiData} />
    </div>
  );
};

export default SoftwareMonitoringSection;
