import React from "react";
import SuperAccountDetailContent from "../../../contents/account/superAdmin/SuperAccountDetailContent";

const AdminAccountDetail = () => {
  return (
    <div className="content">
      <SuperAccountDetailContent />
    </div>
  );
};

export default AdminAccountDetail;
