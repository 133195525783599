import React, { useEffect, useState } from "react";
import BarChart from "../../../../components/chart/BarChart";
import { useQuery } from "react-query";
import { passApi } from "../../../../api/SearchApi";
import { useSelector } from "react-redux";
const DailyTrafficSection = () => {
  const [chartData, setChartData] = useState([]);
  const search = useSelector((state) => state.searchData.search);
  useEffect(() => {
    console.log("chartData", chartData);
  }, [chartData]);
  const { isFetching, isLoading, isSuccess } = useQuery(
    ["passDailyChart", search],
    () => passApi.passDailyChart(search),
    {
      onSuccess: (data) => {
        // console.log("passDailyChart data", data.data.items);
        setChartData([{ name: "일자별 통과 교통량", data: data.data.items }]);
      },
    }
  );

  return (
    <div className="sideBarContent">
      {isSuccess && !isLoading && !isFetching && chartData.length > 0 ? (
        <BarChart data={chartData} height={200} title={"일자별 통과 교통량"} />
      ) : (
        <div className="loading h-[200px]">데이터를 불러오는 중입니다</div>
      )}

      {/*<TblExcelDownload*/}
      {/*  data={dummyData[0].data}*/}
      {/*  filename={"일자별 통과 교통량"}*/}
      {/*/>*/}
    </div>
  );
};

export default DailyTrafficSection;
