import React from "react";
import NavContents from "./NavContents";
import { useDispatch } from "react-redux";
import { setParentMenu } from "../../store/features/nav/navSlice";
import { Role } from "../../constants/Constants";
const NavMenu = () => {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const roleAccountPage = {
    [Role.USER]: "/user",
    [Role.ADMIN]: "/admin",
    [Role.SUPER]: "/super",
  };
  const items = [
    { id: 1, upperMenu: "대시보드", icon: "dashboard", url: "/dashboard" },
    { id: 2, upperMenu: "모니터링", icon: "monitor", url: "/monitoring" },
    {
      id: 3,
      upperMenu: "조회",
      icon: "search",
      lowerMenu: [
        {
          id: "3-1",
          title: "통과 교통량",
          url: "/check-pass",
        },
        {
          id: "3-2",
          title: "방향별 회전 교통량",
          url: "/check-rotation",
        },
        {
          id: "3-3",
          title: "신호주기",
          url: "/check-signal",
        },
      ],
    },
    {
      id: 4,
      upperMenu: "신호 적용 결과",
      icon: "sign",
      url: "/signal-result",
    },
    {
      id: 5,
      upperMenu: "자율주행 데이터 조회",
      icon: "data",
      url: "/auto-driving-data",
    },
    {
      id: 6,
      upperMenu: "로그",
      icon: "log",
      lowerMenu: [
        {
          id: "6-1",
          title: "데이터 송수신 기록",
          url: "/data-sending-log",
        },
        {
          id: "6-2",
          title: "상세 기록",
          url: "/detail-log",
        },
      ],
    },
    {
      id: 7,
      upperMenu: "계정 관리",
      icon: "account",
      url: roleAccountPage[role],
    },
    // {
    //   id: 8,
    //   upperMenu: "데이터 확인",
    //   icon: "account",
    //   url: "/confirm-data",
    // },
  ];

  const parentMenus = {};

  items.forEach((i) => {
    // 하위 메뉴가 있는 메뉴 리스트 생성
    if ("lowerMenu" in i) parentMenus[i.id] = false; // key 는 해당 id 값은 false 로 설정
  });

  dispatch(setParentMenu(parentMenus));

  return (
    <>
      {items.map((i) => (
        <NavContents key={i.id} item={i} />
      ))}
    </>
  );
};
export default React.memo(NavMenu);
