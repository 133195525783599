import axios from "axios";
export const userApi = {
  userDetail: async () => {
    const response = await axios.get("/account/user");
    // console.log("응답", response.data);
    return response.data;
  },

  changePass: async (params) => {
    // console.log("changePass params", params);
    const { currentPwd, newPwd } = params;
    const response = await axios.put(
      "/account/user/password",
      {
        password: currentPwd,
        newPassword: newPwd,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    // console.log("응답", response.data);
    return response.data;
  },

  userDel: async (params) => {
    console.log("userDel params", params);

    // 직접 데이터를 문자열로 직렬화
    const data = `password=${encodeURIComponent(params)}`;

    const response = await axios.delete("/account/user", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });

    console.log("응답", response.data);
    return response.data;
  },
  userUpdate: async (params) => {
    // console.log("userUpdate params", params);
    const { name: userName, phone } = params;
    const response = await axios.put(
      "/account/user",
      {
        name: userName,
        phoneNum: phone,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    // console.log("응답", response.data);
    return response.data;
  },
};

export const adminApi = {
  adminUsers: async (params, pageNum) => {
    console.log("users params", params, pageNum);
    const { endDate, startDate, state: userState } = params;
    const response = await axios.get("/admin/users", {
      params: {
        start_date: startDate,
        end_date: endDate,
        state: userState,
        page: pageNum || 1,
        offset: 10,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  adminAdd: async (params) => {
    // console.log("adminAdd params", params);
    const { name: userName, id: userId, phone } = params;
    const data = new URLSearchParams({
      id: userId,
      name: userName,
      phoneNum: phone,
    });
    const response = await axios.post("/admin/user", data.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  adminDetail: async (params) => {
    // console.log("adminDetail params", params);
    const { No: userNo } = params;
    const response = await axios.get("/admin/user/detail", {
      params: {
        no: userNo,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  adminPassUpdate: async (params) => {
    // console.log("adminPassUpdate params", params);
    const { No: userNo } = params;
    const response = await axios.put(
      "/admin/user/password",
      {
        no: userNo,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    // console.log("응답", response.data);
    return response.data;
  },
  adminStateUpdate: async (params) => {
    // console.log("adminStateUpdate params", typeof params.No);
    const { No: userNo, state: userState } = params;
    const response = await axios.put(
      "/admin/user/status",
      {
        no: userNo,
        state: userState,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    // console.log("응답", response.data);
    return response.data;
  },
  adminDelUser: async (no) => {
    // console.log("adminDelUser params", no);
    const response = await axios.delete(`/admin/user/${no}`);
    // console.log("응답", response.data);
    return response.data;
  },
};

export const superApi = {
  coList: async () => {
    const response = await axios.get("/super/company/names");
    // console.log("응답", response.data);
    return response.data;
  },

  superUsers: async (params, pageNum) => {
    // console.log("superUsers params", params, pageNum);
    const {
      endDate,
      startDate,
      company: companyName,
      role: userRole,
      state: userState,
    } = params;
    const response = await axios.get("/super/users", {
      params: {
        start_date: startDate,
        end_date: endDate,
        company: companyName || "전체",
        role: userRole || "전체",
        state: userState || "전체",
        page: pageNum || 1,
        offset: 10,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },

  superUserDetail: async (params) => {
    // console.log("superUserDetail params", params);
    const { No: userNo } = params;
    const response = await axios.get("/super/user/detail", {
      params: {
        no: userNo,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
  superPassUpdate: async (params) => {
    // console.log("superPassUpdate params", params);
    const { No: userNo } = params;
    const response = await axios.put("/super/user/password", {
      no: userNo,
    });
    // console.log("응답", response.data);
    return response.data;
  },
  superUpdate: async (params) => {
    console.log("superUpdate params", params);
    const {
      no: userNo,
      company: companyName,
      role: userRole,
      state: userState,
    } = params;
    const response = await axios.put(
      "/super/user/status",
      {
        no: Number(userNo),
        company: companyName,
        role: userRole,
        state: userState,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    console.log("응답", response.data);
    return response.data;
  },
  superAdd: async (params) => {
    console.log("superAdd params", params);
    const {
      company: companyName,
      id: userId,
      name: userName,
      phone: userPhone,
      role: userRole,
    } = params;
    const data = new URLSearchParams({
      company: companyName,
      id: userId,
      name: userName,
      phoneNum: userPhone,
      role: userRole,
    });
    const response = await axios.post("/super/user", data.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    console.log("응답", response.data);
    return response.data;
  },

  superDelUser: async (no) => {
    // console.log("superDel params", no);
    const response = await axios.delete(`/super/user/${no}`);
    // console.log("응답", response.data);
    return response.data;
  },

  superCos: async (pageNum) => {
    // console.log("superCos params", pageNum);
    const response = await axios.get("/super/companies", {
      params: {
        page: pageNum || 1,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },

  updateCo: async (params) => {
    // console.log("updateCo params", params);
    const { no: No, newCo } = params;
    const response = await axios.put(
      "/super/company",
      {
        no: No,
        company: newCo,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    // console.log("응답", response.data);
    return response.data;
  },

  addCo: async (params) => {
    console.log("addCo params", params);
    const response = await axios.post(
      "/super/company",
      {
        company: params,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    console.log("응답", response.data);
    return response.data;
  },

  delCo: async (no) => {
    // console.log("superDel params", no);
    const response = await axios.delete(`/super/company/${no}`);
    // console.log("응답", response.data);
    return response.data;
  },
};

export const commonAccountApi = {
  checkDuplicateId: async (id) => {
    console.log("checkDuplicateId params", id);
    const response = await axios.get("/commons/admin/duplicate/id", {
      params: {
        id,
      },
    });
    console.log("응답", response.data);
    return response.data;
  },
};
