import Router from "./routes/Router";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";
import InitializeApp from "./api/initializeApp";

function App() {
  const queryClient = new QueryClient();
  useEffect(() => {
    InitializeApp();
  }, []);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
