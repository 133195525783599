import React, { useState } from "react";
import { useQuery } from "react-query";
import { monitoringApi } from "../../../api/MonitoringApi";
import { checkStatus } from "../../../hoc/CommonHooks";
import clsx from "clsx";

const ServerInfoSection = () => {
  const [apiData, setApiData] = useState({});
  const { isFetching, isLoading } = useQuery(
    "severInfo",
    () => monitoringApi.serverInfo(),
    {
      onSuccess: (data) => {
        // console.log("severInfo", data.data);
        setApiData(data.data);
      },
    }
  );

  return (
    <div className="flex flex-col space-y-3 mr-[30px]">
      <div className="title1 pl-3">
        {isFetching || isLoading ? "기기명" : apiData.serverName}
      </div>
      <hr className="mx-1" />
      <div className="flex justify-between px-3">
        <span className="title1">IP</span>
        <span>{isFetching || isLoading ? "IP 주소" : apiData.ip}</span>
      </div>
      <div className="flex justify-between px-3">
        <span className="title1">Status</span>
        <span className="flex">
          {isFetching || isLoading
            ? "ON/OFF"
            : apiData.isConnect
            ? "ON"
            : "OFF"}
          <span className="mx-1">/</span>
          {checkStatus(apiData.status)}
          <span
            className={clsx("ml-1 text-dark-grey", {
              " text-normal-green": apiData.status === 1,
              "text-dark-pink": apiData.status === 0,
            })}
          >
            {isFetching || isLoading
              ? "상태"
              : apiData.status
              ? "Normal"
              : "Error"}
          </span>
        </span>
      </div>
      <hr className="mx-1" />
      <div className="ml-3">{apiData.description}</div>
    </div>
  );
};

export default ServerInfoSection;
