import { format } from "date-fns";

export const sendingDataListData = (startDate) => {
  const date = format(new Date(startDate), "yyyy.MM.dd");
  return [
    {
      no: 1,
      date: `${date} 12:40`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 2,
      date: `${date} 12:45`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 3,
      date: `${date} 12:50`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 4,
      date: `${date} 12:55`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 5,
      date: `${date} 13:00`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 6,
      date: `${date} 13:05`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 7,
      date: `${date} 13:10`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 8,
      date: `${date} 13:20`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 9,
      date: `${date} 13:25`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
    {
      no: 10,
      date: `${date} 13:30`,
      totalNum: 50,
      origin: 50,
      waypoint1: 50,
      waypoint2: 50,
      destination: 50,
    },
  ];
};
