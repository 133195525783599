import React from "react";

const Legend = () => {
  return (
    <div className="sideBarContent space-y-3 mt-3">
      <div className="w-full title1 mb-[20px] ">범례</div>
      <div className="w-full grow">
        <div className="flex flex-col pl-4">
          <div className="flex w-full">
            <div className="w-1/6 textfield">원활</div>
            <div className="mr-3 bg-green-500 h-5 w-1/3"></div>
            <div className="w-5/6 textfield"> 0m 이상 ~ 10m 미만</div>
          </div>
          <div className="flex w-full">
            <div className="w-1/6 textfield">서행</div>
            <div className="mr-3  bg-yellow-300 h-5 w-1/3"></div>
            <div className="w-5/6 textfield"> 10m 이상 ~ 20m 미만</div>
          </div>
          <div className="flex w-full">
            <div className="w-1/6 textfield">지체</div>
            <div className="mr-3 bg-orange-500 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">20m 이상 ~ 30m 미만</div>
          </div>
          <div className="flex w-full">
            <div className="w-1/6 textfield">정체</div>
            <div className="mr-3 bg-red-500 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">30m ~ </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
