import React from "react";
import ApexChart from "react-apexcharts";
import { format } from "date-fns";

const BarChart = ({ data, title, height, width }) => {
  const isHourly = title === "시간별 통과 교통량";
  const isWating = title === "대기행렬";
  const formatData = {
    통행량: "MM/dd",
    대기행렬: "MM/dd",
    "일자별 통과 교통량": "MM/dd",
  };

  const formatMin = {
    통행량: 12000,
    대기행렬: 5,
  };

  return (
    <ApexChart
      type="bar"
      series={data}
      height={height}
      width={width}
      options={{
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
            export: {
              csv: {
                filename: title,
                columnDelimiter: ",",
                headerCategory: isHourly ? "일시" : "일자",
                headerValue: "",
                dateFormatter(value) {
                  return new Date(value).toDateString();
                },
                useEmptyValues: true,
              },
              svg: {
                filename: title,
              },
              png: { filename: title },
            },
          },
          background: "transparent",
          parentHeightOffset: 0,
          offsetX: -10,
        },
        theme: {
          mode: "light",
        },
        title: {
          text: title,
          style: {
            fontSize: "18px",
            fontFamily: "pretendard",
            fontWeight: 600,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
        },
        stroke: {
          width: 0,
        },
        yaxis: {
          show: true,
          min: formatMin[title],
          labels: {
            formatter: (value) => Math.round(value * 100) / 100,
          },
        },
        xaxis: {
          labels: {
            formatter: (value) =>
              isHourly ? value : format(new Date(value), formatData[title]),
            style: {
              fontSize: "13px",
              fontFamily: "pretendard",
              fontWeight: 500,
            },
          },
        },
        legend: {
          position: "bottom",
          fontSize: 13,
          fontFamily: "pretendard",
          fontWeight: 500,
        },

        tooltip: {
          x: {
            formatter: (value) =>
              isHourly ? value : format(new Date(value), formatData[title]),
          },
          y: {
            formatter: (value) =>
              value + isWating ? Math.round(value * 100) / 100 : value + "대",
          },
        },
      }}
    />
  );
};

export default BarChart;
