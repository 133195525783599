import React from "react";
import IntersectionStatusSection from "./section/IntersectionStatusSection";
import LogStatusSection from "./section/logStatus/LogStatusSection";
import TrafficVolumeSection from "./section/TrafficVolumeSection";
import HardwareMonitoringSection from "./section/HardwareMonitoringSection";
import SoftwareMonitoringSection from "./section/SoftwareMonitoringSection";

const DashboardContent = () => {
  return (
    <div className="boxWrapper flex-col">
      <div className="flex space-x-[20px] pb-[20px]">
        {/*교차로 현황*/}
        <IntersectionStatusSection />
        {/*로그 현황*/}
        <LogStatusSection />
      </div>
      {/*통행량/ 대기행렬*/}
      <TrafficVolumeSection />
      <div className="flex space-x-[20px] pt-[20px]">
        {/*하드웨어 모니터링*/}
        <HardwareMonitoringSection />

        {/*소프트웨어 모니터링*/}
        <SoftwareMonitoringSection />
      </div>
    </div>
  );
};

export default DashboardContent;
