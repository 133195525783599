import React, { useState } from "react";
import BarChart from "../../../components/chart/BarChart";
import { useQuery } from "react-query";
import { dashboardApi } from "../../../api/DashboardApi";

const TrafficVolumeSection = () => {
  const [volumeData, setVolumeData] = useState([]);
  const [queueData, setQueueData] = useState([]);
  const { isFetching, isLoading } = useQuery(
    "trafficVolume",
    () => dashboardApi.trafficVolume(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        console.log("trafficVolume queueItems", data.data.queueItems);
        setVolumeData(data.data.volumeItems);
        setQueueData(data.data.queueItems);
      },
      onError: () => {},
    }
  );
  return (
    <div className="box w-full ">
      <div className="boxTitle">
        통행량 / 대기행렬
        <span className="caption ml-[10px]">1개월/ 혼잡도 상위 3개 교차로</span>
      </div>
      <div className="boxContent">
        <div className="flex justify-evenly p-4">
          <div className="rounded-box bg-white px-4 py-1">
            {isLoading || isFetching ? (
              <div className="h-[350px] w-[600px] loading">
                데이터를 불러오는 중입니다
              </div>
            ) : (
              <BarChart
                data={volumeData}
                title={"통행량"}
                height={350}
                width={600}
              />
            )}
          </div>
          <div className="rounded-box bg-white px-4 py-2">
            {isLoading || isFetching ? (
              <div className="h-[350px] w-[600px] loading">
                데이터를 불러오는 중입니다
              </div>
            ) : (
              <BarChart
                data={queueData}
                title={"대기행렬"}
                height={350}
                width={600}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrafficVolumeSection;
