import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAutoDrivingSearch,
  resetSelectedRoute,
  setSelectedRoute,
} from "../../../store/features/autoDrivingData/autoDrivingDataSlice";
import { useQuery } from "react-query";
import { drivingApi } from "../../../api/SearchApi";
import clsx from "clsx";

const AutonomousRoute = () => {
  const [routeData, setRouteData] = useState([]);
  const dispatch = useDispatch();
  const search = useSelector((state) => state.autoDrivingData.search);
  const clickRoute = useSelector((state) => state.autoDrivingData.selectedPath);
  console.log("clickRoute", clickRoute);
  useEffect(() => {
    dispatch(resetAutoDrivingSearch());
    dispatch(resetSelectedRoute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log("AutonomousRoute search", search);
  }, [search]);

  const handleClickRoute = (route) => {
    console.log("handleClickRoute route", route);
    dispatch(setSelectedRoute(route));
  };
  useEffect(() => {
    console.log("routeData routeData", routeData);
  }, [routeData]);

  const { isFetching, isLoading, isSuccess } = useQuery(
    ["routeData", search],
    () => drivingApi.drivingArrow(search),
    {
      // enabled: routeData.length === 0,
      onSuccess: (data) => {
        dispatch(resetSelectedRoute());
        console.log("AutonomousRoute onSuccess 넘어온 데이터 값", data.data);

        // console.log(
        //   "AutonomousRoute onSuccess 가공 ~~~~~~~~~~~~~~~~~~~~~",
        //
        //   data.data.items.map((item) => {
        //     const formattedPath = item.path.flatMap((i) => [
        //       i.origin,
        //       i.destination,
        //     ]);
        //     const uniquePath = [];
        //
        //     for (let i = 0; i < formattedPath.length - 1; i++) {
        //       if (formattedPath[i] !== formattedPath[i + 1]) {
        //         uniquePath.push(formattedPath[i]);
        //       }
        //     }
        //     uniquePath.push(formattedPath[formattedPath.length - 1]);
        //
        //     return {
        //       distance: item.distance,
        //       minute: item.minute,
        //       num: item.num,
        //       percentage: item.percentage,
        //       path: uniquePath,
        //     };
        //   })
        // );

        setRouteData(
          data.data.items.map((item) => {
            const formattedPath = item.path.flatMap((i) => [
              i.origin,
              i.destination,
            ]);
            const uniquePath = [];

            for (let i = 0; i < formattedPath.length - 1; i++) {
              if (formattedPath[i] !== formattedPath[i + 1]) {
                uniquePath.push(formattedPath[i]);
              }
            }
            uniquePath.push(formattedPath[formattedPath.length - 1]);

            return {
              distance: item.distance,
              minute: item.minute,
              num: item.num,
              percentage: item.percentage,
              path: uniquePath,
            };
          })
        );
      },
    }
  );

  return (
    <>
      <div className="w-full title1 mb-[20px]">자율 주행 경로</div>
      <div className="h-520px overflow-y-auto space-y-1">
        {isSuccess && !isLoading && !isFetching ? (
          routeData.length > 0 ? (
            routeData.map((item, index) => (
              <button
                key={index}
                onClick={() => handleClickRoute(index + 1)}
                className={clsx("w-full border px-3 py-2 transition-colors", {
                  "bg-orange-200": clickRoute === index + 1,
                  "hover:bg-orange-100 active:bg-orange-200 cursor-pointer":
                    clickRoute !== index + 1 || clickRoute.length < 0,
                })}
              >
                <div className="font-semibold text-left">경로 {index + 1}</div>
                <hr />
                <div className="flex justify-between py-2">
                  <div>
                    <span className="text-xl font-semibold">{item.minute}</span>
                    /{item.distance}km
                  </div>
                  <div>
                    {item.num} 대 / {item.percentage}%
                  </div>
                </div>
                <hr />
                <div className="py-4">
                  {item.path.map((origin, index) => (
                    <span key={origin}>
                      {origin}
                      {index !== item.path.length - 1 && " → "}
                    </span>
                  ))}
                </div>
              </button>
            ))
          ) : (
            <div className="loading h-full">
              해당 데이터가 존재하지 않습니다
            </div>
          )
        ) : (
          <div className="loading h-full">데이터를 불러오는 중입니다</div>
        )}
      </div>
    </>
  );
};

export default AutonomousRoute;
