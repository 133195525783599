import React from "react";

const SendingDataLogDetailDescription = (data) => {
  return (
    <table className="w-full text-center my-3">
      <tbody>
        {data.map((row, index) => (
          <tr className="border border-silver" key={index}>
            <td className="border border-silver font-semibold w-1/4">
              {row.key}
            </td>
            <td>{row.description}</td>
            <td className="border border-silver font-semibold w-1/4">
              {row.key2}
            </td>
            <td>{row.description2}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SendingDataLogDetailDescription;
