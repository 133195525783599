import { createSlice } from "@reduxjs/toolkit";
import { DefaultValue, SelectOptions } from "../../../constants/Constants";
import { format } from "date-fns";
const today = new Date();

const initialState = {
  search: {
    [SelectOptions.DONG]: DefaultValue.DONG,
    [SelectOptions.CROSSWAY]: DefaultValue.CROSSWAY,
    [SelectOptions.DIRECTION]: DefaultValue.DIRECTION,
    [SelectOptions.ROTATION]: DefaultValue.ROTATION,
    startDate: format(new Date(today), "yyyy-MM-dd"),
    endDate: format(new Date(today), "yyyy-MM-dd"),
  },
};

const searchSlice = createSlice({
  name: "checkRotation",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload.search;
    },
    setDong: (state, action) => {
      state.search.dong = action.payload;
    },
    setCrossway: (state, action) => {
      state.search.crossway = action.payload;
    },
    setDirection: (state, action) => {
      state.search.direction = action.payload;
    },
    setRotation: (state, action) => {
      state.search.rotation = action.payload;
    },
    setCrosswayNDirection: (state, action) => {
      state.search = {
        ...state.search,
        direction: action.payload.direction,
        crossway: action.payload.crossway,
      };
    },
    setDirectionNRotation: (state, action) => {
      state.search = {
        ...state.search,
        direction: initialState.search.direction,
        rotation: initialState.search.rotation,
      };
    },
    resetSearch: (state, action) => {
      state.search = initialState.search;
    },
  },
});

export default searchSlice.reducer;
export const {
  setSearch,
  setDong,
  setCrossway,
  setDirection,
  setRotation,
  setCrosswayNDirection,
  setDirectionNRotation,
  resetSearch,
} = searchSlice.actions;
