import React, { useState } from "react";
import RadialChart from "../../../components/chart/RadialChart";
import SingleHorizontalBarChart from "../../../components/chart/SingleHorizontalBarChart";
import { useQuery } from "react-query";
import { monitoringApi } from "../../../api/MonitoringApi";

const ChartSection = () => {
  const [apiData, setApiData] = useState({});
  const { isFetching, isLoading } = useQuery(
    "monitoringChart",
    () => monitoringApi.chart(),
    {
      onSuccess: (data) => {
        console.log("monitoringChart", data.data);
        setApiData(data.data);
      },
    }
  );

  return (
    <>
      <div className="flex items-center px-[35px] border-l">
        <div className="w-1/2">
          {isLoading || isFetching ? (
            <div className="h-[130px] flex justify-center items-center textfield">
              데이터를 불러오는 중입니다
            </div>
          ) : (
            <RadialChart data={apiData?.temperature?.cpu} />
          )}

          <div className="title1 text-center">CPU</div>
        </div>
        <div className="w-1/2">
          {isLoading || isFetching ? (
            <div className="h-[130px] flex justify-center items-center textfield">
              데이터를 불러오는 중입니다
            </div>
          ) : (
            <RadialChart data={apiData?.temperature?.gpu} />
          )}

          <div className="title1 text-center">GPU</div>
        </div>
      </div>
      <div className="px-[35px] border-l">
        <div className="h-1/2">
          <div className="title1">STORAGE</div>
          {isLoading || isFetching ? (
            <div className="loading h-[20px]">데이터를 불러오는 중입니다</div>
          ) : (
            <SingleHorizontalBarChart
              data={apiData?.usage?.storage}
              title={"Storage"}
            />
          )}
        </div>
        <div className="h-1/2">
          <div className="title1">RAM</div>
          {isLoading || isFetching ? (
            <div className="loading h-[20px]">데이터를 불러오는 중입니다</div>
          ) : (
            <SingleHorizontalBarChart
              data={apiData?.usage?.ram}
              title={"Ram"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChartSection;
