import { createSlice } from "@reduxjs/toolkit";
import { DefaultValue, SelectOptions } from "../../../constants/Constants";

const initialState = {
  search: {
    [SelectOptions.DONG]: DefaultValue.DONG,
    [SelectOptions.CROSSWAY]: DefaultValue.CROSSWAY,
  },
};

const signalResultSlice = createSlice({
  name: "signalResultSlice",
  initialState,
  reducers: {
    setSignalResultSearch: (state, action) => {
      state.search = action.payload.search;
    },
    resetSignalResultSearch: (state, action) => {
      state.search = initialState.search;
    },
    setSignalResultDong: (state, action) => {
      state.search.dong = action.payload;
    },
    setSignalResultCrossway: (state, action) => {
      state.search.crossway = action.payload;
    },
  },
});

export default signalResultSlice.reducer;
export const { setSignalResultSearch, resetSignalResultSearch } =
  signalResultSlice.actions;
