import DailyTrafficSection from "./section/passTrafficSidebar/DailyTrafficSection";
import HourlyTrafficSection from "./section/passTrafficSidebar/HourlyTrafficSection";
import Legend from "./section/passTrafficSidebar/Legend";

import React from "react";
import SearchForm from "./section/SearchForm";
const TrafficSidebar = () => {
  return (
    <div className="sideBarWrapper ">
      <div className=" space-y-[30px] sideBarContainer">
        {/*검색 창*/}
        <SearchForm />
        {/*일자별 통과 교통량*/}
        <DailyTrafficSection />
        {/*시간별 통과 교통량*/}
        <HourlyTrafficSection />
        <Legend />
      </div>
    </div>
  );
};

export default TrafficSidebar;
