import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initializeApp from "../../../api/initializeApp";
export const userLogin = createAsyncThunk(
  "account/login",
  async ({ id, password }, { rejectWithValue }) => {
    // console.log("넘어온 데이터", id, password);
    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const { data } = await axios.post(
        `/account/login`,
        { username: id, password },
        config
      );
      console.log("loginData", data);
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("role", data.role);
      localStorage.setItem("name", data.name);

      initializeApp();
      return data;
    } catch (error) {
      // console.log("authActions error", error);
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
