import React from "react";

const Legend = () => {
  return (
    <div className="sideBarContent space-y-1">
      <div className="w-full title1 mb-[30px]">범례</div>
      <div className="w-full grow">
        <div className="flex flex-col">
          <div className="flex w-full">
            <div className="mr-3 bg-sky-100 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">
              100대/일 이상 ~ 5,000대/일 미만
            </div>
          </div>
          <div className="flex w-full">
            <div className="mr-3  bg-sky-200 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">
              5,000대/일 이상 ~ 10,000대/일 미만
            </div>
          </div>
          <div className="flex w-full">
            <div className="mr-3 bg-sky-300 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">
              10,000대/일 이상 ~ 20,000대/일 미만
            </div>
          </div>
          <div className="flex w-full">
            <div className="mr-3 bg-sky-400 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">
              20,000대/일 이상 ~ 50,000대/일 미만
            </div>
          </div>
          <div className="flex w-full">
            <div className="mr-3 bg-sky-500 h-5 w-1/3"></div>
            <div className="w-5/6 textfield">
              50,000대/일 이상 ~ 100,000대/일 미만
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
