import React, { useEffect } from "react";
import { ReactComponent as IcNormal } from "../asset/icon/ic_normal.svg";
import { ReactComponent as IcError } from "../asset/icon/ic_error.svg";
/**
 * 폼 제출 시 엔터 이벤트 방지
 * @returns {null}
 * @constructor
 */
export const PreventEnterDefault = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return null;
};
/**
 * 모니터링 Normal/ Error에 따른 동그라미 출력
 * @param data  1 또는 0
 * @returns {*|JSX.Element} 1 은 초록 동그라미, 0은 빨간 동그라미
 */
export const checkStatus = (data) => {
  if (data === 1) {
    return <IcNormal className="mr-auto ml-auto" />;
  } else if (data === 0) {
    return <IcError className="mr-auto ml-auto" />;
  } else {
    return data;
  }
};
