import React, { useState } from "react";
import DetailLogRecord from "./section/DetailLogRecord";
import { Controller, useForm } from "react-hook-form";
import Calendar from "../../utils/calendar/Calendar";
import SearchBtn from "../../components/button/SearchBtn";

import { format } from "date-fns";
import { useQuery } from "react-query";

import { LogApi as logApi } from "../../api/LogApi";

const DetailLogContent = () => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setFocus,
    setError,
  } = useForm();

  const [search, setSearch] = useState({});

  const today = new Date();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [resData, setResData] = useState({});

  const { isFetching, isLoading } = useQuery(
    ["detailLog", search],
    () => logApi.detailLog(search),
    {
      onSuccess: (data) => {
        console.log("detailLog data", data.data);
        setResData(data.data);
      },
    }
  );

  const onValid = (data) => {
    console.log("DetailLogContent param", data);
    if (
      Number(data.start_time.slice(0, 2)) > Number(data.end_time.slice(0, 2))
    ) {
      setError(
        "end_time",
        { message: "종료시간을 다시 확인해주시기 바랍니다" },
        { shouldFocus: true }
      );
      return;
    }
    data.date = format(new Date(data?.date || today), "yyyy-MM-dd");
    console.log(
      "DetailLogContent param",
      data,
      Number(data.end_time.slice(0, 2))
    );
    setSearch(data);
  };

  const handleTimeChange = (e, type) => {
    if (e.target.value.length > 0) clearErrors();
    // console.log(type);
    const isStart = type === "start";
    // console.log("handleStartTimeChange", e.target.value);
    let input = e.target.value;
    // 숫자와 : 만 입력되도록 설정
    if (!/^[\d:]*$/.test(input)) {
      e.preventDefault();
      return;
    }
    console.log("input", input.length);

    if (input === "") {
      isStart ? setStartTime("") : setEndTime("");
    } else if (input.length <= 2) {
      isStart ? setStartTime(input) : setEndTime(input);
    } else if (input.length === 3) {
      if (input[2] === ":") {
        isStart
          ? setStartTime(`${input.slice(0, 2)}:${input.slice(3)}`)
          : setEndTime(`${input.slice(0, 2)}:${input.slice(3)}`);
      } else {
        isStart
          ? setStartTime(`${input.slice(0, 2)}:${input[2]}`)
          : setEndTime(`${input.slice(0, 2)}:${input[2]}`);
      }
    } else if (input.length === 4) {
      if (isStart) {
        setStartTime(`${input.slice(0, 2)}:${input.slice(3, 5)}`);
      } else {
        setEndTime(`${input.slice(0, 2)}:${input.slice(3, 5)}`);
      }
    } else {
      if (isStart) {
        setStartTime(`${input.slice(0, 2)}:${input.slice(3, 5)}`);
        setFocus("end_time");
      } else {
        setEndTime(`${input.slice(0, 2)}:${input.slice(3, 5)}`);
      }
    }
  };
  return (
    <div className="bigBox">
      <form
        className="flex justify-between pb-[20px]"
        onSubmit={handleSubmit(onValid)}
      >
        <div className="calWrapper">
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <Calendar field={field} inputLen={"!w-[150px]"} isStart={true} />
            )}
          />
          <input
            className="pageInput ml-[10px] w-[150px] text-center"
            type="text"
            placeholder="시작시간 00:00"
            {...register("start_time", {
              pattern: {
                value: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
                message: "유효하지 않은 시간 형식입니다",
              },
            })}
            value={startTime}
            onChange={(e) => handleTimeChange(e, "start")}
          />
          <input
            className="pageInput ml-[10px] w-[150px] text-center"
            type="text"
            placeholder="종료시간 23:59"
            {...register("end_time", {
              pattern: {
                value: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
                message: "유효하지 않은 시간 형식입니다",
              },
            })}
            value={endTime}
            onChange={(e) => handleTimeChange(e, "end")}
          />
          <p className=" textfield text-dark-pink mx-3">
            {errors?.start_time?.message || errors?.end_time?.message}
          </p>
        </div>
        <div className="flex justify-center">
          <select
            className="sideBarSelectBox mr-[10px] w-[120px]"
            {...register("type")}
          >
            <option value="전체">전체</option>
            <option value="INFO">INFO</option>
            <option value="WARNING">WARNING</option>
            <option value="ERROR">ERROR</option>
          </select>
          <input
            className="pageInput mr-[10px] w-[200px]"
            type="text"
            placeholder="검색어를 입력하세요"
            {...register("keyword")}
          />

          <SearchBtn className="!w-[120px]">검색</SearchBtn>
        </div>
      </form>
      <div className="boxContent p-[30px]">
        {/*상세 로그 기록*/}
        {isLoading || isFetching ? (
          <div className="loading h-full">데이터를 불러오는 중입니다</div>
        ) : Object.entries(resData).length > 0 ? (
          <DetailLogRecord resData={resData} />
        ) : (
          <div className="loading h-full">해당하는 데이터가 없습니다</div>
        )}
      </div>
    </div>
  );
};

export default DetailLogContent;
