import React from "react";
import { checkStatus } from "../../hoc/CommonHooks";

const Table = ({ data, title }) => {
  // console.log("Table data", data);
  return (
    <>
      {data.length > 0 ? (
        <table className="w-full">
          <thead>
            <tr>
              {Object.keys(data[0]).map((key) => (
                <th key={key} className="border h-[50px] bg-pale-grey">
                  {key.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {Object.values(item).map((val, index) => (
                  <td key={index} className="border h-[50px] text-center">
                    {checkStatus(val)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="border h-[50px] text-center">
          <p className="loading pt-3">해당하는 데이터가 없습니다</p>
        </div>
      )}
    </>
  );
};

export default Table;
