import React, { useState, useRef, useEffect } from "react";
import {
  add,
  compareDesc,
  differenceInDays,
  endOfMonth,
  format,
  setDate,
  startOfMonth,
  sub,
} from "date-fns";
import Cell from "./Cell";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  resetMinDate,
  setEndDate,
  setMinDate,
  setStartDate,
} from "../../store/features/calendar/calendarSlice";
import { ReactComponent as IcCalendar } from "../../asset/icon/ic_calendar.svg";

/**
 *
 * @param field react-hook-form 데이터 전송 변수
 * @param inputLen input 길이 설정
 * @param isStart 시작 날짜 설정 달력인지 아닌지 구분
 * @returns {JSX.Element} input + 달력
 * @constructor
 */
const Calendar = ({ field, inputLen, isStart = false }) => {
  const [open, setOpen] = useState(false); // 달력 출력 여부를 관리
  const calendarRef = useRef(null); // 달력 컴포넌트의 DOM 요소를 참조하는 변수
  const inputRef = useRef(null); // input 컴포넌트의 DOM 요소를 참조하는 변수
  const dispatch = useDispatch();
  const resDate = useSelector((state) => state.calendar.minDate); // store 에서 선택 최소 일자를 가져온다
  const minDate = resDate ? new Date(resDate) : null; // resDate가 존재하면 Date로 변환, 존재하지 않으면 null로 설정
  const selectedEndDateString = useSelector((state) => state.calendar.endDate);

  const selectedStartDateString = useSelector(
    (state) => state.calendar.startDate
  ); // store 에서 시작 날짜를 가져온다
  const selectedStartDate = selectedStartDateString
    ? new Date(selectedStartDateString)
    : null; // selectedStartDate 존재하면 Date로 변환, 존재하지 않으면 null로 설정

  const [value, setValue] = useState(new Date()); //  날짜 값을 관리하며 초기값은 selectedStartDate 또는 오늘 날짜로 설정
  /**
   * value 상태 값을 업데이트하는 함수
   * @param date Date 객체
   *
   */
  const onChange = (date) => setValue(date);
  useEffect(() => {
    // resDate 가 변경 될 때마다 value값을 변경 (종료 날짜)
    if (isStart) {
      field.onChange(String(new Date()));
    } else if (
      compareDesc(new Date(resDate), new Date(selectedEndDateString)) < 0
    ) {
      setValue(new Date(minDate));
      field.onChange(String(resDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resDate]);

  useEffect(() => {
    if (isStart) {
      // 시작 날짜면
      dispatch(setStartDate({ startDate: String(value) })); // 시작 날짜면 startDate redux에 저장
      dispatch(setMinDate({ minDate: String(value) })); // 최소 선택 날짜 설정
    } else if (!selectedStartDate) {
      // 시작 날짜가 아니고 선택된 시작 날짜가 없으면
      dispatch(setEndDate({ endDate: String(value) })); // 종료 날짜 설정
    }
    field.onChange(String(value)); // 날짜 값 변경 후 use-form 에 전달
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // 요일 배열
  const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // 해당 달의 시작하는 날
  const startDate = startOfMonth(value);
  // 해당 달의 끝나는 날
  const endDate = endOfMonth(value);
  // 해당 달의 총 날 수
  const totalDays = differenceInDays(endDate, startDate) + 1;

  // 해당 달 시작 전 빈 칸 수
  const prefixDays = startDate.getDay();
  // 해당 달 종료 후 빈 칸 수
  const suffixDays = 6 - endDate.getDay();

  const prevMonth = () => {
    if (
      // 시작 날짜거나, 선택된 시작 날짜가 없거나, 선택된 시작 날짜가 있고 최소 날짜 달 보다 클 경우
      isStart ||
      !selectedStartDate ||
      (selectedStartDate &&
        minDate &&
        (value.getFullYear() > minDate.getFullYear() ||
          (value.getFullYear() === minDate.getFullYear() &&
            value.getMonth() > minDate.getMonth())))
    ) {
      onChange(sub(value, { months: 1 }));
      if (minDate && value.getDate() < minDate.getDate()) {
        setValue(minDate);
      }
    }
  };
  const nextMonth = () => onChange(add(value, { months: 1 }));
  const prevYear = () => {
    if (
      // 시작 날짜거나, 선택된 시작 날짜가 없거나, 선택된 시작 날짜가 있고 최소 날짜 년 보다 클 경우
      isStart ||
      !selectedStartDate ||
      (!!selectedStartDate &&
        compareDesc(minDate, sub(value, { years: 1 })) >= 0)
    ) {
      onChange(sub(value, { years: 1 }));
    }
  };
  const nextYear = () => onChange(add(value, { years: 1 }));

  useEffect(() => {
    dispatch(resetMinDate()); // 페이지가 렌더링될 때마다 minDate 리셋
    setValue(new Date()); // value 상태를 오늘 날짜로 변경

    // 모달에 있는 요소인지 아닌지 확인 후 모달 요소면 document 가 아닌 상위 요소에 이벤트 등록
    const parent = document.querySelector(`[id^='headlessui-dialog-panel-']`);
    if (parent) {
      // 모달에 있으면
      // 달력 외 영역을 선택했을 때 달력이 닫히게 하기 위해 상위 요소에 클릭 이벤트 등록
      parent.addEventListener("click", handleClickOutside);
      return () => {
        // cleanup 함수로 이전에 등록한 이벤트 제거
        parent.removeEventListener("click", handleClickOutside);
      };
    }
    // 달력 외 영역을 선택했을 때 달력이 닫히게 하기 위해 화면 전체에 클릭 이벤트 등록
    document.addEventListener("click", handleClickOutside);
    return () => {
      // cleanup 함수로 이전에 등록한 이벤트 제거
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 달력 외의 영역을 클릭했을 때 달력을 닫는 함수
   * @param e 마우스 이벤트 객체
   */
  const handleClickOutside = (e) => {
    if (
      // 달력 컴포넌트가 존재하고 달력 영역에 클릭 영역이 포함되어있지 않고 클릭 영역도 달력 영역이 아니면
      calendarRef.current &&
      !calendarRef.current.contains(e.target) &&
      e.target !== calendarRef.current
    ) {
      setOpen(false); // 달력 숨기기
    } else if (!calendarRef.current && inputRef.current.contains(e.target)) {
      // 달력 컴포넌트가 없고 클릭 영역이 input 영역이면
      setOpen(true); // 달력 보이기
    }
  };

  /**
   * 날짜 선택 함수
   * @param index 선택한 일자
   */
  const handleClickDate = (index) => {
    const date = setDate(value, index); // 선택한 일자를 해당 달의 Date 형태로 변경하고 value 값으로 설정
    onChange(date); // 날짜 상태값 업데이트

    if (isStart) {
      // 시작 날짜면
      dispatch(setStartDate({ startDate: String(date) })); // 시작 날짜면 startDate redux에 저장
      dispatch(setMinDate({ minDate: String(date) })); // 최소 선택 날짜 설정
    } else {
      dispatch(setEndDate({ endDate: String(date) })); // 종료 날짜면 endDate redux에 저장
    }

    field.onChange(String(date)); // 날짜 값 변경 후 use-form 에 전달
    setOpen(false); // 날짜 선택 후 달력 닫기
  };

  return (
    <div className={` w-[200px] h-[50px] ${inputLen}`}>
      <div className="absolute">
        <div
          ref={inputRef}
          className={clsx(
            inputLen,
            "flex justify-between items-center h-[50px] border border-silver rounded-basic pl-[20px] pr-[20px] w-[200px]"
          )}
        >
          <input
            className={clsx(
              "bg-transparent textfield h-full w-11/12 cursor-pointer focus:outline-0 "
            )}
            value={format(value, "yyyy/MM/dd")}
            readOnly
          />
          <IcCalendar className="icon" />
        </div>
        {open && (
          <div
            ref={calendarRef}
            className="z-50 bg-white relative -left-6  w-[250px] border border-1 rounded-lg shadow-lg"
          >
            <div className="grid grid-cols-7 items-center justify-center text-center p-1">
              <Cell onClick={prevYear}>{"<<"}</Cell>
              <Cell onClick={prevMonth}>{"<"}</Cell>
              <Cell className=" col-span-3 font-bold">
                {format(value, "LLL yyyy")}
              </Cell>
              <Cell onClick={nextMonth}>{">"}</Cell>
              <Cell onClick={nextYear}>{">>"}</Cell>
              {weeks.map((week) => (
                <Cell className="text-xs font-bold uppercase" key={week}>
                  {week}
                </Cell>
              ))}
              {Array.from({ length: prefixDays }).map((_, index) => (
                <Cell key={index} />
              ))}
              {Array.from({ length: totalDays }).map((_, index) => {
                const date = index + 1;
                const formattedDate = setDate(value, date);
                const isCurrentDate = date === value.getDate(); // 클릭한 날과 value 값과 일치여부 확인
                const isDisabled = compareDesc(formattedDate, minDate) > 0; // minDate 이전의 날짜인 경우 disabled 상태로 설정 뒤 날짜가 크면 1 일치하면 0 작으면 -1
                return (
                  <Cell
                    key={date}
                    isActive={isCurrentDate}
                    onClick={() => handleClickDate(date)}
                    disabled={isStart ? undefined : isDisabled}
                  >
                    {date}
                  </Cell>
                );
              })}
              {Array.from({ length: suffixDays }).map((_, index) => (
                <Cell key={index} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
