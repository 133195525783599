import React from "react";
import logo from "../../asset/icon/img_logo.png";
import { ReactComponent as IcLogout } from "../../asset/icon/nav/ic-logout.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../store/features/auth/authSlice";
const NavHeader = () => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem("name");
  return (
    <>
      <img src={logo} alt="logo" className="w-[165px] h-[40px]" />
      <div className="flex justify-between items-center w-full h-[50px] grow-0 mt-[30px] mb-[70px] py-[15px] pr-[10px] pl-[20px] rounded-basic border border-silver">
        <p className="title1 text-purpleish-blue">
          {userName} <span className="font-medium text-dark-grey">님</span>
        </p>
        <IcLogout
          className="icon2 cursor-pointer"
          onClick={() => dispatch(logout())}
        />
      </div>
    </>
  );
};

export default NavHeader;
