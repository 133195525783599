import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Nav from "../components/nav/Nav";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
  const { access_token } = useSelector((state) => state.auth);
  // console.log("ProtectedRoute access_token", access_token);
  if (!access_token) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="wrapper">
      <div className="container">
        <Nav />
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedRoute;
