import React from "react";
import clsx from "clsx";

const SearchBtn = ({ onClick, children, className, type }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={clsx(
        "flex justify-center items-center h-[50px] title2 bg-slate-grey text-white w-full rounded-basic hover:bg-slate-600 active:bg-slate-700 transition-colors",
        className
      )}
    >
      {children}
    </button>
  );
};

export default SearchBtn;
