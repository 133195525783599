import React from "react";
import DashboardContent from "../contents/dashboard/DashboardContent";

const Dashboard = () => {
  return (
    <div className="content">
      <DashboardContent />
    </div>
  );
};

export default Dashboard;
