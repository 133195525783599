import React from "react";
import UserAccountContent from "../../../contents/account/user/UserAccountContent";

const UserAccount = () => {
  return (
    <div className="content">
      <UserAccountContent />
    </div>
  );
};

export default UserAccount;
