export const signalDisplayPlanOffsetContent = [
  { receiveTime: "00:01", crosswayId: 1, offest: 20 },
  { receiveTime: "00:02", crosswayId: 2, offest: 30 },
  { receiveTime: "00:03", crosswayId: 3, offest: 30 },
  { receiveTime: "00:04", crosswayId: 4, offest: 10 },
  { receiveTime: "00:05", crosswayId: 5, offest: 20 },
  { receiveTime: "00:06", crosswayId: 6, offest: 20 },
  { receiveTime: "00:07", crosswayId: 7, offest: 20 },
  { receiveTime: "00:08", crosswayId: 8, offest: 25 },
  { receiveTime: "00:09", crosswayId: 9, offest: 15 },
  { receiveTime: "00:10", crosswayId: 10, offest: 10 },
  { receiveTime: "00:11", crosswayId: 11, offest: 20 },
  { receiveTime: "00:12", crosswayId: 12, offest: 25 },
  { receiveTime: "00:13", crosswayId: 13, offest: 15 },
  { receiveTime: "00:14", crosswayId: 14, offest: 15 },
  { receiveTime: "00:15", crosswayId: 15, offest: 10 },
  { receiveTime: "00:16", crosswayId: 16, offest: 25 },
  { receiveTime: "00:17", crosswayId: 17, offest: 15 },
  { receiveTime: "00:18", crosswayId: 18, offest: 20 },
  { receiveTime: "00:19", crosswayId: 19, offest: 30 },
  { receiveTime: "00:20", crosswayId: 20, offest: 15 },
  { receiveTime: "00:21", crosswayId: 21, offest: 15 },
  { receiveTime: "00:22", crosswayId: 22, offest: 10 },
  { receiveTime: "00:23", crosswayId: 23, offest: 10 },
  { receiveTime: "00:24", crosswayId: 24, offest: 25 },
  { receiveTime: "00:25", crosswayId: 25, offest: 25 },
  { receiveTime: "00:26", crosswayId: 26, offest: 15 },
  { receiveTime: "00:27", crosswayId: 27, offest: 15 },
  { receiveTime: "00:28", crosswayId: 28, offest: 25 },
  { receiveTime: "00:29", crosswayId: 29, offest: 30 },
  { receiveTime: "00:30", crosswayId: 30, offest: 20 },
];
