import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parentMenus: {},
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setParentMenu: (state, action) => {
      state.parentMenus = action.payload;
    },
    toggleMenu: (state, action) => {
      state.parentMenus[action.payload] = !state.parentMenus[action.payload];
    },
  },
});

export default navSlice.reducer;
export const { setParentMenu, toggleMenu, reverseMenu } = navSlice.actions;
