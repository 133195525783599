import React, { useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { resetExcelBtn } from "../store/features/common/excelSlice";

/**
 * 엑셀 출력 여부와 api 전송 상태를 확인하여 다운로드를 진행하는 함수
 * @param data  api에서 전달받은 데이터
 * @param filename  파일이름
 * @param date 출력기간
 * @param onClick 엑셀 출력 여부 clickExcelBtn 상태 확인  dispatch(clickExcelBtn({ clicked: true }))
 * @param status  요청한 api 상태 확인 isExcelSuccess, isExcelFetching, isExcelLoading
 * @returns {JSX.Element} 엑셀 다운로드 버튼
 * @constructor
 */
const TblExcelDownload = ({
  data,
  filename,
  date,
  onClick,
  status,
  excelStatus,
}) => {
  // console.log(
  //   "TblExcelDownload data",
  //   data,
  //   excelStatus,
  //   filename,
  //   onClick,
  //   status
  // );
  const dispatch = useDispatch();
  const clickedExcelBtn = useSelector((state) => state.excel.clicked);
  useEffect(() => {
    if (
      clickedExcelBtn &&
      status.isExcelSuccess &&
      !status.isExcelFetching &&
      !status.isExcelLoading
    ) {
      // 엑셀 출력 여부가 true인 상태에서 api 전송 상태가 성공일 경우
      download(data, () => dispatch(resetExcelBtn())); // 다운로드 시작 및 콜백함수 전달
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.isExcelSuccess, data, clickedExcelBtn]);
  /**
   * 전달 받은 data를 엑셀 형식으로 출력
   * @param data api에서 전달받은 데이터
   * @param callback 다운로드 종료 시, 수행해야 할 함수 (엑셀 출력 상태를 false로 수정)
   */
  const download = (data, callback) => {
    console.log("download data", data);
    if (data.length === 0) return;
    const keyValue = Object.keys(data[0]);
    const th = keyValue.map((i) => i.toUpperCase());

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = date + filename;
    const ws = XLSX.utils.aoa_to_sheet([[date, filename], th]);
    data.map((data) => {
      const rowData = Object.keys(data).map((key) => data[key]);
      XLSX.utils.sheet_add_aoa(ws, [rowData], {
        origin: -1,
      });
      ws[`!cols`] = [{ wpx: 200 }, { wpx: 200 }];
      return false;
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelButter], { type: fileType });
    FileSaver.saveAs(excelFile, fileName + fileExtension);

    if (typeof callback === "function") {
      callback(); // 콜백 함수 실행 엑셀 출력 여부 false로 변경
    }
  };

  const handleButtonClick = () => onClick(); // 엑셀 출력 여부 true 로 변경

  return (
    <button
      onClick={handleButtonClick}
      className="w-[120px] flex justify-center items-center px-3 py-1 bg-slate-grey text-white rounded-basic textfield hover:bg-slate-600 active:bg-slate-700 transition-colors"
    >
      {status.isExcelLoading || status.isExcelLoading // api 요청 상태에 따라 버튼 텍스트 변경
        ? "로딩중.."
        : "엑셀 다운로드"}
    </button>
  );
};

export default TblExcelDownload;
