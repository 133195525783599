import React from "react";
import AdminAccountDetailContent from "../../../contents/account/admin/AdminAccountDetailContent";

const AdminAccountDetail = () => {
  return (
    <div className="content">
      <AdminAccountDetailContent />
    </div>
  );
};

export default AdminAccountDetail;
