import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./authActions";

const access_token = localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : null;

const refresh_token = localStorage.getItem("refresh_token")
  ? localStorage.getItem("refresh_Token")
  : null;

const initialState = {
  loading: false,
  userInfo: {},
  access_token: access_token,
  refresh_token: refresh_token,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("role");
      state.loading = false;
      state.access_token = null;
      state.refresh_token = null;
      state.error = null;
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { logout } = authSlice.actions;
export default authSlice.reducer;
