import React from "react";
import TrafficSidebar from "../../contents/check/CheckPassTrafficSidebar";
import CheckPassMap from "../../contents/check/section/map/CheckPassMap";

const CheckPass = () => {
  return (
    <>
      <TrafficSidebar />
      <CheckPassMap />
    </>
  );
};

export default CheckPass;
