import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { drivingApi } from "../../../../api/SearchApi";
import {
  resetAutoDrivingEndCrossway,
  resetAutoDrivingStartCrossway,
  setAutoDrivingEndCrossway,
  setAutoDrivingStartCrossway,
} from "../../../../store/features/autoDrivingData/autoDrivingDataSlice";
import clsx from "clsx";

/*
  검색어 자동완성 기능 구현
  - input에 값을 작성할 때마다 onChange 이벤트로 keyword에 상태값 저장
  - keyword 값이 변경될 때마다 api 호출 (fetchDummyCrosswayList)
  - api를 통해 받아온 검색옵션은 searchOptions에 상태값 저장
  - map으로 검색옵션 출력
  - 클릭 또는 마우스 이벤트로 선택된 data는 searchInputVal에 상태값 저장

  방향키로 검색어 설정 기능 구현
  - 키보드 이벤트를 위한 인덱스 상태값 (-1) 설정 (검색어 옵션 리스트는 배열로 인덱스 번호 0 부터 시작하기 때문)
  - input에서 아래쪽 방향키를 누르면 인덱스 상태값 +1 위쪽 방향키를 누르면 인덱스 상태값 -1
  - 검색어 옵션 리스트 인덱스 값과 설정한 인덱스 상태값이 일치하면 배경색 변경
  - 엔터키를 누르면 클릭 이벤트 실행
*/
const AutoComplete = ({ inputType }) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState(""); // 검색어 저장
  const [searchOptionsIdx, setSearchOptionsIdx] = useState(-1); // 키보드 이벤트를 위해 인덱스 값 저장
  const [isOpen, setIsOpen] = useState(true); // ul 표기 여부 상태값
  const inputRef = useRef(null); // 입력창 컴포넌트의 DOM 요소를 참조하는 변수
  const isStart = inputType === "startCrossway";
  const placeholder = isStart ? "출발" : "도착";
  const search = useSelector((state) => state.autoDrivingData.search);
  const [searchOptions, setSearchOptions] = useState([]); //  api에서 온 데이터 저장
  const [startSearchInputVal, setStartSearchInputVal] = useState(
    search.startCrossway
  ); // 출발지 input value값 설정을 위해 검색어 저장
  const [endSearchInputVal, setEndSearchInputVal] = useState(
    search.endCrossway
  ); // 출발지 input value값 설정을 위해 검색어 저장

  useEffect(() => {
    dispatch(resetAutoDrivingEndCrossway());
    dispatch(resetAutoDrivingStartCrossway());
    // 달력 외 영역을 선택했을 때 달력이 닫히게 하기 위해 화면 전체에 클릭 이벤트 설정
    document.addEventListener("click", handleClickOutside);
    // cleanup 함수로 이전에 등록한 이벤트 제거
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(
      "AutoComplete 검색어",
      search.startCrossway,
      search.endCrossway
    );
  }, [search]);

  useEffect(() => {
    console.log("keyword 검색어", keyword);
  }, [keyword]);

  // 검색 옵션 데이터 불러올 api 호출
  useQuery(["crosswayList", keyword], () => drivingApi.crosswayList(keyword), {
    enabled: !!keyword,
    onSuccess: (data) => {
      const formattedData =
        inputType === "startCrossway"
          ? data?.data?.items.filter((i) => i !== search.endCrossway)
          : data?.data?.items.filter((i) => i !== search.startCrossway);
      setSearchOptions(formattedData);
      setIsOpen(true);
    },
  });

  /**
   * input 값이 변경될 때 상태값을 변경하는 함수
   * @param e 마우스 이벤트 객체
   */
  const handleStartOnChange = (e) => {
    console.log(e.target.value, e.target.value.length === 0);
    if (e.target.value.length === 0) setIsOpen(false);
    isStart
      ? setStartSearchInputVal(e.target.value)
      : setEndSearchInputVal(e.target.value); // input value 상태값 저장
    setKeyword(e.target.value); // 검색어 상태값 저장
  };

  /**
   * 자동완성 옵션 리스트에서 검색어를 선택했을 때 상태값을 변경하는 함수
   * @param crossway  선택된 교차로
   */
  const handleOptionsClick = (crossway) => {
    isStart ? setStartSearchInputVal(crossway) : setEndSearchInputVal(crossway); //  출발지 도착지에 따라 input value 상태값 저장
    dispatch(
      inputType === "startCrossway" // 출발인지 도착인지 구분하여 검색어 업데이트를 위한 액션 디스패치
        ? setAutoDrivingStartCrossway({ startCrossway: crossway })
        : setAutoDrivingEndCrossway({ endCrossway: crossway })
    );
    setSearchOptions([]); // 검색 완료 후 자동완성 옵션 리스트 값 삭제
    setSearchOptionsIdx(-1); // 검색 완료 후 키보드 이벤트를 위한 인덱스 기본값으로 재설정
  };

  /**
   * 키보드 이벤트 감지 함수
   * @param e 키보드 이벤트 객체
   */
  const handleOnKeyUp = (e) => {
    // 아래쪽 방향키를 눌렀을 때 인덱스 상태값이 검색어 옵션 아이템 갯수보다 작으면 인덱스 값 1 증가
    if (e.key === "ArrowDown" && searchOptions.length - 1 > searchOptionsIdx) {
      setSearchOptionsIdx(searchOptionsIdx + 1);
    }
    // 위쪽 방향키를 눌렀을 때 인덱스 상태값이 0보다 같거나 크면 인덱스 값 1 감소
    if (e.key === "ArrowUp" && searchOptionsIdx >= 0) {
      setSearchOptionsIdx(searchOptionsIdx - 1);
    }
    // 엔터를 눌렀을 때 인덱스 상태값이 0보다 같거나 크면 클릭 이벤트 실행
    if (e.key === "Enter" && searchOptionsIdx >= 0) {
      handleOptionsClick(searchOptions[searchOptionsIdx]);
      setSearchOptionsIdx(-1); // 인덱스 상태값 기본 값 (-1)으로 재설정
    } else if (e.key === "Enter" && searchOptionsIdx === -1) {
      handleOptionsClick(searchOptions[0]);
    }
  };

  /**
   * 입력창 외의 영역을 클릭했을 때 달력을 닫는 함수
   * @param e 마우스 이벤트 객체
   */
  const handleClickOutside = (e) => {
    if (
      // 입력창 영역에 클릭 영역이 포함되어있지 않고 클릭 영역도 입력창 영역이 아니면

      !inputRef.current.contains(e.target) &&
      e.target !== inputRef.current
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleDelBtn = () => {
    isStart ? setStartSearchInputVal("") : setEndSearchInputVal(""); // 출발지 목적지에 따라 검색창 비우기
    setSearchOptions([]); // 자동완성 옵션 리스트 값 삭제
    setSearchOptionsIdx(-1); // 키보드 이벤트를 위한 인덱스 기본값으로 재설정
    dispatch(
      isStart // 출발인지 도착인지 구분하여 검색어 리셋을 위한 액션 디스패치
        ? resetAutoDrivingStartCrossway()
        : resetAutoDrivingEndCrossway()
    );
  };

  return (
    <div className="relative grow bg-pale-grey textfield border-none rounded-basic h-[50px] appearance-none focus:outline-none">
      <div className="flex items-center">
        <input
          className="px-[20px] py-[13px] w-11/12 text-center focus:outline-none bg-transparent"
          placeholder={`${placeholder} 교차로명 입력`}
          onChange={handleStartOnChange}
          onKeyUp={handleOnKeyUp}
          value={isStart ? startSearchInputVal : endSearchInputVal}
          autoComplete="off"
          ref={inputRef}
        />
        <button
          className="grow border-none pr-5 textfield"
          type="button"
          onClick={handleDelBtn}
        >
          X
        </button>
      </div>
      {isOpen && (
        <ul className="absolute w-full z-50 max-h-[400px] bg-pale-grey overflow-y-auto shadow-lg">
          {searchOptions &&
            searchOptions?.map((crossway, index) => (
              <li
                className={clsx(
                  "pt-2 pb-2 px-3  cursor-pointer hover:bg-slate-200",
                  {
                    "bg-slate-200": searchOptionsIdx === index,
                  }
                )}
                key={index}
                onClick={() => handleOptionsClick(crossway)}
              >
                {crossway}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default AutoComplete;
