import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import AccountTable from "../../../components/table/AccountTable";
import Calendar from "../../../utils/calendar/Calendar";
import SearchBtn from "../../../components/button/SearchBtn";
import { useQuery } from "react-query";
import { superApi } from "../../../api/AccountApi";
import AccountOptions from "../../../components/select/AccountOptions";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { resetPageNum } from "../../../store/features/common/paginationSlice";

import Pagination from "../../../components/table/Pagination";

const SuperAccountContent = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, register } = useForm();
  const page_no = useSelector((state) => state.pagination.pageNum.pageNum); // api로 전달할 페이지 값
  const startDate = useSelector((state) => state.calendar.startDate); // 종료 날짜를 정하지 않았을 경우 api로 전달할 시작 날짜 값
  const today = new Date();
  const [paramData, setParamData] = useState({});
  const [totalNum, setTotalNum] = useState(0);
  const [apiData, setApiData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetPageNum()); // 페이지 값 리셋
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInsertBtn = () => {
    navigate("/super/add");
  };
  const handleCoBtn = () => {
    navigate("/super/co");
  };

  /**
   * 테이블 출력 데이터 key 값 한글 변경 함수
   * @param items 테이블 출력 데이터
   * @returns {*} 한글로 변환된 key 값
   */
  const formatter = (items) =>
    items.map((item) => ({
      No: item?.no,
      회사: item?.company,
      아이디: item?.id,
      이름: item?.name,
      휴대폰: item?.phoneNum,
      권한: item?.role,
      상태: item?.state,
      등록일시: item?.regDate,
    }));

  const { isFetching, isLoading, isSuccess } = useQuery(
    ["superUsers", paramData, page_no],
    () => superApi.superUsers(paramData, page_no),
    {
      onSuccess: (data) => {
        setTotalNum(data.data.totalNum);
        const formattedApiData = formatter(data.data.items);
        setApiData(formattedApiData);
      },
    }
  );

  const onValid = (data) => {
    data.startDate = format(new Date(startDate || today), "yyyy-MM-dd");
    data.endDate = format(
      new Date(data?.endDate || startDate || today),
      "yyyy-MM-dd"
    );
    console.log("superUsers data", data);
    setParamData(data);
  };

  return (
    <div className="grow bigBox">
      <form
        className="flex justify-between pb-[20px]"
        onSubmit={handleSubmit(onValid)}
      >
        <div className="calWrapper">
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <Calendar field={field} inputLen={"!w-[150px]"} isStart={true} />
            )}
          />
          <hr className="w-[10px] bg-silver" />
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <Calendar inputLen={"!w-[150px]"} field={field} />
            )}
          />
        </div>
        <div className="flex justify-center">
          <select
            className="sideBarSelectBox mr-[10px] w-[220px]"
            {...register("company")}
          >
            <AccountOptions type={"company"} />
          </select>
          <select
            className="sideBarSelectBox mr-[10px] w-[120px]"
            {...register("role")}
          >
            <AccountOptions type={"role"} />
          </select>
          <select
            className="sideBarSelectBox mr-[10px] w-[120px]"
            {...register("state")}
          >
            <AccountOptions type={"state"} />
          </select>
          <SearchBtn className="!w-[120px]">검색</SearchBtn>
        </div>
      </form>
      <div>
        {isSuccess && !isFetching && !isLoading ? (
          apiData.length > 0 ? (
            <AccountTable data={apiData} />
          ) : (
            <div className="loading">
              검색 조건에 해당하는 데이터가 없습니다
            </div>
          )
        ) : (
          <div className="loading">데이터를 가져오는 중입니다</div>
        )}
      </div>
      <div className="flex pt-4 space-x-3">
        <SearchBtn className="!w-[120px]" onClick={handleInsertBtn}>
          계정 등록
        </SearchBtn>
        <SearchBtn className="!w-[120px]" onClick={handleCoBtn}>
          회사 관리
        </SearchBtn>
      </div>
      <Pagination totalNum={totalNum} />
    </div>
  );
};

export default SuperAccountContent;
