import React from "react";
import GreenTimeSection from "./section/signalSidebar/GreenTimeSection";
import SearchForm from "./section/SearchForm";

const SignalSidebar = () => {
  return (
    <div className="sideBarWrapper">
      <div className="space-y-[30px] sideBarContainer">
        {/*검색 창*/}
        <SearchForm />
        {/*현시별 유효 녹색 시간 표*/}
        <GreenTimeSection />
      </div>
    </div>
  );
};

export default SignalSidebar;
