import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountTable from "../../../components/table/AccountTable";
import { PreventEnterDefault } from "../../../hoc/CommonHooks";
import { useDispatch, useSelector } from "react-redux";
import AdminAccountSearchOptionsModal from "./AdminAccountSearchOptionsModal";
import { showSearchOptionsModal } from "../../../store/features/account/searchOptionsSlice";
import SearchBtn from "../../../components/button/SearchBtn";
import Pagination from "../../../components/table/Pagination";
import { useQuery } from "react-query";
import { adminApi } from "../../../api/AccountApi";
import { format } from "date-fns";

const AdminAccountContent = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const search = useSelector((state) => state.searchOptionsModal.search);
  const page_no = useSelector((state) => state.pagination.pageNum.pageNum);

  const [apiData, setApiData] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  console.log("search", search);

  /**
   * 테이블 출력 데이터 key 값 한글 변경 함수
   * @param items 테이블 출력 데이터
   * @returns {*} 한글로 변환된 key 값
   */
  const formatter = (items) =>
    items.map((item) => ({
      No: item?.no,
      아이디: item?.id,
      이름: item?.name,
      휴대폰: item?.phoneNum,
      상태: item?.state,
      등록일시: format(new Date(item?.regDate), "yyyy.MM.dd."),
    }));

  const { isFetching, isLoading, isSuccess } = useQuery(
    ["adminUsers", search, page_no],
    () => adminApi.adminUsers(search, page_no),
    {
      onSuccess: (data) => {
        // console.log("users data", data.data);
        const formattedApiData = formatter(data.data.items);
        // console.log("users data", formattedApiData);
        setApiData(formattedApiData);
        // console.log("page_no data", data.data.totalNum);
        setTotalNum(data.data.totalNum);
      },
    }
  );

  const handleInsertBtn = () => {
    navigate("/admin/add");
  };

  return (
    <>
      <AdminAccountSearchOptionsModal inputRef={inputRef} />
      <div className="grow bigBox">
        <PreventEnterDefault />
        <div className="flex justify-end my-[20px]">
          <div
            ref={inputRef}
            className="pageInput !grow-0 !w-[200px] mr-[10px]"
            onClick={() => dispatch(showSearchOptionsModal({ show: true }))}
          />
          <SearchBtn
            className="!w-[120px]"
            onClick={() => dispatch(showSearchOptionsModal({ show: true }))}
          >
            검색
          </SearchBtn>
        </div>
        {/*</div>*/}
        <div>
          {isSuccess && !isFetching && !isLoading ? (
            apiData.length > 0 ? (
              <AccountTable data={apiData} />
            ) : (
              <div className="loading">
                검색 조건에 해당하는 데이터가 없습니다
              </div>
            )
          ) : (
            <div className="loading">데이터를 가져오는 중입니다</div>
          )}
        </div>
        <div className="flex pt-4">
          <SearchBtn className="!w-[120px]" onClick={handleInsertBtn}>
            계정 등록
          </SearchBtn>
        </div>
        <Pagination totalNum={totalNum} />
      </div>
    </>
  );
};

export default AdminAccountContent;
