import axios from "axios";
import { format } from "date-fns";
const today = format(new Date(), "yyyy-MM-dd");
export const monitoringApi = {
  chart: async () => {
    const response = await axios.get("/monitoring/hardware");
    return response.data;
  },
  serverInfo: async () => {
    const response = await axios.get("/monitoring/device");
    return response.data;
  },
  monitoringContent: async (params, pageNum) => {
    // console.log("monitoringContent params", params, pageNum);
    const { endDate, startDate, option, isDownExcel } = params;
    const response = await axios.get("/monitoring/software", {
      params: {
        start_date: startDate || today,
        end_date: endDate || today,
        status: option || "all",
        page_no: pageNum || 1,
        offset: 10,
        isDownExcel: isDownExcel || false,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },
};
