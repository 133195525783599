import React, { useEffect, useState } from "react";
import {
  CustomOverlayMap,
  Map,
  Polyline,
  ZoomControl,
} from "react-kakao-maps-sdk";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IcLocation } from "../../asset/icon/ic_location.svg";
import {
  setCrosswayNDirection,
  setDirectionNRotation,
  setRotation,
} from "../../store/features/search/searchSlice";
import { resetSelectedRoute } from "../../store/features/autoDrivingData/autoDrivingDataSlice";
import { DefaultValue } from "../../constants/Constants";

/**
 * 지도 불러오는 함수
 * @param coordinates 중심좌표
 * @param arrowData 화살표 데이터
 * @returns {JSX.Element} 지도
 * @constructor
 */
function KakaoMap({ coordinates, arrowData, center }) {
  // console.log("KakaoMap arrowData", arrowData);
  // // console.log("searchedArrow", searchedArrow);
  // // console.log("searchedCrossway", searchedCrossway);
  const kakao = window.kakao;

  const [clickedArrow, setClickedArrow] = useState(null);
  // const [clickedDirectionArrow, setClickedDirectionArrow] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const isRotation = location.pathname.includes("rotation"); // 방향별 회전 교통량 페이지인지 구분
  const isPass = location.pathname.includes("check-pass"); // 통과 교통량 페이지인지 구분
  const isDriving = location.pathname.includes("auto-driving-data"); // 자율주행 데이터 조회 페이지인지 구분
  const selectedRoute = useSelector(
    (state) => state.autoDrivingData.selectedPath
  );
  const selectedSearch = useSelector((state) => state.searchData.search);

  // console.log("KakaoMap selectedRoute", selectedRoute);
  // console.log("isRotation", isRotation);
  // useEffect(() => {
  // console.log(
  //     "clickedArrowclickedArrowclickedArrowclickedArrowclickedArrow",
  //     clickedArrow
  //   );
  // }, [clickedArrow]);

  /*
      지도에 화살표 표기
    - 데이터를 받은 후 overlayData 객체의 arrow 배열을 순회하면서 각 요소에 대한 정보를 기반으로 <Polyline> 컴포넌트를 생성한다

    - path 속성에는 화살표의 좌표값이 들어간다
    - 이 때, formattedPath 메서드를 사용하여 선의 정보를 latlng 배열로 변환한다


    방향별 회전 교통량의 화살표와 일반 페이지의 화살표를 구분한다 (url로 구분)
    방향별 회전 교통량의 화살표는 상위 방향 화살표와 하위 방향 화살표로 나뉜다
    - 상위 방향 화살표 (진행1)
    - 하위 방향 화살표 (진행1_방향1)
    - 구분 함수 isChildArrow
   */

  /**
   * 하위 방향 화살표(클릭했을 때 나오는 2차 화살표)인지 구분하는 함수
   * @param arrowName 화살표 이름
   * @returns {*}
   */
  const isChildArrow = (arrowName) => {
    //console.log("arrowName", arrowName);
    return arrowName.includes("_"); // 변수에 "_"가 존재하는지로 하위 방향 화살표인지 판별
  };

  const [overlayData, setOverlayData] = useState({
    arrow: arrowData?.arrow?.filter((item) => !isChildArrow(item.arrowName)), // 최초에 방향 화살표(상위 방향 화살표) 및 일반 화살표만 출력
  });

  useEffect(() => {
    // console.log("overlayDataoverlayData arrowData", arrowData);
    // console.log("overlayDataoverlayData coordinates", coordinates);
    // console.log("overlayDataoverlayData overlayData", overlayData);
  }, [overlayData]);
  /**
   * Drawing Manager에서 가져온 데이터 중 선과 다각형의 꼭지점 정보를 latlng 배열로 반환하는 함수
   * @param points  좌표
   * @returns {*}
   */
  function pointsToPath(points) {
    // console.log("들어오나", points);
    return points.map((point) => ({
      lat: point.y,
      lng: point.x,
    }));
  }

  /*
  일반 화살표 이벤트 (click, mouseOut)
  클릭 시 해당 정보를 가져오고 확대되며 마우스가 해당 화살표 밖으로 벗어나면 축소된다
*/

  /**
   * 화살표 선택 시 해당 정보 가져오는 함수
   * @param e 마우스 이벤트 객체
   * @param arrowName 화살표 이름
   */
  const handleArrowClick = (e, arrowName, crossway) => {
    kakao.maps.event.preventMap(); // 지도 클릭 이벤트 막기 (지도 클릭 이벤트가 최상위에서 실행되기 때문)
    // console.log("e", e);
    // console.log("클릭된 화살표 이름", e, crossway, arrowName);
    // 선택된 하위 화살표 회전방향으로 그래프 업데이트를 위한 액션 디스패치

    dispatch(
      isChildArrow(arrowName)
        ? setRotation(arrowName.slice(3))
        : setCrosswayNDirection({
            crossway: crossway,
            direction: `${arrowName} 접근`,
          })
    );

    const matchArrow = arrowData?.arrow?.find((i) => i.arrowName === arrowName);
    const preArrowColor = matchArrow.options.strokeColor;
    if (clickedArrow) {
      clickedArrow.setOptions({ strokeColor: preArrowColor, strokeWeight: 8 }); // 이전에 클릭된 화살표의 색상 및 크기를 원래대로 복원
    }
    e.setOptions({ strokeColor: "rgb(249 115 22)", strokeWeight: 15 }); // 클릭된 일반 화살표 색상 및 크기 확대
    setClickedArrow(e); // 클릭된 일반 화살표 저장
  };

  useEffect(() => {
    if (isDriving && selectedRoute > 0) {
      const showArrowData = arrowData?.arrow?.map((i) =>
        i.route === selectedRoute
          ? {
              ...i,
              options: {
                ...i.options,
                strokeWeight: 15,
                zIndex: 50,
              },
            }
          : {
              ...i,
              options: {
                ...i.options,
                strokeColor: "rgb(213,212,222)",
                zIndex: 1,
              },
            }
      );

      // 변경된 상위 방향 화살표(현재 클릭된 화살표) 및 하위 방향 화살표 배열 재설정
      setOverlayData({ arrow: showArrowData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  useEffect(() => {
    if (isPass) {
      // console.log("selectedSearch", selectedSearch);
      // console.log("arrowData", arrowData);
      if (selectedSearch.direction.length > 0) {
        const showArrowData = arrowData?.arrow?.map((i) =>
          i.crossway === selectedSearch.crossway &&
          i.arrowName === selectedSearch.direction.slice(0, 2)
            ? {
                ...i,
                options: {
                  ...i.options,
                  strokeColor: "rgb(249 115 22)",
                  strokeWeight: 15,
                },
              }
            : {
                ...i,
              }
        );

        // console.log("showArrowData", showArrowData);
        setOverlayData({ arrow: showArrowData });
      }
    } else if (isRotation) {
      // console.log("selectedSearch", selectedSearch);
      // console.log("arrowData", arrowData);

      if (selectedSearch.direction !== DefaultValue.DIRECTION) {
        const tmpData = arrowData?.arrow?.filter(
          (i) =>
            i.crossway === selectedSearch.crossway &&
            i.arrowName.includes(selectedSearch.direction.slice(0, 2))
        );
        // console.log("tmpData", tmpData);

        const tmpArrowData = tmpData.map((i) =>
          i.arrowName === selectedSearch.direction.slice(0, 2)
            ? {
                ...i,
                options: {
                  ...i.options,

                  strokeColor: "rgb(249 115 22)",
                  strokeWeight: 15,
                },
              }
            : i.crossway === selectedSearch.crossway &&
              isChildArrow(i.arrowName) &&
              i.arrowName.includes(selectedSearch.direction.slice(0, 2))
            ? i.arrowName.includes(selectedSearch.rotation)
              ? {
                  ...i,
                  options: {
                    ...i.options,
                    strokeColor: "rgb(249 115 22)",
                    strokeWeight: 15,
                  },
                }
              : i
            : null
        );

        // console.log("tmpArrowData", tmpArrowData);

        // 변경된 상위 방향 화살표(현재 클릭된 화살표) 및 하위 방향 화살표 배열 재설정
        setOverlayData({ arrow: tmpArrowData });
      } else {
        setOverlayData({
          arrow: arrowData?.arrow?.filter(
            (arrow) => !isChildArrow(arrow.arrowName)
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch]);

  /*
  상위 방향 화살표 이벤트 (click, mouseOut)
  클릭 시 해당하는 하위 방향 화살표를 표시
*/
  /**
   * 마우스가 화살표 밖으로 벗어날 때 축소 이벤트
   * @param e 마우스 이벤트 객체
   */
  // const handleArrowMouseout = (e) => {
  //   if (e !== clickedArrow) {
  //     e.setOptions({ strokeWeight: 8 }); // 클릭된 일반 화살표가 아닌 경우에만 축소 이벤트 발생
  //   }
  // };

  /**
   * 상위 방향 화살표 선택 시 다른 화살표 출력 함수
   * @param e 마우스 이벤트 객체
   * @param arrowName 화살표 이름
   */
  const handleDirectionArrowClick = (e, arrowName, crossway) => {
    kakao.maps.event.preventMap(); // 지도 클릭 이벤트 막기 (지도 클릭 이벤트가 최상위에서 실행되기 때문)
    // console.log("클릭된 방향 화살표", arrowName);
    // 선택된 상위 방향 화살표 진행방향으로 그래프 업데이트를 위한 액션 디스패치
    dispatch(
      setCrosswayNDirection({
        crossway: crossway,
        direction: `${arrowName} 접근`,
      })
    );
    // // arrowData 에서 arrowName이 포함된 화살표 데이터(상위 방향 화살표 및 하위 방향 화살표 데이터)만 필터링 후
    // // arrowName와 일치하는 화살표 (상위 방향 화살표)의 strokeColor를 변경한 새로운 배열 생성
    // const showArrowData = arrowData?.arrow
    //   .filter((i) => i.crossway === crossway && i.arrowName.includes(arrowName))
    //   .map((i, index) =>
    //     i.crossway === crossway && i.arrowName === arrowName
    //       ? {
    //           ...i,
    //           options: {
    //             ...i.options,
    //
    //             strokeColor: "rgb(249 115 22)",
    //             strokeWeight: 15,
    //           },
    //         }
    //       : i
    //   );
    //
    // // 변경된 상위 방향 화살표(현재 클릭된 화살표) 및 하위 방향 화살표 배열 재설정
    // setOverlayData({ arrow: showArrowData });
  };

  /**
   * 마우스가 방향 화살표 밖으로 벗어날 때 축소 이벤트
   * @param e 마우스 이벤트 객체
   */
  // const handleDirectionArrowMouseout = (e) => {
  //   // console.log("마우스 아웃", e);
  //   if (e !== clickedDirectionArrow) {
  //     e.setOptions({ strokeWeight: 8 }); // 클릭된 방향 화살표가 아닌 경우에만 축소 이벤트 발생
  //   }
  // };

  /**
   * 지도 클릭 시, 초기 화살표 출력 함수
   * @param e 마우스 이벤트 객체
   */
  const handleMapClick = (e) => {
    // 텍스트 업데이트를 위한 액션 디스패치
    dispatch(setDirectionNRotation());
    if (isDriving) {
      dispatch(resetSelectedRoute());
      return;
    }
    // 2차 화살표가 아닌 화살표 출력
    setOverlayData({
      arrow: arrowData?.arrow?.filter(
        (arrow) => !isChildArrow(arrow.arrowName)
      ),
    });
  };

  return (
    <Map
      // center={{ lat: 37.350126, lng: 126.73112 }}
      center={{ lat: center.lat, lng: center.lng }}
      className="grow w-screen"
      level={isDriving ? 4 : 1}
      onClick={handleMapClick}
    >
      <ZoomControl />
      {coordinates.map((item, index) => (
        <CustomOverlayMap
          key={index}
          position={{ lat: item.lat, lng: item.lng }}
        >
          <div
            key={index}
            className="w-[144px] bg-[#28adf8] h-[50px] flex justify-center items-center text-white rounded-[25px] shadow-marker "
          >
            <IcLocation className="icon2 mr-[12px]" />
            <span className="title2">{item.crossway}</span>
          </div>
        </CustomOverlayMap>
      ))}

      {overlayData.arrow?.map(({ points, options, arrowName, crossway }, i) =>
        isRotation && !isChildArrow(arrowName) ? ( // 방향별 회전 교통량 페이지이자 상위 방향 화살표라면
          <Polyline
            key={i}
            path={pointsToPath(points)}
            {...options}
            onClick={(e) => handleDirectionArrowClick(e, arrowName, crossway)}
            // onMouseover={(e) => e.setOptions({ strokeWeight: 15 })} // 화살표에 마우스를 올렸을 때 확대 이벤트
            // onMouseout={(e) => handleDirectionArrowMouseout(e)}
          />
        ) : isChildArrow(arrowName) || isPass ? (
          // 일반 화살표 및 하위 방향 화살표 또는 통과 교통량 페이지라면
          <Polyline
            key={i}
            path={pointsToPath(points)}
            {...options}
            onClick={(e) => handleArrowClick(e, arrowName, crossway)}
            // onMouseover={(e) => e.setOptions({ strokeWeight: 15 })} // 화살표에 마우스를 올렸을 때 확대 이벤트
            // onMouseout={handleArrowMouseout}
          />
        ) : (
          // 방향별 화전 교통량 페이지 및 통과 교통량 페이지가 아니라면 아무 이벤트 없음
          <Polyline key={i} path={pointsToPath(points)} {...options} />
        )
      )}
    </Map>
  );
}

export default React.memo(KakaoMap);
