import React, { useEffect, useState } from "react";
import KakaoMap from "../../../../components/map/KakaoMap";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { rotationApi } from "../../../../api/SearchApi";
import { resetSearch } from "../../../../store/features/search/searchSlice";

const CheckDirectionMap = () => {
  const [coordinateData, setCoordinateData] = useState([]);
  const [arrowDataContent, setArrowDataContent] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   console.log("coordinateData", coordinateData);
  // }, [coordinateData]);
  useEffect(() => {
    dispatch(resetSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * 종류에 따른 화살표 색상 변경
   * @param arrowName 화살표 종류
   * @returns {string}  색상
   */
  const arrowColor = (arrowName) => {
    let res = "";
    res = arrowName.includes("_") ? "#7dd3fc" : "#3399ff";
    return res;
  };
  const findCenter = (search) => {
    // console.log("findCenter search", search);
    const res = coordinateData.find((i) => i.crossway === search.crossway);
    // console.log("findCenter res", res);
    return res;
  };
  // 화살표 데이터 받아오는 api
  // 검색 시 교차로 중심좌표 받아오는 api
  const tmp = useSelector((state) => state.searchData.search);
  const search = Object.fromEntries(
    Object.entries(tmp).filter(
      ([key, value]) => key !== "direction" && key !== "rotation"
    )
  );
  // console.log("search useSelector", search);
  const { isFetching, isLoading } = useQuery(
    ["rotationArrow", search],
    () => rotationApi.rotationArrow(search),
    {
      enabled: arrowDataContent.length === 0,
      onSuccess: (data) => {
        // console.log("넘어온 rotationArrow", data.data.items);
        setCoordinateData(
          data.data.items.map((item) => Object.values(item)[0])
        );
        setArrowDataContent(
          data.data.items.flatMap((items) =>
            Object.values(items)[0].arrows.flatMap((item) => ({
              ...item,
              crossway: Object.values(items)[0].crossway,
              type: "arrow",
              coordinate: "wgs84",
              options: {
                strokeColor: arrowColor(item.arrowName),
                strokeWeight: 8,
                strokeStyle: "solid",
                strokeOpacity: 1,
                endArrow: true,
              },
            }))
          )
        );
      },
    }
  );
  const center = findCenter(search);
  return (
    <>
      {!isLoading && !isFetching && arrowDataContent.length > 0 ? (
        <KakaoMap
          arrowData={{ arrow: arrowDataContent }}
          coordinates={coordinateData}
          center={center}
        />
      ) : (
        <div className="flex justify-center items-center grow w-screen">
          <p className="loading">데이터를 불러오는 중입니다</p>
        </div>
      )}
    </>
  );
};

export default CheckDirectionMap;
