import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IcUser } from "../../asset/icon/login/ic_user.svg";
import { ReactComponent as IcPassword } from "../../asset/icon/login/ic_password.svg";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { userLogin } from "../../store/features/auth/authActions";

export default function Login() {
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    reset,
    formState: { isValid },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [idError, setIdError] = useState("");
  let pwError = "";
  const { access_token } = useSelector((state) => state.auth);
  const watchId = watch("id");
  const watchPass = watch("password");

  useEffect(() => {
    idError && watchId && setIdError("");
    pwError && watchPass && setIdError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchId, watchPass]);

  useEffect(() => {
    if (access_token) {
      navigate("/dashboard");
    }
  }, [navigate, access_token]);

  const onValid = async (data) => {
    try {
      const res = await dispatch(userLogin(data));
      if (!!res.error) {
        setIdError(res.payload);
        setFocus("id");
        reset();
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="flex justify-center items-center grow min-h-screen w-full overflow-hidden">
        <div className="w-[300px]">
          <p className="flex justify-center heading text-dark-grey mb-[30px]">
            통합 신호운영 센터시스템
          </p>
          <p className="flex justify-center heading text-purpleish-blue mb-[60px]">
            블루시그널
          </p>
          <form onSubmit={handleSubmit(onValid)}>
            <div
              className={clsx(
                "flex w-[300px] h-[50px] pl-[20px] mb-[10px] rounded-basic bg-pale-grey focus-within:bg-white focus-within:ring-2 ",
                {
                  "focus-within:ring-purpleish-blue": !idError,
                  "focus-within:ring-dark-pink": idError,
                }
              )}
            >
              <IcUser className="icon my-0 mr-[16px] object-contain self-center grow-0" />
              <input
                placeholder="아이디"
                {...register("id", { required: true })}
                className="w-100 h-100 pl-1 bg-transparent  textfield placeholder:Textfield border-none focus:outline-none focus:placeholder-dark-grey"
              />
            </div>
            <div
              className={clsx(
                "flex w-[300px] h-[50px] pl-[20px] mb-[10px] rounded-basic bg-pale-grey focus-within:bg-white focus-within:ring-2 ",
                {
                  "focus-within:ring-purpleish-blue": !pwError,
                  "focus-within:ring-dark-pink": pwError,
                }
              )}
            >
              <IcPassword className="icon my-0 mr-[16px] object-contain self-center grow-0" />
              <input
                placeholder="비밀번호"
                type="password"
                {...register("password", { required: true })}
                className="w-100 h-100 pl-1 bg-transparent textfield placeholder:Textfield border-none focus:outline-none  focus:placeholder-dark-grey"
              />
            </div>
            <button
              type="submit"
              className={clsx(
                'w-[300px] h-[50px] grow-0 pt-[15px] pb-[14px] text-center rounded-basic shadow-btn bg-slate-grey hover:"bg-[#6e7285]"',
                {
                  "hover:bg-slate-grey": !isValid,
                  "bg-purpleish-blue": isValid,
                }
              )}
              disabled={!isValid}
            >
              <span className="title2 text-white">로그인</span>
            </button>

            <div className="mt-[30px] relative caption uppercase text-center">
              <Link
                to={{ pathname: "/find-account" }}
                className="caption text-blue-900 hover:text-blue-800"
              >
                아이디 /비밀번호 찾기
              </Link>
              <p className="w-full absolute text-center mt-[30px] caption text-dark-pink">
                {idError || pwError}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
