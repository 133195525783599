import React from "react";
import clsx from "clsx";

const SendingDataLogDetailContent = (data, date) => {
  return (
    <div className="boxContent grow flex flex-col items-center w-full p-3">
      <div className="flex w-[1400px] h-600px p-3 overflow-x-scroll space-x-4">
        {date.length > 0 &&
          data.map((items, index) => (
            <div
              key={index}
              className="w-[600px] h-4/5 flex bg-white rounded-box"
            >
              <div className="flex flex-col justify-center w-[320px] p-10 textfield">
                {Object.entries(items).map(([key, value], itemIndex) => (
                  <div
                    key={itemIndex}
                    className="flex px-4 py-2 even:bg-pale-grey justify-between"
                  >
                    <div
                      className={clsx("font-bold whitespace-pre", {
                        "text-sm pr-2": key === "receiveTime",
                        "text-xs pr-2": key === "createTimeStamp",
                      })}
                    >
                      {key}
                    </div>
                    <div
                      className={clsx("font-bold whitespace-pre", {
                        "text-sm": key === "receiveTime",
                        "text-xs": key === "createTimeStamp",
                      })}
                    >
                      {key === "receiveTime"
                        ? `${date.slice(0, 16)}${value.slice(-3)}`
                        : value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SendingDataLogDetailContent;
