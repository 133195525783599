import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PreventEnterDefault } from "../../../hoc/CommonHooks";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { showChangePwdModal } from "../../../store/features/account/changePwdSlice";
import { showConfirmModal } from "../../../store/features/common/modalSlice";
import DoubleCheckModal from "../../../components/modal/DoubleCheckModal";
import SearchBtn from "../../../components/button/SearchBtn";
import { useQuery } from "react-query";
import { userApi } from "../../../api/AccountApi";

const UserAccountContent = () => {
  const { handleSubmit, register, reset, errors, watch, setFocus } = useForm();
  const dispatch = useDispatch();

  const [info, setInfo] = useState("변경"); // 완료 모달에 표시될 문구 (수정, 변경)
  const [userInfo, setUserInfo] = useState({});
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    setUpdateData({});
  }, []);

  const watchPhone1 = watch("phone1");
  const watchPhone2 = watch("phone2");

  useEffect(() => {
    console.log("watchPhone1", watchPhone1);
    console.log("watchPhone2", watchPhone2);
    if (watchPhone1?.length === 3) setFocus("phone2");
    if (watchPhone2?.length === 4) setFocus("phone3");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPhone1, watchPhone2]);

  const { refetch } = useQuery(["userDetail"], () => userApi.userDetail(), {
    onSuccess: (data) => {
      console.log("data", data.data);
      setUserInfo({
        id: data?.data?.user_id,
        name: data?.data?.name,
        phone1: data?.data?.phone_num.slice(0, 3),
        phone2: data?.data?.phone_num.slice(3, 7),
        phone3: data?.data?.phone_num.slice(7),
      });
    },
  });

  useQuery(["userUpdate", updateData], () => userApi.userUpdate(updateData), {
    enabled: !!updateData.name || !!updateData.phone,
    onSuccess: (data) => {
      if (data.code === 200) {
        dispatch(showConfirmModal({ show: true })); // 완료 모달 상태 변경
        setUpdateData({});
        reset();
        refetch();
      } else {
        console.log("뭔가 오류");
      }
      console.log("userUpdate data", data);
      setUserInfo({});
    },
  });

  // form submit 시 (하단 수정 버튼) 완료 모달을 표시하는 함수
  const onValid = (data) => {
    console.log("AdminAccountContent data", data);
    const tmpPhone1 = data.phone1.length > 0 ? data.phone1 : userInfo.phone1;
    const tmpPhone2 = data.phone2.length > 0 ? data.phone2 : userInfo.phone2;
    const tmpPhone3 = data.phone3.length > 0 ? data.phone3 : userInfo.phone3;

    const tmp = {
      name: data.name.length > 0 ? data.name : userInfo.name,
      phone: tmpPhone1 + tmpPhone2 + tmpPhone3,
    };
    console.log("tmp", tmp);
    setUpdateData(tmp);
    setInfo("수정"); // 완료 모달 문구 변경
  };
  // 회원 탈퇴 버튼 선택 시 이중 확인 f모달 표시하는 함수
  const handleCancellationBtn = () => {
    setInfo("탈퇴"); // 완료 모달 문구 변경
    dispatch(showChangePwdModal({ show: true }));
  }; // 비밀번호 변경 버튼 선택 시 모달 표시하는 함수
  const handleChangePassBtn = () => {
    setInfo("변경"); // 완료 모달 문구 변경
    dispatch(showChangePwdModal({ show: true }));
  };

  return (
    <>
      {/* 엔터 이벤트 방지 */}
      <PreventEnterDefault />
      <UpdatePasswordModal data={info} />
      <DoubleCheckModal data={info} />
      <ConfirmModal data={info} />
      <form
        key={2}
        className="flex flex-col mt-[60px] box h-3/5"
        onSubmit={handleSubmit(onValid)}
      >
        <div className="boxTitle">개인 계정 관리</div>
        <div className=" boxContent flex justify-center w-full p-[30px]">
          <div className="grid grid-cols-4 items-center">
            <div className="title1">아이디</div>
            <div className="col-span-3 textfield">{userInfo.id}</div>

            <div className="title1">비밀번호</div>
            <div className="col-span-3">
              <SearchBtn
                type="button"
                className=" textfield  !h-[36px] !w-[120px]"
                onClick={handleChangePassBtn}
              >
                변경
              </SearchBtn>
            </div>

            <div className="title1">이름</div>
            <div className="col-span-3">
              <input
                type="text"
                className="accountInput"
                maxLength={10}
                placeholder={userInfo.name}
                {...register("name")}
              />
            </div>

            <div className="title1">연락처</div>
            <div className="items-center col-span-3">
              <input
                type="text"
                className="accountInput"
                placeholder={userInfo?.phone1}
                maxLength="3"
                {...register("phone1", {
                  // required: "연락처를 올바르게 입력하세요",
                  pattern: {
                    value: /^\d+$/,
                    message: "숫자만 입력하세요",
                  },
                })}
              />
              <span className="px-5">-</span>
              <input
                type="text"
                className="accountInput"
                placeholder={userInfo?.phone2}
                maxLength="4"
                {...register("phone2", {
                  // required: "연락처를 올바르게 입력하세요",
                  pattern: {
                    value: /^\d+$/,
                    message: "숫자만 입력하세요",
                  },
                })}
              />
              <span className="px-5">-</span>
              <input
                type="text"
                className="accountInput"
                placeholder={userInfo?.phone3}
                maxLength="4"
                {...register("phone3", {
                  // required: "연락처를 올바르게 입력하세요",
                  pattern: {
                    value: /^\d+$/,
                    message: "숫자만 입력하세요",
                  },
                  maxLength: 4,
                })}
              />
              <span className="absolute text-dark-pink mt-2 ml-3">
                {errors?.phone1 && errors?.phone1?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-baseline mt-[30px]">
          <button
            className="accountBtn"
            type="button"
            onClick={handleCancellationBtn}
          >
            회원 탈퇴
          </button>
          <SearchBtn type="submit" className="!w-[120px] !h-[42px]">
            수정
          </SearchBtn>
        </div>
      </form>
    </>
  );
};

export default UserAccountContent;
