import axios from "axios";

export const findAccountApi = {
  findId: async (params) => {
    // console.log("findAccountApi params", params);
    const { name, phone_num } = params;
    const response = await axios.get("/account/id", {
      params: {
        name,
        phoneNum: phone_num,
      },
    });
    // console.log("응답", response.data);
    return response.data;
  },

  findPass: async (params) => {
    // console.log("userUpdate params", params);
    const { id, phone_num } = params;
    const response = await axios.put(
      "/account/password",
      {
        id,
        phoneNum: phone_num,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    // console.log("응답", response.data);
    return response.data;
  },
};
