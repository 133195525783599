import React from "react";
import { ReactComponent as IcNormal } from "../../../asset/icon/ic_normal.svg";
import { ReactComponent as IcError } from "../../../asset/icon/ic_error.svg";

const HWTable = ({ data }) => {
  return (
    <div>
      <table className="w-full border border-pale-grey">
        <tbody>
          <tr>
            <td className="bg-pale-grey py-1 text-center font-semibold">
              기기명
            </td>
          </tr>
          <tr>
            <td className="py-2 flex justify-center bg-white">
              {data[0] === 1 ? (
                <IcNormal />
              ) : data[0] === 0 ? (
                <IcError />
              ) : (
                "데이터를 불러오는 중입니다"
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HWTable;
