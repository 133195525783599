import React from "react";
import CheckRotationTrafficSidebar from "../../contents/check/CheckRotationTrafficSidebar";
import CheckDirectionMap from "../../contents/check/section/map/CheckDirectionMap";

const CheckPass = () => {
  return (
    <>
      <CheckRotationTrafficSidebar />
      <CheckDirectionMap />
    </>
  );
};

export default CheckPass;
