import React from "react";
import ApexChart from "react-apexcharts";

const SingleHorizontalBarChart = ({ data, title }) => {
  console.log("SingleHorizontalBarChart", data);
  return (
    <ApexChart
      type="bar"
      series={[
        {
          name: title,
          data: [data],
        },
      ]}
      height={50}
      options={{
        chart: {
          toolbar: {
            show: false,
          },
          stacked: true,
          background: "transparent",
          parentHeightOffset: 0,
          sparkline: {
            enabled: true,
          },
        },
        subtitle: {
          floating: true,
          align: "left",
          offsetY: 12,
          offsetX: 12,
          text: data + "%",
          style: {
            fontSize: "20px",
            color: "white",
            fontFamily: "pretendard",
          },
        },
        theme: {
          mode: "light",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        yaxis: {
          max: 100,
          show: false,
        },
        xaxis: {
          categories: [title],
          labels: {
            show: false,
          },
          show: false,
        },
        grid: { show: false },
        plotOptions: {
          bar: {
            horizontal: true,
            colors: {
              backgroundBarColors: ["#40475D"],
            },
          },
        },
        tooltip: {
          y: { formatter: (value) => value + "%" },
        },
      }}
    />
  );
};

export default SingleHorizontalBarChart;
