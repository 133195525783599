import React from "react";
import SuperAccountContent from "../../../contents/account/superAdmin/SuperAccountContent";

const AdminAccount = () => {
  return (
    <div className="content">
      <SuperAccountContent />
    </div>
  );
};

export default AdminAccount;
