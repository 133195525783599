import React, { useEffect, useState } from "react";
import KakaoMap from "../../../../components/map/KakaoMap";
import { useQuery } from "react-query";
import { signalApi } from "../../../../api/SearchApi";
import { useDispatch, useSelector } from "react-redux";
import { resetSearch } from "../../../../store/features/search/searchSlice";
const CheckSignalMap = () => {
  const [coordinates, setCoordinates] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const findCenter = (search) => {
    // console.log("findCenter search", search);
    const res = coordinates.find((i) => i.crossway === search.crossway);
    // console.log("findCenter res", res);
    return res;
  };
  const search = useSelector((state) => state.searchData.search);
  const center = findCenter(search);
  const { isFetching, isLoading } = useQuery(
    ["signalMap"],
    () => signalApi.signalMap(),
    {
      onSuccess: (data) => {
        setCoordinates(data.data.items.map((item) => Object.values(item)[0]));
      },
    }
  );
  return (
    <>
      {!isLoading && !isFetching && coordinates.length > 0 ? (
        <KakaoMap coordinates={coordinates} center={center} />
      ) : (
        <div className="flex justify-center items-center grow w-screen">
          <p className="loading">데이터를 불러오는 중입니다</p>
        </div>
      )}
    </>
  );
};

export default CheckSignalMap;
