import { createSlice } from "@reduxjs/toolkit";

const initialState = { show: false };
const changeCoSlice = createSlice({
  name: "changeCo",
  initialState,
  reducers: {
    showChangeCoModal: (state, action) => {
      state.show = action.payload.show;
    },
  },
});

export default changeCoSlice.reducer;
export const { showChangeCoModal } = changeCoSlice.actions;
