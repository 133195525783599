import { createSlice } from "@reduxjs/toolkit";

const initialState = { show: false };
const changePwdSlice = createSlice({
  name: "changePwd",
  initialState,
  reducers: {
    showChangePwdModal: (state, action) => {
      state.show = action.payload.show;
    },
  },
});

export default changePwdSlice.reducer;
export const { showChangePwdModal } = changePwdSlice.actions;
