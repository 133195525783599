import axios from "axios";
export const dashboardApi = {
  intersectionStatus: async () => {
    const response = await axios.get("/dashboard/crossway/status");

    return response.data;
  },
  logStatus: async () => {
    const response = await axios.get("/dashboard/log/status");
    return response.data;
  },
  trafficVolume: async () => {
    const response = await axios.get("/dashboard/congestion/traffic/crossway");
    return response.data;
  },
  hardwareMonitoring: async () => {
    const response = await axios.get("/dashboard/hardware/status");
    return response.data;
  },
  softwareMonitoring: async () => {
    const response = await axios.get("/dashboard/software/status");
    return response.data;
  },
};
