import axios from "axios";
let isRefreshing = false; // 토큰 갱신 중인지 여부
let failedQueue = []; // 실패한 요청들을 저장하는 배열

const processFailedQueue = (token) => {
  failedQueue.forEach((prom) => {
    prom.config.headers["Authorization"] = `Bearer ${token}`;
    prom.resolve(axios(prom.config)); // 다시 요청
  });
  failedQueue = [];
};

const handle401Error = async (error, config) => {
  const refreshToken = await localStorage.getItem("refresh_token");
  if (!isRefreshing) {
    console.log("!isRefreshing");
    isRefreshing = true;
    try {
      const res = await axios.get("/token/refresh", {
        headers: {
          RefreshToken: refreshToken,
        },
      });
      if (res.status === 200) {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.access_token}`;
        processFailedQueue(res.data.access_token); // 다시 실패한 요청 처리
        isRefreshing = false;
      }
    } catch (error) {
      console.error(error);
      console.log("에러로 처리");
      processFailedQueue(null); // 실패한 요청 처리
      isRefreshing = false;
    }
  } else {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject, config: { ...config } });
    });
  }
};

const InitializeApp = () => {
  // 헤더에 인증 토큰 포함 설정
  const access_token = localStorage.getItem("access_token");
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
  }

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      if (error.config.url === "/account/login") {
        return Promise.reject(error);
      }
      const { config } = error?.response;
      if (error?.response?.status === 401) {
        return handle401Error(error, config);
      } else if (error?.response?.status === 400) {
        if (error.config.url === "/token/refresh") {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("role");
          window.location.replace("/");
          processFailedQueue(null);
          isRefreshing = false;
        }
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject, config: { ...config } });
        });
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("role");
        window.location.href = "/login";
      }
      throw error;
    }
  );
};

export default InitializeApp;
