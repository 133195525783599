import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import calendarReducer from "./features/calendar/calendarSlice";
import paginationReducer from "./features/common/paginationSlice";
import findAccountReducer from "./features/account/findAccountSlice";
import changePwdReducer from "./features/account/changePwdSlice";
import confirmModalReducer from "./features/common/modalSlice";
import changeCoReducer from "./features/account/changeCoSlice";
import searchOptionsReducer from "./features/account/searchOptionsSlice";
import searchReducer from "./features/search/searchSlice";
import signalResultReducer from "./features/signalResult/signalResultSlice";
import autoDrivingDataReducer from "./features/autoDrivingData/autoDrivingDataSlice";
import navSliceReducer from "./features/nav/navSlice";
import excelReducer from "./features/common/excelSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    findAccount: findAccountReducer,
    calendar: calendarReducer,
    pagination: paginationReducer,
    changePwd: changePwdReducer,
    confirmModal: confirmModalReducer,
    coModal: changeCoReducer,
    searchOptionsModal: searchOptionsReducer,
    searchData: searchReducer,
    signalResult: signalResultReducer,
    autoDrivingData: autoDrivingDataReducer,
    nav: navSliceReducer,
    excel: excelReducer,
  },
});

export default store;
