import NavMenu from "./NavMenu";
import NavHeader from "./NavHeader";

function Nav() {
  /*
    NavMenu 컴포넌트를 렌더링하여 메뉴 데이터를 표시한다
    NavMenu는 NavContents 컴포넌트를 사용하여 메뉴 항목을 생성한다
    */
  return (
    <>
      <div className="hidden lg:flex grow w-[280px] min-w-max pt-[30px] px-[20px] bg-white">
        <div className="flex flex-col items-center w-[240px]">
          <NavHeader />
          <NavMenu />
        </div>
      </div>
    </>
  );
}

export default Nav;
