import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { showConfirmModal } from "../../store/features/common/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalBtn from "../button/ModalBtn";
import { logout } from "../../store/features/auth/authSlice";

const ConfirmModal = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const show = useSelector((state) => state.confirmModal.show); // 확인 모달이 표시될지 여부를 나타내는 상태값
  const cancelButtonRef = useRef(null); // 모달 취소 버튼
  const location = useLocation();
  const isSuper = location.pathname.includes("super");
  const isCo = location.pathname.includes("co");
  const isCancellation = data === "탈퇴"; // 탈퇴 모달인지 아닌지 구분
  const isDel = data === "삭제"; // 삭제 모달인지 아닌지 구분
  // console.log("data", data, isDel);
  /**
   * 확인 버튼 선택 시 확인 모달창 닫는 함수
   */
  const handleConfirmBtn = () => {
    dispatch(showConfirmModal({ show: false })); // 확인 모달 상태 변경
    // console.log(typeof data, isDel);
    if (isCancellation) {
      dispatch(logout());
    }
    if (isDel)
      navigate(isSuper ? (isCo ? "/super/co" : "/super") : "/admin", {
        replace: true,
      });
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(showConfirmModal({ show: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex justify-center items-center">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-2">
                        <div className="my-7">
                          <p className="title2">{`정상적으로 ${data}되었습니다`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-pale-grey px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <ModalBtn type="button" onClick={handleConfirmBtn}>
                    확인
                  </ModalBtn>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmModal;
