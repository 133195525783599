import React, { useState } from "react";
import LogBox from "./LogBox";
import { useQuery } from "react-query";
import { dashboardApi } from "../../../../api/DashboardApi";

const LogStatusSection = () => {
  const [apiData, setApiData] = useState({});
  const { isFetching, isLoading } = useQuery(
    "logStatus",
    () => dashboardApi.logStatus(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log("logStatus", data.data);
        setApiData(data.data);
      },
    }
  );
  const tableData = Object.entries(apiData);

  return (
    <div className="box w-1/2">
      <div className="boxTitle">로그 현황</div>
      <div className="flex justify-center boxContent">
        {isLoading || isFetching ? (
          <div className="h-[150px] loading">데이터를 불러오는 중입니다</div>
        ) : (
          tableData.map(([key, value]) => (
            <LogBox key={key} logTitle={key} logData={value} />
          ))
        )}
      </div>
    </div>
  );
};

export default LogStatusSection;
