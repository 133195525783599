import React from "react";
import { Controller, useForm } from "react-hook-form";
import { setAutoDrivingDataSearch } from "../../../store/features/autoDrivingData/autoDrivingDataSlice";
import { useDispatch } from "react-redux";
import AutoComplete from "./search/AutoComplete";
import SearchBtn from "../../../components/button/SearchBtn";
import Calendar from "../../../utils/calendar/Calendar";
import { format } from "date-fns";
/*
    날짜는 폼 형식으로 상태를 업데이트하고 자동완성은 별도로(AutoComplete) 상태 업데이트한다
*/
const SearchForm = () => {
  const { control, handleSubmit } = useForm();

  const dispatch = useDispatch();
  const today = new Date();

  const onValid = (data) => {
    data.startDate = format(new Date(data?.startDate || today), "yyyy-MM-dd");
    data.endDate = format(new Date(data?.endDate || today), "yyyy-MM-dd");
    console.log("autoDrivingData data", data);
    dispatch(setAutoDrivingDataSearch({ search: data }));
  };

  return (
    <form
      className="sideBarContainer space-y-[10px]"
      onSubmit={handleSubmit(onValid)}
    >
      <div className="calWrapper">
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <Calendar field={field} isStart={true} inputLen={"w-52"} />
          )}
        />
        <hr className="w-[10px] bg-silver" />
        <Controller
          name="endDate"
          control={control}
          render={({ field }) => <Calendar field={field} inputLen={"w-52"} />}
        />
      </div>
      <div className="grid grid-cols-2 gap-[10px] justify-center">
        <AutoComplete inputType={"startCrossway"} />
        <AutoComplete inputType={"endCrossway"} />
      </div>
      <SearchBtn>검색</SearchBtn>
    </form>
  );
};

export default SearchForm;
