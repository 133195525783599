import React, { useEffect, useState } from "react";
import KakaoMap from "../../../../components/map/KakaoMap";
import { useQuery } from "react-query";
import { drivingApi } from "../../../../api/SearchApi";
import { useDispatch, useSelector } from "react-redux";
import { resetAutoDrivingSearch } from "../../../../store/features/autoDrivingData/autoDrivingDataSlice";

const AutoDrivingMap = () => {
  const [coordinateData, setCoordinateData] = useState([]);
  const [arrowDataContent, setArrowDataContent] = useState([]);
  const dispatch = useDispatch();
  const search = useSelector((state) => state.autoDrivingData);
  const [apiData, setApiData] = useState({});
  const selectedRoute = useSelector(
    (state) => state.autoDrivingData.selectedPath
  );
  useEffect(() => {
    dispatch(resetAutoDrivingSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("apiData", apiData);
    console.log(
      "Object.keys(apiData).length !== 0",
      Object.keys(apiData).length !== 0
    );
    if (Object.keys(apiData).length !== 0) {
      console.log("apiData", apiData);
      const origin = apiData.items[selectedRoute - 1].points.filter(
        (item) => item.type === "origin"
      )[0];
      const destination = apiData.items[selectedRoute - 1].points.filter(
        (item) => item.type === "destination"
      )[0];

      setCoordinateData([
        {
          crossway: origin?.name,
          lng: origin?.point?.x,
          lat: origin?.point?.y,
        },
        {
          crossway: destination?.name,
          lng: destination?.point?.x,
          lat: destination?.point?.y,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  /**
   * 범례에 따른 화살표 색상 변경
   * @param queue 대기 행렬
   * @returns {string}  색상
   */
  const arrowColor = (queue) => {
    let res = "";
    if (queue >= 0 && queue < 10) {
      res = "#1fc55d";
    } else if (queue >= 10 && queue < 20) {
      res = "#fde047";
    } else if (queue >= 20 && queue < 30) {
      res = "#f97315";
    } else if (queue >= 30) {
      res = "#ee4444";
    }
    return res;
  };

  const { isFetching, isLoading } = useQuery(
    ["drivingArrow", search],
    () => drivingApi.drivingArrow(search),
    {
      enabled: !!selectedRoute || coordinateData.length === 0,
      onSuccess: (data) => {
        console.log("AutoDrivingMap onSuccess 넘어온 데이터 값", data.data);
        setApiData(data.data);
        setArrowDataContent(
          data.data.items
            .map((item, index) =>
              item.path.map((i, idx) => ({
                route: index + 1,
                arrowName: `route${index + 1}-${idx + 1}`,
                points: [...i.points],
                // type: "arrow",
                coordinate: "wgs84",
                options: {
                  strokeColor: arrowColor(i.queue),
                  strokeWeight: 8,
                  strokeStyle: "solid",
                  strokeOpacity: 1,
                  // endArrow: true,
                },
              }))
            )
            .flat(1)
        );
      },
    }
  );

  return (
    <>
      {coordinateData.length > 0 &&
      !isLoading &&
      !isFetching &&
      arrowDataContent.length > 0 ? (
        <KakaoMap
          arrowData={{ arrow: arrowDataContent }}
          coordinates={coordinateData}
          center={{
            lat: 37.350126,
            lng: 126.73112,
          }}
        />
      ) : (
        <div className="flex justify-center items-center grow w-screen">
          <p className="loading">데이터를 불러오는 중입니다</p>
        </div>
      )}
    </>
  );
};

export default React.memo(AutoDrivingMap);
