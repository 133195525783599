import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Pagination from "../../components/table/Pagination";
import ExcelDownload from "../../utils/ExcelDownload";
import Calendar from "../../utils/calendar/Calendar";
import SearchBtn from "../../components/button/SearchBtn";
import {
  clickExcelBtn,
  resetExcelBtn,
} from "../../store/features/common/excelSlice";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import AccountTable from "../../components/table/AccountTable";
import { sendingDataListData } from "./data/logContentData/sendingDataListData";
import { resetPageNum } from "../../store/features/common/paginationSlice";

const SendingDataContent = () => {
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const today = new Date();
  const [search, setSearch] = useState({});
  const startDate = useSelector((state) => state.calendar.startDate);
  console.log(startDate, Number(startDate.slice(8, 10)));
  const formattedApiData = sendingDataListData(startDate).map((item) => ({
    No: item.no,
    일시: item.date,
    TCP수신:
      Number(startDate.slice(8, 10)) + item.no === 23
        ? item.origin + 1
        : item.origin,
    "시뮬레이션 송신":
      Number(startDate.slice(8, 10)) + item.no === 27
        ? item.waypoint1 - 1
        : item.waypoint1,
    "시뮬레이션 수신":
      Number(startDate.slice(8, 10)) % 11 === 0 && item.no === 2
        ? item.waypoint2 + 1
        : item.waypoint2,
    "TCP 송신":
      Number(startDate.slice(8, 10)) % 9 === 0 && item.no === 6
        ? item.destination + 1
        : item.destination,
  }));
  useEffect(() => {
    dispatch(resetPageNum()); // 페이지 값 리셋
    dispatch(resetExcelBtn()); // 엑셀 출력 여부 리셋
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onValid = (data) => {
    console.log(data);
    setSearch(data);
  };
  return (
    <div className="bigBox ">
      <form
        className="flex mt-[20px] pb-[20px]"
        onSubmit={handleSubmit(onValid)}
      >
        <div className="calWrapper">
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <Calendar field={field} inputLen={"!w-[150px]"} isStart={true} />
            )}
          />
          <hr className="w-[10px] bg-silver" />
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <Calendar inputLen={"!w-[150px]"} field={field} />
            )}
          />
        </div>
        <SearchBtn className="!w-[120px] ml-3">검색</SearchBtn>
      </form>
      <div className="pb-3">
        <AccountTable data={formattedApiData} />
      </div>
      <div className="flex justify-between">
        <ExcelDownload
          onClick={() => dispatch(clickExcelBtn({ clicked: true }))}
          data={formattedApiData}
          filename={"데이터 송수신 기록"}
          status={{
            isExcelSuccess: true,
            isExcelFetching: false,
            isExcelLoading: false,
          }}
          date={`${search?.startDate || format(today, "yyyy-MM-dd")}_${
            search?.endDate || format(today, "yyyy-MM-dd")
          }`}
        />
      </div>
      <Pagination totalNum={100} />
    </div>
  );
};

export default SendingDataContent;
