import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PreventEnterDefault } from "../../../hoc/CommonHooks";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { showChangeCoModal } from "../../../store/features/account/changeCoSlice";
import SuperAccountupdateCoModal from "./SuperAccountupdateCoModal";
import {
  showConfirmModal,
  showDoubleCheckModal,
} from "../../../store/features/common/modalSlice";
import DoubleCheckModal from "../../../components/modal/DoubleCheckModal";
import { ReactComponent as IcOffice } from "../../../asset/icon/ic_office.svg";
import SearchBtn from "../../../components/button/SearchBtn";
import { useQuery } from "react-query";
import { superApi } from "../../../api/AccountApi";
import Pagination from "../../../components/table/Pagination";
import { resetPageNum } from "../../../store/features/common/paginationSlice";

const SuperAccountCoContent = () => {
  const { handleSubmit, register, reset } = useForm();
  const dispatch = useDispatch();
  const [info, setInfo] = useState(""); // 이중 확인 및 완료 모달에 표시될 문구 (삭제, 수정)
  const page_no = useSelector((state) => state.pagination.pageNum.pageNum);
  const [totalNum, setTotalNum] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [coData, setCoData] = useState({});
  const [newCoData, setNewCoData] = useState("");
  const [delNo, setDelNo] = useState("");
  const finalCheck = useSelector((state) => state.confirmModal.finalCheck);
  // console.log("page_no", page_no);
  useEffect(() => {
    setDelNo("");
    dispatch(resetPageNum()); // 페이지 값 리셋
    setNewCoData("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isFetching, isLoading, isSuccess, refetch } = useQuery(
    ["superCos", page_no],
    () => superApi.superCos(page_no),
    {
      onSuccess: (data) => {
        // console.log("superCos data", data.data);
        setTotalNum(data.data.totalNum);
        setApiData(data.data.items);
      },
    }
  );

  useQuery(["addCo", newCoData], () => superApi.addCo(newCoData), {
    enabled: !!newCoData,
    onSuccess: (data) => {
      console.log("addCo data", data.data);
      setNewCoData("");
      dispatch(showConfirmModal({ show: true })); // 회사 변경 모달 상태 변경
      refetch();
    },
  });

  useQuery(["delCo", delNo], () => superApi.delCo(delNo), {
    enabled: !!delNo && finalCheck,
    onSuccess: (delCoData) => {
      console.log("superDelUserData data", delCoData.data);
      if (delCoData.code === 200) {
        setDelNo("");
        refetch();
      }
    },
  });

  const onValid = (data) => {
    setInfo("추가"); // 완료 모달 문구 설정
    console.log("SuperAccountCoContent data", data);
    setNewCoData(data.newCompany);
    reset();
  };

  /**
   * 삭제 버튼 선택 시, 이중 확인 모달을 표시하는 함수
   */
  const handleDelBtn = (coNum) => {
    setDelNo(coNum);
    setInfo("삭제"); // 완료 모달 문구 설정
    dispatch(showDoubleCheckModal({ doubleCheck: true })); // 이중 확인 모달 상태 변경
  };

  /**
   * 수정 버튼 선택 시, 회사 변경 모달을 표시하는 함수
   */
  const handleUpdateBtn = (coNum, coName) => {
    console.log("handleUpdateBtn", coNum, coName);
    setCoData({ no: coNum, name: coName });
    setInfo("수정"); // 완료 모달 문구 설정
    dispatch(showChangeCoModal({ show: true })); // 회사 변경 모달 상태 변경
  };

  return (
    <>
      <PreventEnterDefault />
      <DoubleCheckModal data={info} />
      <ConfirmModal data={info} />
      <SuperAccountupdateCoModal data={"회사명"} coData={coData} />
      <div className="grow bigBox">
        <form
          className="flex flex-col justify-between pb-4 space-y-3"
          onSubmit={handleSubmit(onValid)}
        >
          <div className="flex justify-between">
            <div className="flex items-center pageInput mr-[10px] text-right">
              <IcOffice />
              <input
                className="bg-transparent appearance-none focus:outline-none w-11/12 h-full ml-[16px] textfield"
                placeholder="회사명"
                {...register("newCompany")}
              ></input>
            </div>
            <SearchBtn className="!w-[160px]" type="submit">
              회사 추가
            </SearchBtn>
          </div>
        </form>
        {isSuccess && !isFetching && !isLoading ? (
          apiData.length > 0 ? (
            <table className="w-full my-[40px]">
              <thead>
                <tr className="h-[50px] bg-pale-grey">
                  <th className="border-l h-[50px] bg-pale-grey">No</th>
                  <th className="h-[50px] bg-pale-grey">회사명</th>
                  <th className="border-r h-[50px] bg-pale-grey">관리</th>
                </tr>
              </thead>
              <tbody>
                {apiData.map((item) => (
                  <tr>
                    <td className="textfield border-l border-b h-[50px] text-center">
                      {item.no}
                    </td>
                    <td className="textfield border-b w-9/12 h-[50px] text-center">
                      {item.company}
                    </td>
                    <td className="flex justify-center items-center border-b h-[50px] border-r">
                      <SearchBtn
                        className="textfield !h-[40px] !w-[80px] mr-[10px]"
                        type="button"
                        onClick={() => handleDelBtn(item.no)}
                      >
                        삭제
                      </SearchBtn>
                      <SearchBtn
                        className="textfield !h-[40px] !w-[80px]"
                        type="button"
                        onClick={() => handleUpdateBtn(item.no, item.company)}
                      >
                        수정
                      </SearchBtn>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="felx grow loading">데이터가 없습니다</div>
          )
        ) : (
          <div className="felx grow loading">데이터를 가져오는 중입니다</div>
        )}

        <Pagination totalNum={totalNum} />
      </div>
    </>
  );
};

export default SuperAccountCoContent;
