import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SearchBtn from "../../../components/button/SearchBtn";
import Calendar from "../../../utils/calendar/Calendar";
import Options from "../../../components/select/Options";
import { SelectOptions } from "../../../constants/Constants";
import {
  setCrossway,
  setDong,
  setSearch,
} from "../../../store/features/search/searchSlice";
import { format } from "date-fns";

const SearchForm = () => {
  const { control, handleSubmit, register, reset } = useForm();
  const dispatch = useDispatch();
  const search = useSelector((state) => state.searchData.search);
  const startDate = useSelector((state) => state.calendar.startDate); // 종료 날짜를 정하지 않았을 경우 api로 전달할 시작 날짜 값
  const today = new Date();
  useEffect(() => {
    let defaultValues = {};
    defaultValues.dong = search.dong;
    defaultValues.crossway = search.crossway;
    reset({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValid = (data) => {
    data.startDate = format(new Date(startDate || today), "yyyy-MM-dd");
    data.endDate = format(
      new Date(data?.endDate || startDate || today),
      "yyyy-MM-dd"
    );
    console.log("signalResult Sidebar data", data);
    dispatch(setSearch({ search: data }));
  };
  /**
   * 동 선택 시 교차로 변환 함수
   * @param e onChange 이벤트 객체
   */
  const handleChangeDistrict = (e) => dispatch(setDong(e.target.value));
  /**
   * 사거리 선택 시 상태 등록 함수
   * @param e onChange 이벤트 객체
   */
  const handleChangeCrossway = (e) => dispatch(setCrossway(e.target.value));
  return (
    <form
      className="sideBarContainer  space-y-[10px]"
      onSubmit={handleSubmit(onValid)}
    >
      <div className="calWrapper">
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <Calendar field={field} isStart={true} inputLen={"w-52"} />
          )}
        />
        <hr className="w-[10px] bg-silver" />
        <Controller
          name="endDate"
          control={control}
          render={({ field }) => <Calendar field={field} inputLen={"w-52"} />}
        />
      </div>
      <div className="grid grid-cols-2 gap-[10px] justify-center">
        <select
          className="sideBarSelectBox"
          {...register(SelectOptions.DONG)}
          onChange={handleChangeDistrict}
          value={search.dong}
        >
          <Options type={SelectOptions.DONG} />
        </select>
        <select
          className="sideBarSelectBox"
          {...register(SelectOptions.CROSSWAY)}
          onChange={handleChangeCrossway}
        >
          <Options type={SelectOptions.CROSSWAYS} dong={search.dong} />
        </select>
      </div>
      <SearchBtn>검색</SearchBtn>
    </form>
  );
};

export default SearchForm;
