import React from "react";
import SignalSidebar from "../../contents/check/SignalSidebar";
import CheckSignalMap from "../../contents/check/section/map/CheckSignalMap";

const CheckPass = () => {
  return (
    <>
      <SignalSidebar />
      <CheckSignalMap />
    </>
  );
};

export default CheckPass;
