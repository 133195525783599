import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PreventEnterDefault } from "../../../hoc/CommonHooks";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import {
  showConfirmModal,
  showDoubleCheckModal,
} from "../../../store/features/common/modalSlice";
import SearchBtn from "../../../components/button/SearchBtn";
import DoubleCheckModal from "../../../components/modal/DoubleCheckModal";
import { useQuery } from "react-query";
import { adminApi, commonAccountApi } from "../../../api/AccountApi";
import AccountOptions from "../../../components/select/AccountOptions";
import clsx from "clsx";
/*
  URL을 비교하여 수정 페이지인지 상세 페이지인지 구분한다
*/
const AdminAccountDetailContent = () => {
  const location = useLocation();
  const isDetailMode = location.pathname.includes("/admin/detail");
  const params = useParams("No");
  const [info, setInfo] = useState(""); // 완료 모달에 표시될 문구 (수정, 초기화)
  const [paramData, setParamData] = useState({});
  const [userDetail, setUserDetailData] = useState({});
  const [isUpdatePass, setIsUpdatePass] = useState(false);
  const [updateState, setUpdateState] = useState({});
  const [delNo, setDelNo] = useState("");
  const [stateVal, setStateVal] = useState("");
  const finalCheck = useSelector((state) => state.confirmModal.finalCheck);
  const [idCheck, setIdCheck] = useState(false); // 중복검사를 했는지 안했는지 구분
  const [idCheckRes, setIdCheckRes] = useState(false); // 아이디 결과 값 저장
  const [idInputVal, setIdInputVal] = useState(""); // 아이디 저장
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    setFocus,
    setError,
    clearErrors,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDelNo("");
    setUpdateState({});
    setParamData({});
    setIsUpdatePass(false);
  }, []);
  const watchPhone1 = watch("phone1");
  const watchPhone2 = watch("phone2");
  const watchId = watch("id");
  useEffect(() => {
    console.log("watchPhone1", watchPhone1);
    console.log("watchPhone2", watchPhone2);
    if (watchPhone1?.length === 3) setFocus("phone2");
    if (watchPhone2?.length === 4) setFocus("phone3");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPhone1, watchPhone2]);

  useEffect(() => {
    console.log("watchId", watchId);
    if (watchId?.length >= 0) {
      setIdInputVal(watchId);
      setIdCheck(false);
      setIdCheckRes(false);
      clearErrors("id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchId]);

  useQuery(["adminAdd", paramData], () => adminApi.adminAdd(paramData), {
    enabled: !!paramData.id,
    onSuccess: (userAddData) => {
      if (userAddData.code === 200) {
        dispatch(showConfirmModal({ show: true })); // 완료 모달 상태 변경
        setParamData({});
        reset();
      } else {
      }
      console.log("userAddData data", userAddData);
      setParamData({});
      setIdInputVal("");
      reset();
    },
  });

  useQuery(["adminDetail", params], () => adminApi.adminDetail(params), {
    enabled: !!isDetailMode,
    onSuccess: (userDetailData) => {
      console.log("adminDetail data", userDetailData.data);
      setUserDetailData(userDetailData.data);
      console.log("userDetailData.data.state", userDetailData.data.state);
      setStateVal(userDetailData.data.state);
    },
  });

  useQuery(
    ["adminPassUpdate", params],
    () => adminApi.adminPassUpdate(params),
    {
      enabled: isUpdatePass,
      onSuccess: (adminPassUpdateData) => {
        console.log("adminPassUpdateData data", adminPassUpdateData.data);
        if (adminPassUpdateData.code === 200)
          dispatch(showConfirmModal({ show: true }));
        setIsUpdatePass(false);
      },
    }
  );

  useQuery(
    ["adminStateUpdate", updateState],
    () => adminApi.adminStateUpdate(updateState),
    {
      enabled: !!updateState.state,
      onSuccess: (adminStateUpdateData) => {
        console.log("adminStateUpdateData data", adminStateUpdateData.data);
        if (adminStateUpdateData.code === 200)
          dispatch(showConfirmModal({ show: true }));
        setUpdateState({});
      },
    }
  );
  useQuery(["adminDelUser", delNo], () => adminApi.adminDelUser(delNo), {
    enabled: !!delNo && finalCheck,
    onSuccess: (adminDelUserData) => {
      console.log("adminStateUpdateData data", adminDelUserData.data);
      if (adminDelUserData.code === 200) setDelNo("");
    },
  });

  useQuery(
    ["checkDuplicateId", idInputVal],
    () => commonAccountApi.checkDuplicateId(idInputVal),
    {
      enabled: idCheck,
      onSuccess: (checkDuplicateIdData) => {
        console.log(
          "checkDuplicateIdData data",
          checkDuplicateIdData.data.isDuplicate
        );
        setIdCheckRes(!checkDuplicateIdData.data.isDuplicate);
        setError(
          "id",
          {
            message: !checkDuplicateIdData.data.isDuplicate
              ? "사용 가능"
              : "사용 불가",
          },
          { shouldFocus: true }
        );
      },
    }
  );

  const onValid = (data) => {
    setIdCheck(false);
    setInfo(isDetailMode ? "수정" : "등록");
    console.log("AdminAccountDetailContent data", data);
    isDetailMode
      ? setUpdateState({ No: params.No, state: data.state })
      : idCheckRes
      ? setParamData({
          name: data.name,
          id: data.id,
          phone: data.phone1 + data.phone2 + data.phone3,
        })
      : setError(
          "id",
          {
            message: "아이디 중복검사를 해주세요",
          },
          { shouldFocus: true }
        );
  };
  const handleDelBtn = () => {
    setDelNo(params.No);
    setInfo("삭제"); // 완료 모달 문구 변경
    dispatch(showDoubleCheckModal({ doubleCheck: true })); // 이중 확인 모달 상태 변경
  };

  const handleChangePass = () => {
    setIsUpdatePass(true);
    setInfo("초기화");
  };
  const handleCheckId = () => {
    idInputVal.length > 0
      ? setIdCheck(true)
      : setError(
          "id",
          {
            message: "아이디를 입력하세요",
          },
          { shouldFocus: true }
        );
  };
  return (
    <>
      <DoubleCheckModal data={info} />
      <ConfirmModal data={info} />
      <div className="mt-[60px] box">
        <PreventEnterDefault />
        <form className="pt-2" onSubmit={handleSubmit(onValid)}>
          <div className="boxTitle">사용자 계정 관리</div>
          <div className="boxContent grow flex flex-col items-center w-full p-[30px] ">
            <div className="grid grid-cols-4 items-center w-[600px] h-[225px]">
              <div className="title1">아이디</div>
              <div className="textfield flex items-center col-span-3">
                {isDetailMode ? (
                  userDetail?.id
                ) : (
                  <>
                    <input
                      className="accountInput"
                      type="text"
                      maxLength={10}
                      {...register("id", {
                        required: "아이디를 입력하세요",
                        // pattern: {
                        //   value: /^[A-Za-z0-9]{4,12}$/,
                        //   message: "아이디는 영문과 숫자로 조합된 4~12자",
                        // },
                      })}
                    />
                    <SearchBtn
                      type="button"
                      className=" textfield !h-[36px] !w-[120px] ml-4"
                      onClick={handleCheckId}
                    >
                      중복검사
                    </SearchBtn>
                  </>
                )}
                <span
                  className={clsx("ml-3", {
                    "text-dark-pink": !idCheckRes,
                    "text-purpleish-blue": idCheckRes,
                  })}
                >
                  {errors.id && errors.id.message}
                </span>
              </div>

              <div className="title1">이름</div>
              <div className="col-span-3 textfield items-center">
                {isDetailMode ? (
                  userDetail?.name
                ) : (
                  <input
                    className="accountInput"
                    type="text"
                    maxLength={10}
                    {...register("name", { required: "이름을 입력하세요" })}
                  />
                )}
                <span className="text-dark-pink ml-3">
                  {errors.name && errors.name.message}
                </span>
              </div>

              <div className="title1">연락처</div>
              <div className="col-span-3 textfield">
                {isDetailMode ? (
                  userDetail?.phoneNum
                ) : (
                  <>
                    <div className="flex items-center">
                      <input
                        className="accountInput"
                        type="text"
                        maxLength="3"
                        {...register("phone1", {
                          required: "연락처를 올바르게 입력하세요",
                          pattern: {
                            value: /^\d+$/,
                            message: "숫자만 입력하세요",
                          },
                        })}
                      />
                      <span className="px-5"> - </span>
                      <input
                        className="accountInput"
                        type="text"
                        maxLength="4"
                        {...register("phone2", {
                          required: "연락처를 올바르게 입력하세요",
                          pattern: {
                            value: /^\d+$/,
                            message: "숫자만 입력하세요",
                          },
                        })}
                      />
                      <span className="px-5"> - </span>
                      <input
                        className="accountInput"
                        type="text"
                        maxLength="4"
                        {...register("phone3", {
                          required: "연락처를 올바르게 입력하세요",
                          pattern: {
                            value: /^\d+$/,
                            message: "숫자만 입력하세요",
                          },
                        })}
                      />
                    </div>
                  </>
                )}
                <p className="absolute text-dark-pink pt-3">
                  {(errors.phone1 && errors.phone1.message) ||
                    (errors.phone2 && errors.phone2.message) ||
                    (errors.phone3 && errors.phone3.message)}
                </p>
              </div>
            </div>
            {isDetailMode && (
              <div className="grid grid-cols-4 w-[600px] h-[130px] items-center">
                <div className="title1">상태</div>
                <div className="col-span-3">
                  <select
                    className="accountSelectBox"
                    {...register("state")}
                    value={stateVal}
                    onChange={(e) => {
                      setStateVal(e.target.value);
                    }}
                  >
                    <AccountOptions type={"detailState"} />
                  </select>
                </div>
                <div className="title1">등록 일시</div>
                <div className="col-span-3 textfield items-center">
                  <span>{userDetail?.regDate}</span>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between mt-[30px]">
            {isDetailMode ? (
              <div className="flex">
                <button
                  className="accountBtn !h-[50px] mr-[10px]"
                  type="button"
                  onClick={handleDelBtn}
                >
                  삭제
                </button>
                <SearchBtn className="!w-[120px]  mr-[10px]" type="submit">
                  수정
                </SearchBtn>
                <SearchBtn
                  className="!w-[130px]"
                  type="button"
                  onClick={handleChangePass}
                >
                  비밀번호 초기화
                </SearchBtn>
              </div>
            ) : (
              <div className="flex">
                <SearchBtn
                  className="!w-[120px]  mr-[10px]"
                  onClick={() => navigate("/admin-account")}
                >
                  취소
                </SearchBtn>
                <SearchBtn className="!w-[120px]  mr-[10px]" type="submit">
                  등록
                </SearchBtn>
              </div>
            )}

            <SearchBtn
              className="!w-[120px]"
              onClick={() => navigate("/admin")}
            >
              목록
            </SearchBtn>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminAccountDetailContent;
