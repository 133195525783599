import DailyTrafficSection from "../check/section/rotationTrafficSidebar/DailyTrafficSection";
import HourlyTrafficSection from "../check/section/rotationTrafficSidebar/HourlyTrafficSection";

import React from "react";
import RotationRatio from "./section/rotationTrafficSidebar/RotationRatio";
import SearchForm from "./section/SearchForm";

// import SearchForm from "./section/rotationTrafficSidebar/SearchForm";

const TrafficSidebar = () => {
  return (
    <div className=" sideBarWrapper overflow-y-scroll">
      <div className="sideBarContainer mt-[230px]">
        {/*검색 창*/}
        <SearchForm />
        {/*일자별 통과 교통량*/}
        <DailyTrafficSection />
        {/*시간별 통과 교통량*/}
        <HourlyTrafficSection />
        <RotationRatio />
      </div>
    </div>
  );
};

export default TrafficSidebar;
