import React, { useEffect, useState } from "react";
import BarChart from "../../../../components/chart/BarChart";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { passApi } from "../../../../api/SearchApi";

const HourlyTrafficSection = () => {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    // console.log("chartData", chartData);
  }, [chartData]);
  const search = useSelector((state) => state.searchData.search);
  const { isFetching, isLoading, isSuccess } = useQuery(
    ["passHourlyChart", search],
    () => passApi.passHourlyChart(search),
    {
      onSuccess: (data) => {
        console.log("passHourlyChart data", data.data);
        setChartData([
          { name: "주중", data: data.data.weekItems },
          { name: "주말", data: data.data.weekendItems },
        ]);
      },
    }
  );
  return (
    <div className="sideBarContent">
      {isSuccess && !isLoading && !isFetching && chartData.length > 0 ? (
        <BarChart data={chartData} height={250} title={"시간별 통과 교통량"} />
      ) : (
        <div className="loading h-[200px]">데이터를 불러오는 중입니다</div>
      )}

      {/*<TblExcelDownload*/}
      {/*  data={dummyData[0].data}*/}
      {/*  filename={"시간별 통과 교통량"}*/}
      {/*/>*/}
    </div>
  );
};

export default HourlyTrafficSection;
