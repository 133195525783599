import React, { useEffect, useState } from "react";
import Table from "../../../../components/table/Table";
import TblExcelDownload from "../../../../utils/ExcelDownload";
import { ReactComponent as Img1 } from "../../../../asset/image/before/ic_sign_a.svg";
import { ReactComponent as Img2 } from "../../../../asset/image/before/ic_sign_b.svg";
import { ReactComponent as Img3 } from "../../../../asset/image/after/ic_sign_f.svg";
import { ReactComponent as Img4 } from "../../../../asset/image/after/ic_sign_g.svg";
import { format } from "date-fns";
import {
  clickExcelBtn,
  resetExcelBtn,
} from "../../../../store/features/common/excelSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { signalApi } from "../../../../api/SearchApi";

const GreenTimeSection = () => {
  const dispatch = useDispatch();
  const today = new Date();
  const search = useSelector((state) => state.searchData.search);
  useEffect(() => {
    dispatch(resetExcelBtn()); // 엑셀 출력 여부 리셋
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [greenTime, setGreenTime] = useState([]);

  useQuery(["display", search], () => signalApi.display(search), {
    onSuccess: (data) => {
      console.log("display data", data.data.items);
    },
  });
  const {
    isSuccess: isGreenSuccess,
    isFetching: isGreenFetching,
    isLoading: isGreenLoading,
  } = useQuery(["greenTime", search], () => signalApi.greenTime(search), {
    onSuccess: (data) => {
      console.log("greenTime data", data.data.items);
      setGreenTime(
        data.data.items.map((item) => ({
          일자: item.date,
          변동시간: item.variationTime,
          유효녹색시간1: item.effectiveTime[0],
          유효녹색시간2: item.effectiveTime[1],
          유효녹색시간3: item.effectiveTime[2],
          유효녹색시간4: item.effectiveTime[3],
        }))
      );
    },
  });

  return (
    <div className="sideBarContent">
      <div className="w-full title1">현시별 유효 녹색 시간</div>
      <div className="w-full grow h-[620px] overflow-y-auto mt-3 mb-3">
        <table className="w-full">
          <tbody>
            <tr className="h-[50px] border bg-pale-grey">
              <td className="col-span-2 text-center font-bold w-[124px]">
                현시
              </td>
              {/*{display.map((item) => (*/}
              {/*  <td className="text-center border font-bold w-[62px]">*/}
              {/*    {item}*/}
              {/*  </td>*/}
              {/*))}*/}
              <td className="text-center border font-bold w-[62px]">
                <Img1 className="w-[58px]" />
              </td>
              <td className="text-center border font-bold w-[62px]">
                <Img2 className="w-[58px]" />
              </td>
              <td className="text-center border font-bold w-[62px]">
                <Img3 className="w-[58px]" />
              </td>
              <td className="text-center border font-bold w-[62px]">
                <Img4 className="w-[58px]" />
              </td>
            </tr>
          </tbody>
        </table>
        {isGreenSuccess && !isGreenFetching && !isGreenLoading ? (
          <Table data={greenTime} />
        ) : (
          <div className="grow loading h-full justify-center items-center">
            데이터를 불러오는 중입니다
          </div>
        )}
      </div>
      <TblExcelDownload
        onClick={() => dispatch(clickExcelBtn({ clicked: true }))}
        data={greenTime}
        filename={"현시별 유효 녹색 시간"}
        status={{
          isExcelSuccess: isGreenSuccess,
          isExcelFetching: isGreenFetching,
          isExcelLoading: isGreenLoading,
        }}
        date={`${search?.startDate || format(today, "yyyy-MM-dd")}_${
          search?.endDate || format(today, "yyyy-MM-dd")
        }`}
      />
    </div>
  );
};

export default GreenTimeSection;
