import React from "react";
import SuperAccountCoContent from "../../../contents/account/superAdmin/SuperAccountCoContent";

const AdminAccount = () => {
  return (
    <div className="content">
      <SuperAccountCoContent />
    </div>
  );
};

export default AdminAccount;
