export const sectionTrafficInfoContent = (createTime) => [
  {
    receiveTime: "00:01",
    createTimeStamp: `${createTime}:01`,
    linkId: 1000000301,
    volume: 74,
    avgTravelTime: 14,
    sectionTime: 29,
    downTime: 8,
    downRatio: 19,
    downCount: 12,
    avgQueue: 15,
    maxQueue: 20,
  },
  {
    receiveTime: "00:02",
    createTimeStamp: `${createTime}:02`,
    linkId: 1000000302,
    volume: 66,
    avgTravelTime: 25,
    sectionTime: 20,
    downTime: 13,
    downRatio: 9,
    downCount: 8,
    avgQueue: 19,
    maxQueue: 19,
  },
  {
    receiveTime: "00:03",
    createTimeStamp: `${createTime}:03`,
    linkId: 1000000303,
    volume: 51,
    avgTravelTime: 13,
    sectionTime: 27,
    downTime: 13,
    downRatio: 6,
    downCount: 11,
    avgQueue: 5,
    maxQueue: 7,
  },
  {
    receiveTime: "00:04",
    createTimeStamp: `${createTime}:04`,
    linkId: 1000000304,
    volume: 135,
    avgTravelTime: 20,
    sectionTime: 12,
    downTime: 10,
    downRatio: 14,
    downCount: 6,
    avgQueue: 6,
    maxQueue: 8,
  },
  {
    receiveTime: "00:05",
    createTimeStamp: `${createTime}:05`,
    linkId: 1000000305,
    volume: 123,
    avgTravelTime: 10,
    sectionTime: 26,
    downTime: 14,
    downRatio: 20,
    downCount: 9,
    avgQueue: 12,
    maxQueue: 5,
  },
  {
    receiveTime: "00:06",
    createTimeStamp: `${createTime}:06`,
    linkId: 1000000306,
    volume: 146,
    avgTravelTime: 20,
    sectionTime: 13,
    downTime: 12,
    downRatio: 8,
    downCount: 14,
    avgQueue: 10,
    maxQueue: 10,
  },
  {
    receiveTime: "00:07",
    createTimeStamp: `${createTime}:07`,
    linkId: 1000000307,
    volume: 105,
    avgTravelTime: 26,
    sectionTime: 24,
    downTime: 11,
    downRatio: 14,
    downCount: 7,
    avgQueue: 18,
    maxQueue: 18,
  },
  {
    receiveTime: "00:08",
    createTimeStamp: `${createTime}:08`,
    linkId: 1000000308,
    volume: 136,
    avgTravelTime: 30,
    sectionTime: 18,
    downTime: 12,
    downRatio: 15,
    downCount: 12,
    avgQueue: 15,
    maxQueue: 17,
  },
  {
    receiveTime: "00:09",
    createTimeStamp: `${createTime}:09`,
    linkId: 1000000309,
    volume: 72,
    avgTravelTime: 22,
    sectionTime: 28,
    downTime: 14,
    downRatio: 5,
    downCount: 3,
    avgQueue: 14,
    maxQueue: 13,
  },
  {
    receiveTime: "00:10",
    createTimeStamp: `${createTime}:10`,
    linkId: 1000000310,
    volume: 101,
    avgTravelTime: 11,
    sectionTime: 18,
    downTime: 8,
    downRatio: 11,
    downCount: 9,
    avgQueue: 5,
    maxQueue: 18,
  },
  {
    receiveTime: "00:11",
    createTimeStamp: `${createTime}:11`,
    linkId: 1000000311,
    volume: 83,
    avgTravelTime: 19,
    sectionTime: 29,
    downTime: 5,
    downRatio: 16,
    downCount: 4,
    avgQueue: 5,
    maxQueue: 18,
  },
  {
    receiveTime: "00:12",
    createTimeStamp: `${createTime}:12`,
    linkId: 1000000312,
    volume: 61,
    avgTravelTime: 17,
    sectionTime: 26,
    downTime: 15,
    downRatio: 13,
    downCount: 14,
    avgQueue: 6,
    maxQueue: 17,
  },
  {
    receiveTime: "00:13",
    createTimeStamp: `${createTime}:13`,
    linkId: 1000000313,
    volume: 140,
    avgTravelTime: 27,
    sectionTime: 13,
    downTime: 7,
    downRatio: 10,
    downCount: 19,
    avgQueue: 13,
    maxQueue: 14,
  },
  {
    receiveTime: "00:14",
    createTimeStamp: `${createTime}:14`,
    linkId: 1000000314,
    volume: 75,
    avgTravelTime: 29,
    sectionTime: 27,
    downTime: 14,
    downRatio: 12,
    downCount: 11,
    avgQueue: 9,
    maxQueue: 18,
  },
  {
    receiveTime: "00:15",
    createTimeStamp: `${createTime}:15`,
    linkId: 1000000315,
    volume: 66,
    avgTravelTime: 26,
    sectionTime: 20,
    downTime: 10,
    downRatio: 16,
    downCount: 11,
    avgQueue: 5,
    maxQueue: 6,
  },
  {
    receiveTime: "00:16",
    createTimeStamp: `${createTime}:16`,
    linkId: 1000000316,
    volume: 56,
    avgTravelTime: 17,
    sectionTime: 13,
    downTime: 15,
    downRatio: 6,
    downCount: 10,
    avgQueue: 10,
    maxQueue: 13,
  },
  {
    receiveTime: "00:17",
    createTimeStamp: `${createTime}:17`,
    linkId: 1000000317,
    volume: 83,
    avgTravelTime: 22,
    sectionTime: 26,
    downTime: 8,
    downRatio: 20,
    downCount: 14,
    avgQueue: 20,
    maxQueue: 14,
  },
  {
    receiveTime: "00:18",
    createTimeStamp: `${createTime}:18`,
    linkId: 1000000318,
    volume: 82,
    avgTravelTime: 12,
    sectionTime: 27,
    downTime: 12,
    downRatio: 20,
    downCount: 8,
    avgQueue: 20,
    maxQueue: 11,
  },
  {
    receiveTime: "00:19",
    createTimeStamp: `${createTime}:19`,
    linkId: 1000000319,
    volume: 57,
    avgTravelTime: 11,
    sectionTime: 16,
    downTime: 9,
    downRatio: 18,
    downCount: 4,
    avgQueue: 14,
    maxQueue: 6,
  },
  {
    receiveTime: "00:20",
    createTimeStamp: `${createTime}:20`,
    linkId: 1000000320,
    volume: 78,
    avgTravelTime: 16,
    sectionTime: 23,
    downTime: 14,
    downRatio: 13,
    downCount: 15,
    avgQueue: 8,
    maxQueue: 16,
  },
  {
    receiveTime: "00:21",
    createTimeStamp: `${createTime}:21`,
    linkId: 1000000321,
    volume: 124,
    avgTravelTime: 16,
    sectionTime: 19,
    downTime: 7,
    downRatio: 19,
    downCount: 13,
    avgQueue: 20,
    maxQueue: 17,
  },
  {
    receiveTime: "00:22",
    createTimeStamp: `${createTime}:22`,
    linkId: 1000000322,
    volume: 77,
    avgTravelTime: 17,
    sectionTime: 29,
    downTime: 15,
    downRatio: 19,
    downCount: 14,
    avgQueue: 16,
    maxQueue: 9,
  },
  {
    receiveTime: "00:23",
    createTimeStamp: `${createTime}:23`,
    linkId: 1000000323,
    volume: 90,
    avgTravelTime: 15,
    sectionTime: 18,
    downTime: 13,
    downRatio: 5,
    downCount: 18,
    avgQueue: 11,
    maxQueue: 10,
  },
  {
    receiveTime: "00:24",
    createTimeStamp: `${createTime}:24`,
    linkId: 1000000324,
    volume: 101,
    avgTravelTime: 24,
    sectionTime: 28,
    downTime: 8,
    downRatio: 17,
    downCount: 12,
    avgQueue: 13,
    maxQueue: 5,
  },
  {
    receiveTime: "00:25",
    createTimeStamp: `${createTime}:25`,
    linkId: 1000000325,
    volume: 68,
    avgTravelTime: 19,
    sectionTime: 11,
    downTime: 7,
    downRatio: 7,
    downCount: 5,
    avgQueue: 19,
    maxQueue: 12,
  },
  {
    receiveTime: "00:26",
    createTimeStamp: `${createTime}:26`,
    linkId: 1000000326,
    volume: 83,
    avgTravelTime: 27,
    sectionTime: 12,
    downTime: 6,
    downRatio: 9,
    downCount: 15,
    avgQueue: 6,
    maxQueue: 13,
  },
  {
    receiveTime: "00:27",
    createTimeStamp: `${createTime}:27`,
    linkId: 1000000327,
    volume: 64,
    avgTravelTime: 13,
    sectionTime: 27,
    downTime: 11,
    downRatio: 17,
    downCount: 4,
    avgQueue: 6,
    maxQueue: 14,
  },
  {
    receiveTime: "00:28",
    createTimeStamp: `${createTime}:28`,
    linkId: 1000000328,
    volume: 105,
    avgTravelTime: 20,
    sectionTime: 15,
    downTime: 14,
    downRatio: 19,
    downCount: 20,
    avgQueue: 7,
    maxQueue: 20,
  },
  {
    receiveTime: "00:29",
    createTimeStamp: `${createTime}:29`,
    linkId: 1000000329,
    volume: 71,
    avgTravelTime: 14,
    sectionTime: 26,
    downTime: 7,
    downRatio: 18,
    downCount: 11,
    avgQueue: 9,
    maxQueue: 5,
  },
  {
    receiveTime: "00:30",
    createTimeStamp: `${createTime}:30`,
    linkId: 1000000330,
    volume: 82,
    avgTravelTime: 30,
    sectionTime: 28,
    downTime: 5,
    downRatio: 11,
    downCount: 5,
    avgQueue: 20,
    maxQueue: 8,
  },
];
