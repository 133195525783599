import React, { useState } from "react";
import HWTable from "../table/HWTable";
import Status from "../../../components/table/Status";
import { useQuery } from "react-query";
import { dashboardApi } from "../../../api/DashboardApi";

const HardwareMonitoringSection = () => {
  const [hwApiData, setHwApiData] = useState([]);
  useQuery("hardwareMonitoring", () => dashboardApi.hardwareMonitoring(), {
    onSuccess: (data) => {
      setHwApiData([data.data.status]);
    },
  });
  return (
    <div className="box w-1/2">
      <div className="boxTitle flex justify-between align-center">
        H/W 모니터링
        <Status />
      </div>
      <HWTable data={hwApiData} />
    </div>
  );
};

export default HardwareMonitoringSection;
