export const crosswayTrafficInfoContent = [
  {
    receiveTime: "00:01",
    crosswayID: 9,
    ringID: 5,
    signalCycle: "45/4",
    smallVolume: 104,
    mediumVolume: 139,
    largeVolume: 106,
    motorcycleVolume: 38,
    initialWaitNum: 11,
    avgMaxQueue: 19.0,
    avgSpeed: 35.0,
    avgTrafficRate: 6.0,
    avgDemandVolume: 10.1,
    avgSaturation: 10,
    dataNum: 27,
    accessCrosswayID: 2,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:02",
    crosswayID: 7,
    ringID: 4,
    signalCycle: "70/2",
    smallVolume: 110,
    mediumVolume: 64,
    largeVolume: 120,
    motorcycleVolume: 73,
    initialWaitNum: 13,
    avgMaxQueue: 19.1,
    avgSpeed: 40.1,
    avgTrafficRate: 9.5,
    avgDemandVolume: 13.9,
    avgSaturation: 9,
    dataNum: 20,
    accessCrosswayID: 6,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:03",
    crosswayID: 6,
    ringID: 4,
    signalCycle: "68/3",
    smallVolume: 53,
    mediumVolume: 76,
    largeVolume: 82,
    motorcycleVolume: 55,
    initialWaitNum: 8,
    avgMaxQueue: 13.4,
    avgSpeed: 45.3,
    avgTrafficRate: 12.4,
    avgDemandVolume: 8.9,
    avgSaturation: 3,
    dataNum: 26,
    accessCrosswayID: 4,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:04",
    crosswayID: 5,
    ringID: 1,
    signalCycle: "75/2",
    smallVolume: 92,
    mediumVolume: 106,
    largeVolume: 107,
    motorcycleVolume: 32,
    initialWaitNum: 8,
    avgMaxQueue: 17.6,
    avgSpeed: 45.3,
    avgTrafficRate: 6.5,
    avgDemandVolume: 8.9,
    avgSaturation: 1,
    dataNum: 23,
    accessCrosswayID: 7,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:05",
    crosswayID: 6,
    ringID: 2,
    signalCycle: "55/2",
    smallVolume: 126,
    mediumVolume: 127,
    largeVolume: 104,
    motorcycleVolume: 44,
    initialWaitNum: 12,
    avgMaxQueue: 10.1,
    avgSpeed: 41.0,
    avgTrafficRate: 7.6,
    avgDemandVolume: 5.3,
    avgSaturation: 8,
    dataNum: 28,
    accessCrosswayID: 8,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:06",
    crosswayID: 8,
    ringID: 2,
    signalCycle: "49/2",
    smallVolume: 60,
    mediumVolume: 95,
    largeVolume: 139,
    motorcycleVolume: 56,
    initialWaitNum: 5,
    avgMaxQueue: 10.2,
    avgSpeed: 33.9,
    avgTrafficRate: 13.6,
    avgDemandVolume: 11.4,
    avgSaturation: 1,
    dataNum: 27,
    accessCrosswayID: 5,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:07",
    crosswayID: 8,
    ringID: 5,
    signalCycle: "47/5",
    smallVolume: 76,
    mediumVolume: 96,
    largeVolume: 138,
    motorcycleVolume: 25,
    initialWaitNum: 8,
    avgMaxQueue: 15.2,
    avgSpeed: 44.8,
    avgTrafficRate: 6.4,
    avgDemandVolume: 11.4,
    avgSaturation: 6,
    dataNum: 21,
    accessCrosswayID: 9,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:08",
    crosswayID: 7,
    ringID: 3,
    signalCycle: "76/2",
    smallVolume: 79,
    mediumVolume: 149,
    largeVolume: 150,
    motorcycleVolume: 31,
    initialWaitNum: 12,
    avgMaxQueue: 12.9,
    avgSpeed: 47.8,
    avgTrafficRate: 11.1,
    avgDemandVolume: 6.9,
    avgSaturation: 4,
    dataNum: 20,
    accessCrosswayID: 6,
    movementDirection: "우회전",
  },
  {
    receiveTime: "00:09",
    crosswayID: 9,
    ringID: 4,
    signalCycle: "67/1",
    smallVolume: 91,
    mediumVolume: 95,
    largeVolume: 104,
    motorcycleVolume: 72,
    initialWaitNum: 8,
    avgMaxQueue: 14.3,
    avgSpeed: 30.7,
    avgTrafficRate: 14.6,
    avgDemandVolume: 13.2,
    avgSaturation: 8,
    dataNum: 23,
    accessCrosswayID: 9,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:10",
    crosswayID: 6,
    ringID: 2,
    signalCycle: "52/2",
    smallVolume: 82,
    mediumVolume: 113,
    largeVolume: 54,
    motorcycleVolume: 45,
    initialWaitNum: 6,
    avgMaxQueue: 13.9,
    avgSpeed: 31.1,
    avgTrafficRate: 5.2,
    avgDemandVolume: 14.6,
    avgSaturation: 3,
    dataNum: 25,
    accessCrosswayID: 7,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:11",
    crosswayID: 6,
    ringID: 1,
    signalCycle: "69/2",
    smallVolume: 89,
    mediumVolume: 136,
    largeVolume: 66,
    motorcycleVolume: 61,
    initialWaitNum: 12,
    avgMaxQueue: 19.6,
    avgSpeed: 40.7,
    avgTrafficRate: 6.1,
    avgDemandVolume: 7.9,
    avgSaturation: 5,
    dataNum: 28,
    accessCrosswayID: 9,
    movementDirection: "우회전",
  },
  {
    receiveTime: "00:12",
    crosswayID: 10,
    ringID: 5,
    signalCycle: "42/1",
    smallVolume: 118,
    mediumVolume: 142,
    largeVolume: 112,
    motorcycleVolume: 25,
    initialWaitNum: 15,
    avgMaxQueue: 10.7,
    avgSpeed: 32.7,
    avgTrafficRate: 6.0,
    avgDemandVolume: 10.6,
    avgSaturation: 5,
    dataNum: 25,
    accessCrosswayID: 5,
    movementDirection: "우회전",
  },
  {
    receiveTime: "00:13",
    crosswayID: 8,
    ringID: 3,
    signalCycle: "80/5",
    smallVolume: 139,
    mediumVolume: 120,
    largeVolume: 54,
    motorcycleVolume: 35,
    initialWaitNum: 6,
    avgMaxQueue: 19.4,
    avgSpeed: 35.2,
    avgTrafficRate: 10.4,
    avgDemandVolume: 13.3,
    avgSaturation: 9,
    dataNum: 21,
    accessCrosswayID: 3,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:14",
    crosswayID: 5,
    ringID: 2,
    signalCycle: "59/5",
    smallVolume: 62,
    mediumVolume: 66,
    largeVolume: 50,
    motorcycleVolume: 32,
    initialWaitNum: 13,
    avgMaxQueue: 12.9,
    avgSpeed: 47.6,
    avgTrafficRate: 14.8,
    avgDemandVolume: 5.8,
    avgSaturation: 2,
    dataNum: 30,
    accessCrosswayID: 6,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:15",
    crosswayID: 8,
    ringID: 4,
    signalCycle: "77/4",
    smallVolume: 107,
    mediumVolume: 122,
    largeVolume: 68,
    motorcycleVolume: 62,
    initialWaitNum: 7,
    avgMaxQueue: 15.1,
    avgSpeed: 42.8,
    avgTrafficRate: 8.2,
    avgDemandVolume: 6.1,
    avgSaturation: 9,
    dataNum: 23,
    accessCrosswayID: 6,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:16",
    crosswayID: 8,
    ringID: 1,
    signalCycle: "43/4",
    smallVolume: 135,
    mediumVolume: 131,
    largeVolume: 146,
    motorcycleVolume: 52,
    initialWaitNum: 6,
    avgMaxQueue: 12.9,
    avgSpeed: 44.1,
    avgTrafficRate: 7.4,
    avgDemandVolume: 10.4,
    avgSaturation: 7,
    dataNum: 26,
    accessCrosswayID: 3,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:17",
    crosswayID: 7,
    ringID: 4,
    signalCycle: "59/5",
    smallVolume: 96,
    mediumVolume: 86,
    largeVolume: 137,
    motorcycleVolume: 41,
    initialWaitNum: 8,
    avgMaxQueue: 16.4,
    avgSpeed: 34.1,
    avgTrafficRate: 12.7,
    avgDemandVolume: 10.0,
    avgSaturation: 3,
    dataNum: 29,
    accessCrosswayID: 9,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:18",
    crosswayID: 9,
    ringID: 2,
    signalCycle: "70/2",
    smallVolume: 126,
    mediumVolume: 113,
    largeVolume: 59,
    motorcycleVolume: 44,
    initialWaitNum: 7,
    avgMaxQueue: 15.1,
    avgSpeed: 39.8,
    avgTrafficRate: 6.4,
    avgDemandVolume: 8.3,
    avgSaturation: 5,
    dataNum: 24,
    accessCrosswayID: 5,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:19",
    crosswayID: 9,
    ringID: 3,
    signalCycle: "61/2",
    smallVolume: 144,
    mediumVolume: 109,
    largeVolume: 125,
    motorcycleVolume: 33,
    initialWaitNum: 15,
    avgMaxQueue: 19.0,
    avgSpeed: 30.7,
    avgTrafficRate: 9.0,
    avgDemandVolume: 6.4,
    avgSaturation: 8,
    dataNum: 30,
    accessCrosswayID: 4,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:20",
    crosswayID: 4,
    ringID: 5,
    signalCycle: "73/4",
    smallVolume: 50,
    mediumVolume: 122,
    largeVolume: 94,
    motorcycleVolume: 58,
    initialWaitNum: 8,
    avgMaxQueue: 12.5,
    avgSpeed: 32.0,
    avgTrafficRate: 5.5,
    avgDemandVolume: 8.5,
    avgSaturation: 9,
    dataNum: 28,
    accessCrosswayID: 7,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:21",
    crosswayID: 3,
    ringID: 5,
    signalCycle: "49/5",
    smallVolume: 88,
    mediumVolume: 134,
    largeVolume: 99,
    motorcycleVolume: 33,
    initialWaitNum: 14,
    avgMaxQueue: 15.0,
    avgSpeed: 36.0,
    avgTrafficRate: 7.1,
    avgDemandVolume: 8.3,
    avgSaturation: 4,
    dataNum: 28,
    accessCrosswayID: 1,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:22",
    crosswayID: 2,
    ringID: 3,
    signalCycle: "44/5",
    smallVolume: 67,
    mediumVolume: 75,
    largeVolume: 80,
    motorcycleVolume: 42,
    initialWaitNum: 6,
    avgMaxQueue: 19.2,
    avgSpeed: 45.6,
    avgTrafficRate: 10.9,
    avgDemandVolume: 11.1,
    avgSaturation: 9,
    dataNum: 30,
    accessCrosswayID: 9,
    movementDirection: "우회전",
  },
  {
    receiveTime: "00:23",
    crosswayID: 7,
    ringID: 5,
    signalCycle: "74/3",
    smallVolume: 119,
    mediumVolume: 141,
    largeVolume: 53,
    motorcycleVolume: 53,
    initialWaitNum: 12,
    avgMaxQueue: 19.5,
    avgSpeed: 37.9,
    avgTrafficRate: 13.2,
    avgDemandVolume: 11.8,
    avgSaturation: 5,
    dataNum: 22,
    accessCrosswayID: 2,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:24",
    crosswayID: 9,
    ringID: 3,
    signalCycle: "41/4",
    smallVolume: 86,
    mediumVolume: 138,
    largeVolume: 55,
    motorcycleVolume: 67,
    initialWaitNum: 13,
    avgMaxQueue: 16.3,
    avgSpeed: 44.8,
    avgTrafficRate: 9.1,
    avgDemandVolume: 7.6,
    avgSaturation: 10,
    dataNum: 29,
    accessCrosswayID: 3,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:25",
    crosswayID: 4,
    ringID: 5,
    signalCycle: "52/5",
    smallVolume: 132,
    mediumVolume: 103,
    largeVolume: 109,
    motorcycleVolume: 52,
    initialWaitNum: 8,
    avgMaxQueue: 19.4,
    avgSpeed: 48.5,
    avgTrafficRate: 9.2,
    avgDemandVolume: 9.9,
    avgSaturation: 5,
    dataNum: 26,
    accessCrosswayID: 8,
    movementDirection: "우회전",
  },
  {
    receiveTime: "00:26",
    crosswayID: 7,
    ringID: 5,
    signalCycle: "78/5",
    smallVolume: 66,
    mediumVolume: 125,
    largeVolume: 58,
    motorcycleVolume: 48,
    initialWaitNum: 5,
    avgMaxQueue: 11.3,
    avgSpeed: 30.2,
    avgTrafficRate: 14.0,
    avgDemandVolume: 14.6,
    avgSaturation: 3,
    dataNum: 24,
    accessCrosswayID: 2,
    movementDirection: "직진",
  },
  {
    receiveTime: "00:27",
    crosswayID: 6,
    ringID: 4,
    signalCycle: "43/1",
    smallVolume: 65,
    mediumVolume: 52,
    largeVolume: 134,
    motorcycleVolume: 30,
    initialWaitNum: 15,
    avgMaxQueue: 12.7,
    avgSpeed: 35.0,
    avgTrafficRate: 7.9,
    avgDemandVolume: 6.6,
    avgSaturation: 3,
    dataNum: 28,
    accessCrosswayID: 10,
    movementDirection: "우회전",
  },
  {
    receiveTime: "00:28",
    crosswayID: 10,
    ringID: 3,
    signalCycle: "40/3",
    smallVolume: 130,
    mediumVolume: 99,
    largeVolume: 104,
    motorcycleVolume: 42,
    initialWaitNum: 13,
    avgMaxQueue: 15.9,
    avgSpeed: 37.5,
    avgTrafficRate: 13.1,
    avgDemandVolume: 13.7,
    avgSaturation: 5,
    dataNum: 21,
    accessCrosswayID: 4,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:29",
    crosswayID: 8,
    ringID: 1,
    signalCycle: "72/4",
    smallVolume: 127,
    mediumVolume: 122,
    largeVolume: 109,
    motorcycleVolume: 27,
    initialWaitNum: 6,
    avgMaxQueue: 10.0,
    avgSpeed: 34.4,
    avgTrafficRate: 8.9,
    avgDemandVolume: 6.0,
    avgSaturation: 5,
    dataNum: 29,
    accessCrosswayID: 1,
    movementDirection: "좌회전",
  },
  {
    receiveTime: "00:30",
    crosswayID: 9,
    ringID: 1,
    signalCycle: "77/4",
    smallVolume: 71,
    mediumVolume: 143,
    largeVolume: 78,
    motorcycleVolume: 42,
    initialWaitNum: 9,
    avgMaxQueue: 13.1,
    avgSpeed: 44.4,
    avgTrafficRate: 13.3,
    avgDemandVolume: 14.8,
    avgSaturation: 2,
    dataNum: 30,
    accessCrosswayID: 9,
    movementDirection: "직진",
  },
];
