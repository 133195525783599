import React, { useEffect, useState } from "react";
import KakaoMap from "../../../../components/map/KakaoMap";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { passApi } from "../../../../api/SearchApi";
import { resetSearch } from "../../../../store/features/search/searchSlice";

const CheckPassMap = () => {
  const [coordinateData, setCoordinateData] = useState([]);
  const [arrowDataContent, setArrowDataContent] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("arrowDataContent", arrowDataContent);
  //   console.log("arrowDataContent", arrowDataContent.length > 0);
  // }, [arrowDataContent]);

  /**
   * 범례에 따른 화살표 색상 변경
   * @param volume 통과 교통량
   * @returns {string}  색상
   */
  const arrowColor = (volume) => {
    let res = "";
    if (volume < 5000) {
      res = "#e0f2fe";
    } else if (volume >= 5000 && volume < 10000) {
      res = "#bae6fd";
    } else if (volume >= 10000 && volume < 20000) {
      res = "#7dd3fc";
    } else if (volume >= 20000 && volume < 50000) {
      res = "#38bdf8";
    } else if (volume >= 50000 && volume < 10000000) {
      res = "#0ea5e9";
    }
    return res;
  };
  /**
   * 지도 중심을 찾는 함수
   * @param search 검색 값
   * @returns {*} 좌표 값
   */
  const findCenter = (search) => {
    // console.log("findCenter search", search);
    const res = coordinateData.find((i) => i.crossway === search.crossway);
    console.log("findCenter res", res);
    return res;
  };

  // 화살표 데이터 받아오는 api
  // 통과교통량 검색 객체에서 방향은 제하고 가져온다
  const tmp = useSelector((state) => state.searchData.search);
  const search = Object.fromEntries(
    Object.entries(tmp).filter(([key, value]) => key !== "direction")
  );
  // console.log("search useSelector", search);
  // console.log("search useSelector searchedArrow", search.direction);
  const center = findCenter(search);
  /**
   * 통과 교통량 화살표 데이터를 불러오는 쿼리
   */
  const { isFetching, isLoading } = useQuery(
    ["passArrow", search],
    () => passApi.passArrow(search),
    {
      enabled: arrowDataContent.length === 0,
      onSuccess: (data) => {
        // console.log("passArrow", data);
        setCoordinateData(
          data.data.items.map((item) => Object.values(item)[0])
        );
        setArrowDataContent(
          data.data.items.flatMap((items) =>
            Object.values(items)[0].arrows.map((item) => ({
              ...item,
              crossway: Object.values(items)[0].crossway,
              type: "arrow",
              coordinate: "wgs84",
              options: {
                strokeColor: arrowColor(item.volume),
                strokeWeight: 8,
                strokeStyle: "solid",
                strokeOpacity: 1,
                endArrow: true,
              },
            }))
          )
        );
      },
    }
  );
  return (
    <>
      {!isLoading && !isFetching && arrowDataContent.length > 0 ? (
        <KakaoMap
          arrowData={{ arrow: arrowDataContent }}
          coordinates={coordinateData}
          center={center}
        />
      ) : (
        <div className="flex justify-center items-center grow w-screen">
          <p className="loading">데이터를 불러오는 중입니다</p>
        </div>
      )}
    </>
  );
};

export default React.memo(CheckPassMap);
