import React from "react";
import { checkStatus } from "../../../hoc/CommonHooks";
const SWTable = ({ data }) => {
  return (
    <div>
      <table className="w-full border border-bg-pale-grey-two">
        <thead>
          <tr>
            <td className="bg-pale-grey py-1 text-center font-semibold">
              TCP 연결
            </td>
            <td className="bg-pale-grey py-1 text-center font-semibold">
              데이터 수신
            </td>
            <td className="bg-pale-grey py-1 text-center font-semibold">
              데이터 송신
            </td>
            <td className="bg-pale-grey py-1 text-center font-semibold">
              데이터 처리
            </td>
            <td className="bg-pale-grey py-1 text-center font-semibold">
              API 연결 확인
            </td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).length === 0 ? ( // key 배열의 길이로 빈 객체인지 아닌지 확인
            <tr className="text-center">
              <td colSpan={5} className="col-span-full py-2">
                <p className="mr-auto ml-auto">데이터를 불러오는 중입니다</p>
              </td>
            </tr>
          ) : (
            <tr>
              <td className=" border-r">{checkStatus(data.tcp)}</td>
              <td className="py-2  border-r">{checkStatus(data.receive)}</td>
              <td className="py-2  border-r">{checkStatus(data.transmit)}</td>
              <td className="py-2  border-r">{checkStatus(data.processing)}</td>
              <td className="py-2 ">{checkStatus(data.connection)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SWTable;
