import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { showChangeCoModal } from "../../../store/features/account/changeCoSlice";
import { showConfirmModal } from "../../../store/features/common/modalSlice";
import ModalBtn from "../../../components/button/ModalBtn";
import { useQuery } from "react-query";
import { superApi } from "../../../api/AccountApi";

export default function SuperAccountupdateCoModal({ data, coData }) {
  // console.log("SuperAccountupdateCoModal coData", coData);
  const { handleSubmit, register, reset } = useForm();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.coModal.show); // 회사 변경 모달이 표시될지 여부를 나타내는 상태값
  const cancelButtonRef = useRef(null); // 모달 취소 버튼
  const [paramData, setParamData] = useState({});

  useQuery(["updateCo", paramData], () => superApi.updateCo(paramData), {
    enabled: !!paramData.no,
    onSuccess: (paramData) => {
      console.log("updateCo", paramData);
      dispatch(showConfirmModal({ show: true }));
    },
  });

  /**
   * form submit 시 회사 변경 모달 종료 후 확인 모달 상태 변경 함수
   * @param data  폼 제출 시 입력된 데이터
   */
  const onValid = (data) => {
    data.no = coData.no;
    console.log("회사이름 변경", data);
    setParamData(data);
    dispatch(showChangeCoModal({ show: false }));
    reset();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(showChangeCoModal({ show: false }))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={handleSubmit(onValid)}>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex justify-center items-center">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-center leading-6 title1"
                        >
                          회사명 변경
                        </Dialog.Title>
                        <div className="mt-4">
                          <div className="my-7">
                            <table>
                              <tbody>
                                <tr className="border-b border-gray-200">
                                  <td className="py-3 textfield">
                                    현재 회사명
                                  </td>
                                  <td className="py-3 text-center textfield">
                                    {coData.name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="py-3 textfield">
                                    새로운 회사명
                                  </td>
                                  <td>
                                    <input
                                      className="h-8 ml-4 text-sm border-none bg-transparent  focus:outline-none text-center"
                                      type="text"
                                      placeholder="변경할 회사명 입력"
                                      autoComplete="off"
                                      {...register("newCo")}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-pale-grey px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <ModalBtn type="submit">변경</ModalBtn>
                    <ModalBtn
                      className="mr-3"
                      type="button"
                      onClick={() =>
                        dispatch(showChangeCoModal({ show: false }))
                      }
                    >
                      취소
                    </ModalBtn>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
