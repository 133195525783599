import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  doubleCheck: false,
  finalCheck: false,
};

const modalSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showConfirmModal: (state, action) => {
      state.show = action.payload.show;
    },
    showDoubleCheckModal: (state, action) => {
      state.doubleCheck = action.payload.doubleCheck;
    },
    setFinalCheck: (state, action) => {
      state.finalCheck = action.payload.finalCheck;
    },
  },
});

export default modalSlice.reducer;
export const { showConfirmModal, showDoubleCheckModal, setFinalCheck } =
  modalSlice.actions;
