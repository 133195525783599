import React from "react";
import ApexChart from "react-apexcharts";

const LineChart = ({ data, title }) => {
  console.log(data);
  return (
    <ApexChart
      type="line"
      series={data}
      height={180}
      options={{
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false | '<img src="/static/icons/reset.png" width="20">',
            },
            offsetX: -10,
          },
          export: {
            csv: {
              filename: title,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: title,
            },
            png: { filename: title },
          },
          background: "transparent",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        theme: {
          mode: "light",
        },
        title: {
          text: title,
          style: {
            fontSize: "18px",
            fontFamily: "pretendard",
            fontWeight: 600,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
        },
        stroke: {
          width: 5,
          curve: "smooth",
        },
        yaxis: {
          show: true,
        },
        colors: ["#a5b1c2", "#f97315"],
        tooltip: {
          y: {
            formatter: (value) => (value === null ? null : value + "%"),
          },
        },
      }}
    />
  );
};

export default LineChart;
