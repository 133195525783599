import React, { useEffect, useState } from "react";
import ServerInfoSection from "./section/ServerInfoSection";
import ChartSection from "./section/chartSection";
import { Controller, useForm } from "react-hook-form";
import Table from "../../components/table/Table";
import Pagination from "../../components/table/Pagination";
import ExcelDownload from "../../utils/ExcelDownload";
import Calendar from "../../utils/calendar/Calendar";
import SearchBtn from "../../components/button/SearchBtn";
import Status from "../../components/table/Status";
import { useQuery } from "react-query";
import { monitoringApi } from "../../api/MonitoringApi";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { resetPageNum } from "../../store/features/common/paginationSlice";
import {
  clickExcelBtn,
  resetExcelBtn,
} from "../../store/features/common/excelSlice";

const MonitoringContent = () => {
  const { control, handleSubmit, register } = useForm();
  const [search, setSearch] = useState({});
  const page_no = useSelector((state) => state.pagination.pageNum); // api로 전달할 페이지 값
  const startDate = useSelector((state) => state.calendar.startDate); // 종료 날짜를 정하지 않았을 경우 api로 전달할 시작 날짜 값
  const clickedExcelBtn = useSelector((state) => state.excel.clicked); // 엑셀 출력 여부
  const dispatch = useDispatch();

  const today = new Date();
  useEffect(() => {
    dispatch(resetPageNum()); // 페이지 값 리셋
    dispatch(resetExcelBtn()); // 엑셀 출력 여부 리셋
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValid = (data) => {
    dispatch(resetPageNum()); // 페이지 값 리셋
    dispatch(resetExcelBtn()); // 엑셀 출력 여부 리셋
    // console.log("MonitoringContent data", data);
    data.startDate = format(new Date(data?.startDate || today), "yyyy-MM-dd");
    data.endDate = format(
      new Date(data?.endDate || startDate || today),
      "yyyy-MM-dd"
    );
    setSearch(data);
  };
  /**
   * 테이블 출력 데이터 key 값 한글 변경 함수
   * @param items 테이블 출력 데이터
   * @returns {*} 한글로 변환된 key 값
   */
  const formatter = (items) =>
    items.map((item) => ({
      일자: format(new Date(item?.date), "yyyy.MM.dd."),
      시간: format(new Date(item?.date), "HH:mm"),
      "TCP 연결": item?.tcpConnection,
      "데이터 수신": item?.receive,
      "데이터 송신": item?.transmit,
      "데이터 처리": item?.handling,
      "API 연결 확인": item?.apiConnection,
    }));
  /**
   * 엑셀 출력 데이터 key, value 값 한글 변경 함수
   * @param items 엑셀 출력 데이터
   * @returns {*} 힌글로 변환된 key, value 값
   * @constructor
   */
  const Excelformatter = (items) =>
    items.map((item) => ({
      일자: format(new Date(item?.date), "yyyy.MM.dd."),
      시간: format(new Date(item?.date), "HH:mm"),
      "TCP 연결": item?.tcpConnection ? "Normal" : "Error",
      "데이터 수신": item?.receive ? "Normal" : "Error",
      "데이터 송신": item?.transmit ? "Normal" : "Error",
      "데이터 처리": item?.handling ? "Normal" : "Error",
      "API 연결 확인": item?.apiConnection ? "Normal" : "Error",
    }));

  const [apiData, setApiData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [totalNum, setTotalNum] = useState(0);

  const { isFetching, isLoading } = useQuery(
    ["monitoringContent", search, page_no],
    () => monitoringApi.monitoringContent(search, page_no),
    {
      onSuccess: (data) => {
        console.log("monitoringContent data", data.data);
        const formattedApiData = formatter(data.data.items);
        console.log(
          "monitoringContent formattedApiData data",
          formattedApiData
        );
        setApiData(formattedApiData);
        // console.log("page_no data", data.data.totalNum);
        setTotalNum(data.data.totalNum);
      },
    }
  );

  const {
    isLoading: isExcelLoading,
    isFetching: isExcelFetching,
    isSuccess: isExcelSuccess,
  } = useQuery(
    ["monitoringExcelContent", { ...search, isDownExcel: true }],
    () => monitoringApi.monitoringContent({ ...search, isDownExcel: true }),
    {
      enabled: clickedExcelBtn,
      onSuccess: (excelApiData) => {
        console.log("monitoringExcelContent data", excelApiData.data);
        const formattedExcelData = Excelformatter(excelApiData.data.items);
        // console.log("formattedExcelData", formattedApiData);
        setExcelData(formattedExcelData);
      },
    }
  );

  return (
    <div className="grid grid-rows-4 grow gap-[20px] h-full">
      <div className="box p-[30px]">
        <div className="grid grid-cols-3">
          {/*서버 정보*/}
          <ServerInfoSection />
          {/*Cpu, Gpu, Storage, Ram 그래프*/}
          <ChartSection />
        </div>
      </div>
      <div className="box row-span-3 py-4 px-2">
        <form className="flex justify-between" onSubmit={handleSubmit(onValid)}>
          <div className="calWrapper">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => <Calendar field={field} isStart={true} />}
            />
            <hr className="w-[10px] bg-silver" />
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => <Calendar field={field} />}
            />
          </div>
          <div className="flex w-1/5 space-x-[20px]">
            <select
              className="sideBarSelectBox appearance-none focus:outline-none"
              {...register("option")}
            >
              <option>전체</option>
              <option>Normal</option>
              <option>Error</option>
            </select>
            <SearchBtn className="!w-[120px]">검색</SearchBtn>
          </div>
        </form>
        <div className="pt-3">
          <div className="pb-3">
            {isLoading || isFetching ? (
              <div className="loading h-[550px]">
                데이터를 불러오는 중입니다
              </div>
            ) : apiData.length > 0 ? (
              <Table data={apiData} />
            ) : (
              <div className="loading h-[550px]">
                해당하는 데이터가 없습니다
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <ExcelDownload
              onClick={() => dispatch(clickExcelBtn({ clicked: true }))}
              data={excelData}
              filename={"모니터링"}
              status={{
                isExcelSuccess: isExcelSuccess,
                isExcelFetching: isExcelFetching,
                isExcelLoading: isExcelLoading,
              }}
              date={`${search?.startDate || format(today, "yyyy-MM-dd")}_${
                search?.endDate || format(today, "yyyy-MM-dd")
              }`}
            />
            <Status />
          </div>
          <Pagination totalNum={totalNum} />
        </div>
      </div>
    </div>
  );
};

export default MonitoringContent;
