import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showFindModal } from "../../store/features/account/findAccountSlice";
import FindModal from "../../contents/login/FindModal";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import SearchBtn from "../../components/button/SearchBtn";
import { useNavigate } from "react-router-dom";

const FindAccount = () => {
  /*
    findId 값(boolean)으로 비밀번호 찾기와 아이디 찾기를 구분한다
    아이디 찾기/ 비밀번호 찾기를 누르면 findId 값을 전달하여 해당 모달창을 띄운다
  */
  const [findId, setFindId] = useState(true); // 상태값으로 아이디 찾기와 비밀번호 찾기를 구분하는 변수
  const [userInfo, setUserInfo] = useState({}); // 아이디 비밀번호 찾기 모달에 전달할 변수
  const showPass = useSelector((state) => state.findAccount.show_pass); // 비밀번호 찾기 화면이 표시될지 여부를 나타내는 상태값
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  // showPass 값이 변경될 때마다 findId 값을 업데이트하여 아이디 찾기/비밀번호 찾기를 구분
  useEffect(() => {
    setFindId(!showPass);
    console.log("findId", findId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPass]);
  const handleIdTitle = () => {
    setFindId(true); // "아이디 찾기" 탭 클릭 시 findId 값을 true로 설정
    clearErrors(["id", "phone_num", "name"]);
  };
  const handlePWTitle = () => {
    setFindId(false); // "비밀번호 찾기" 탭 클릭 시 findId 값을 false로 설정
    clearErrors(["id", "phone_num", "name"]);
  };

  const onValid = (data) => {
    setUserInfo(data);
    dispatch(showFindModal({ show: true }));
    reset();
  };

  return (
    <>
      {/* FindModal 컴포넌트를 초기에 렌더링하며 findId 값을 전달 상태관리로 출력 제어 */}
      <FindModal findId={findId} userInfo={userInfo} />
      <div>
        <div className="wrapper">
          <div className="content bg-pale-grey-two">
            <div className="bigBox">
              <div className="heading !mt-[60px]">아이디/ 비밀번호 찾기</div>
              <div className="flex justify-center items-center mt-[40px]">
                <form
                  className="flex flex-col w-1/2 p-[20px]"
                  onSubmit={handleSubmit(onValid)}
                >
                  <div className="flex">
                    <div
                      className={clsx(
                        "text-center w-1/2 py-3 border-2 border-b-0 border-pale-grey rounded-t-box transition-colors",
                        {
                          "bg-pale-grey cursor-pointer title2": !findId,
                          title1: findId,
                        }
                      )}
                      onClick={handleIdTitle}
                    >
                      아이디 찾기
                    </div>
                    <div
                      className={clsx(
                        "text-center w-1/2 py-3 border-2 border-b-0 border-pale-grey rounded-t-box transition-colors",
                        {
                          "bg-pale-grey cursor-pointer title2": findId,
                          title1: !findId,
                        }
                      )}
                      onClick={handlePWTitle}
                    >
                      비밀번호 찾기
                    </div>
                  </div>
                  <div className="flex flex-col items-center h-[450px] border-x border-b rounded-b-box">
                    <div className="py-10 title2">
                      {findId
                        ? "등록한 이름과 연락처를 입력하세요"
                        : "등록한 아이디와 연락처를 입력하세요"}
                    </div>
                    <div className="flex flex-col w-full px-4">
                      <table className="mb-[60px]">
                        <tbody>
                          <tr>
                            <td className="w-1/2 h-[100px] text-center title2">
                              {findId ? "이름" : "아이디"}
                            </td>
                            <td className="h-[80px]">
                              <input
                                className="pageInput relative"
                                type="text"
                                {...register(findId ? "name" : "id", {
                                  required: findId
                                    ? "이름을 입력하세요"
                                    : "아이디를 입력하세요",
                                })}
                              />
                              <p className="absolute textfield text-dark-pink">
                                {errors?.id?.message || errors?.name?.message}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="title2 text-center ">연락처</td>
                            <td>
                              <input
                                className="pageInput relative"
                                type="text"
                                {...register("phone_num", {
                                  required: "연락처를 입력하세요",
                                  pattern: {
                                    value: /^\d+$/,
                                    message: "숫자만 입력하세요",
                                  },
                                })}
                              />
                              <p className="absolute textfield text-dark-pink">
                                {errors?.phone_num?.message}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="flex justify-center w-full px-4">
                      <SearchBtn className="!w-1/3">
                        {findId ? "아이디" : "비밀번호"} 찾기
                      </SearchBtn>
                      <button
                        type="button"
                        onClick={() => navigate("/")}
                        className="w-1/5 bg-pale-grey rounded-basic text-dark-grey ml-3 textfield"
                      >
                        로그인 페이지
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindAccount;
